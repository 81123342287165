import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
    InputAbbr,
    InputGroup,
    StField,
    StLabel,
    StHelp,
} from 'shared/components/index';
import { FormikErrors } from 'shared/components/Formik/index';

const TaxDeduction = ({ name }) => (
    <Fragment>
        <StLabel htmlFor={name}>
            {_trans('quick_payment.form.tax_card.label')}
        </StLabel>
        <StField>
            <InputGroup>
                <Field type="text" name={name} className="u-1/1" size={4} maxLength={5} id={name} aria-describedby="taxDeductionHelp" />
                <InputAbbr abbr="%" />
            </InputGroup>
            <FormikErrors name={name} />
            <StHelp id="taxDeductionHelp">
                {_trans('quick_payment.form.tax_card.help')}
            </StHelp>
        </StField>
    </Fragment>
);

TaxDeduction.propTypes = {
    name: PropTypes.string,
};

TaxDeduction.defaultProps = {
    name: 'taxDeduction',
};

export default TaxDeduction;
