import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getErrorMessages } from '../utils';
import ProceedButton from './ProceedButton';
import ErrorMessages from './ErrorMessages';
import { MaxTextArea } from 'shared/components';
import eventKeys from 'shared/constants/eventKeys';
import security from 'shared/utils/security';

/**
 * Yhden tai useamman rivin tekstin syöttö.
 */
export default class TextInput extends Component {
    constructor(props) {
        super(props);

        this.textInput = null;

        this.state = {
            rows: 1,
            value: props.value === null ? '' : props.value,
            errors: [],
            hasSubmitted: false,
        };
    }

    /**
     * Lähetetään tiedot eteenpäin (jos vain Enter)
     * tai lisätään uusi rivi textareaan (jos Enter + Shift).
     * @param event
     */
    onKeyUp = (event) => {
        const isShiftDown = event.shiftKey;

        if (event.key === eventKeys.ENTER) {
            if (! isShiftDown) {
                this.onProceed();
            } else {
                this.setState({
                    rows: this.state.rows + 1,
                });
            }
        }
    };

    /**
     * Jos textareassa painetaan enteriä ilman shiftiä (= rivinvaihto) lähetetään vastaus.
     * @param event
     */
    onKeyDown = (event) => {
        if (event.key === eventKeys.ENTER && !event.shiftKey) {
            event.preventDefault();
            this.onProceed();
        }
    };

    /**
     * Päivitetään arvo ja validoidaan se.
     * @param value
     */
    onChange = (value) => {
        const errors = getErrorMessages(value, this.props.validators);

        this.setState({
            value,
            errors,
        });
    };

    /**
     * Jatketaan ainoastaan jos validointi menee läpi (tai sitä ei ole).
     */
    onProceed = () => {
        const { onEnter } = this.props;
        const errors = getErrorMessages(this.state.value, this.props.validators);

        this.setState({
            hasSubmitted: true,
            errors,
        });

        if (errors.length === 0) {
            this.textInput.blur();
            onEnter(security.sanitizeContent(encodeURIComponent(this.state.value)));
        } else {
            onEnter(null);
        }
    };

    /**
     * Renderöi joko yksittäisen tekstikentän tai MaxTextArea:n.
     * @param value - nykyinen arvo.
     * @param showErrors bool - näytetäänkö virheet.
     */
    renderInput = (value, showErrors) => {
        if (this.props.hasParagraphs) {
            return (
                <Fragment>
                    <MaxTextArea
                        modifierClass={classNames('c-inquisitor__text-area u-1/1', { 'has-error': showErrors })}
                        innerRef={(textInput) => this.textInput = textInput}
                        onChange={this.onChange}
                        onKeyUp={this.onKeyUp}
                        onKeyDown={this.onKeyDown}
                        rows={this.state.rows}
                        maxLength={240}
                        value={value}
                    />
                    <span className="o-help u-margin-top-tiny">
                        {_transMd('inquisitor.press_shift_enter_for_paragraph')}
                    </span>
                </Fragment>
            );
        }

        return (
            <input
                type="text"
                className={classNames('c-inquisitor__text-input u-1/1', { 'has-error': showErrors })}
                ref={(textInput) => this.textInput = textInput}
                onKeyUp={this.onKeyUp}
                onChange={(event) => this.onChange(event.target.value)}
                onFocus={this.props.onFocus}
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus={this.props.hasFocus}
                value={value}
            />
        );
    };

    render() {
        const { errors } = this.state;

        const showErrors = this.state.hasSubmitted && this.state.errors.length > 0;
        const value = this.state.value || '';
        return (
            <div>
                {this.renderInput(value, showErrors)}

                { showErrors && (
                    <ErrorMessages
                        errors={errors}
                    />
                )}
                { value.length > 0 && (
                    <div className="u-padding-top-small u-animation-slide-in-up">
                        <ProceedButton
                            label="OK"
                            onProceed={this.onProceed}
                            isFocusable={false}
                        />
                    </div>
                )}
            </div>
        );
    }
}

TextInput.defaultProps = {
    modifierClass: '',
    focus: true,
    hasFocus: false,
    value: null,
    validators: {},
    hasParagraphs: false,
};

TextInput.propTypes = {
    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Mitä tehdään kun painetaan enteriä.
     */
    onEnter: PropTypes.func.isRequired,

    /**
     * Onko tällä kysymyksellä fokus. Esim. kun tullaan uudelleen tähän kysymykseen.
     */
    hasFocus: PropTypes.bool,

    /**
     * Mitä tapahtuu kun kenttä saa fokuksen.
     */
    onFocus: PropTypes.func.isRequired,

    /**
     * Mitä tapahtuu kun kenttä menettää fokuksen.
     */
    onBlur: PropTypes.func.isRequired,

    /**
     * Oletusarvo.
     */
    value: PropTypes.string,

    /**
     * Mahdolliset validaattorit.
     */
    validators: PropTypes.object,

    /**
     * Voidaanko kenttään lisätä rivejä.
     */
    hasParagraphs: PropTypes.bool,
};
