/**
 * Lodashin groupBy vastaava, jolle annetaan taulukko objekteita ja funktio
 *
 * @param items
 * @param predicate
 */
export const groupBy = (items, predicate) => items.reduce(
    (result, item) => ({
        ...result,
        [predicate(item)]: [
            ...(result[predicate(item)] || []),
            item,
        ],
    }),
    {},
);

/**
 * Lodashia vastaa chunk-funkkari
 *
 * @param input
 * @param size
 */
export const chunkArray = (input, size) =>
    input.reduce((arr, item, idx) => idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    []);

/**
 *
 * @param arr
 * @param predicate
 * @returns []
 */
export const uniqueBy = (arr, predicate = null) => {
    if (typeof predicate !== 'function') {
        predicate = (v) => v;
    }
    const identifiers = new Set();
    return arr.filter(function (item) {
        const identifier = predicate(item);

        if (!identifiers.has(identifier)) {
            identifiers.add(identifier);
            return true;
        }
    });
};

/**
 * Muodostaa end-start pituisen taulukon numeerisilla arvoilla.
 * Esim. range(1, 5) => [1, 2, 3, 4, 5]
 * @param start
 * @param end
 * @returns {unknown[]}
 */
export const range = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i);
