import PoliticalInfluenceQuestion from '../components/PoliticalInfluenceQuestion';
import countries from '../constants/countries';

// KYC-kysymykset
// TODO: Tallennus
export default [
    {
        id: 'intro',
        type: 'intro',
        title: 'Ennen palkan maksua',
        description: _trans('kyc.questions.intro.description'),
    },

    {
        id: 'nationality',
        type: 'yes/no',
        title: _trans('kyc.questions.nationality.title'),
        description: _trans('kyc.questions.nationality.description'),
        jumpTo: {
            'livelihood': false,
            'select-country': true,
        },
        attributes: {
            noLabel: _trans('kyc.questions.nationality.no_label'),
        }
    },

    {
        id: 'select-country',
        type: 'select',
        title: _trans('kyc.questions.select-country.title'),
        description: _trans('kyc.questions.select-country.description'),
        attributes: {
            placeholder: _trans('kyc.questions.select-country.placeholder'),
        },
        options: countries,
    },

    {
        id: 'livelihood',
        type: 'choice',
        title: _trans('kyc.questions.livelihood.title'),
        options: [
            {
                value: 1,
                label: _trans('kyc.questions.livelihood.options.a'),
            },
            {
                value: 2,
                label: _trans('kyc.questions.livelihood.options.b'),
            },
            {
                value: 3,
                label: _trans('kyc.questions.livelihood.options.c'),
            },
            {
                value: 4,
                label: _trans('kyc.questions.livelihood.options.d'),
            },
            {
                value: 5,
                label: _trans('kyc.questions.livelihood.options.e'),
            },
            {
                value: 6,
                label: _trans('kyc.questions.livelihood.options.f'),
            },
            {
                value: 7,
                label: _trans('kyc.questions.livelihood.options.g'),
            },
        ]
    },
    {
        id: 'political-influence',
        type: 'custom',
        title: _trans('kyc.questions.political-influence.title'),
        component: PoliticalInfluenceQuestion,
        accessKeys: {
            'K': true,
            'E': false,
        },
    },

    {
        id: 'wage-count',
        type: 'choice',
        title: _trans('kyc.questions.wage-count.title'),
        options: [
            {
                value: 1,
                label: '0–2 000',
            },
            {
                value: 2,
                label: '2 000–5 000',
            },
            {
                value: 3,
                label: '2 000–5 000',
            },
            {
                value: 4,
                label: '5 000–10 000',
            },
            {
                value: 5,
                label: 'yli 10 000',
            },
        ]
    },

    {
        id: 'money-use',
        type: 'choice',
        title: _trans('kyc.questions.money-use.title'),
        options: [
            {
                value: 1,
                label: '0–5 000',
            },
            {
                value: 2,
                label: '5 000–20 000',
            },
            {
                value: 3,
                label: '20 000–100 000',
            },
            {
                value: 4,
                label: '100 000–1 000 000',
            },
            {
                value: 5,
                label: 'yli 1 000 000',
            },
        ],
    },

    {
        id: 'thank-you',
        type: 'thank-you',
        title: _trans('kyc.questions.thank-you.title'),
        description: _trans('kyc.questions.thank-you.description'),
    }
];
