import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import security from 'shared/utils/security';

/**
 * Kysymyksen selite. Kääntää sisällön markdownista.
 *
 */
function QuestionDescription({ description, isIntro, }) {
    const trimmedDescription = description.trim();

    if (trimmedDescription === '') return null;

    const descriptionClass = classNames('c-inquisitor__description u-margin-bottom-small u-margin-bottom@medium', {
        'u-padding-left-none': isIntro,
    });

    return (
        <div className={descriptionClass}>
            {SafeMarkDown(trimmedDescription)}
        </div>
    );
}

QuestionDescription.propTypes = {
    /**
     * Onko kyseessä intro?
     */
    isIntro: PropTypes.bool.isRequired,

    /**
     * Esittelyteksti.
     */
    description: PropTypes.string.isRequired,
};

const SafeMarkDown = (source) => (
    <ReactMarkdown
        source={security.sanitizeMarkdown(source)}
        unwrapDisallowed
        renderers={
            {
                // eslint-disable-next-line react/display-name
                root: (props) => <span>{props.children}</span>,
                // eslint-disable-next-line react/display-name
                link: ({ node, ...props }) => {
                    /* eslint-disable react/prop-types */
                    let href = props.href;
                    let linkAttributes = {};
                    let content = props.children;
                    const hostname = _.get(location, 'hostname', null);

                    // Jos linkki osoittaa palvelun ulkopuolelle tai se on asetettu avautumaan uuteen
                    // ikkunaan lisätään linkin kylkeen myös kuvaava ikoni ja aputeksti.
                    const openInNew = props.href.match('http') && !props.href.startsWith(hostname);

                    // Jos linkki osoittaa palvelun ulkopuolelle tehdään tarvittavat attribuuttilisäykset.
                    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
                    if (openInNew) {
                        linkAttributes = {
                            rel: 'nofollow noreferrer noopener',
                            target: '_blank',
                            title: _trans('text.opens_in_new_window'),
                        };

                        content = (
                            <Fragment>
                                {props.children}
                                <i
                                    className="material-icons c-md-icon--tiny"
                                    aria-hidden="true"
                                    style={{
                                        marginLeft: '3px',
                                    }}
                                >
                                    open_in_new
                                </i>
                            </Fragment>
                        );
                    }

                    // Jos url alkaa suoratyo-sanalla oletetaan että se on jokin
                    // routing.yml:stä löytyvä polku. Generoidaan se.
                    if (props.href.match(/^[suoratyo].+$/)) {
                        // Jos löytyy query paramateriä
                        const queryStringPosition = props.href.indexOf('?');
                        if (queryStringPosition !== -1) {
                            // Lisäillää query string(it) perään
                            href = Routing.generate(props.href.slice(0, queryStringPosition)) + props.href.slice(queryStringPosition);
                        } else {
                            href = Routing.generate(props.href);
                        }
                    }

                    return (
                        <a
                            href={href}
                            {...linkAttributes}
                        >
                            {content}
                        </a>
                    );
                    /* eslint-enable react/prop-types */
                }
            }
        }
        skipHtml
        plugins={[remarkBreaks]}
    />
);

SafeMarkDown.propTypes = {
    children: PropTypes.node.isRequired,
};

export default QuestionDescription;
