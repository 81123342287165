/**
 * Todotyypit. Lisää lisää kun jaksat.
 * @type {{TYPE_INSTANT_MESSAGES: string}}
 */
export const todoTypes = {
    AGE_CHANGE_COST_REIMBURSEMENT_UPDATE: 'AGE_CHANGE_COST_REIMBURSEMENT_UPDATE',
    TAX_CARD_UPDATE_NEEDS_ACTIONS: 'TAX_CARD_UPDATE_NEEDS_ACTIONS',
    TYPE_OWN_INSTANT_MESSAGES: 'TYPE_OWN_INSTANT_MESSAGES',
    TYPE_WELLBEING_AREA_INSTANT_MESSAGES: 'TYPE_WELLBEING_AREA_INSTANT_MESSAGES',
    TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_EMPLOYEE: 'TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_EMPLOYEE',
    TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_BENEFICIARY: 'TYPE_OVERLAPPING_WORK_TIME_PERIODS_FOR_SINGLE_BENEFICIARY',
    TYPE_BENEFIT_HISTORY_EXCESS_HOURS: 'TYPE_BENEFIT_HISTORY_EXCESS_HOURS',
    TYPE_BENEFIT_ALLOWANCE_RULE_EXCESS_HOURS: 'TYPE_BENEFIT_ALLOWANCE_RULE_EXCESS_HOURS',
    MISSING_PERSONAL_TRADE_UNION_REFERENCES: 'MISSING_PERSONAL_TRADE_UNION_REFERENCES',
};
