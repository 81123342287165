/**
 * Sisältää yleishyödyllisiä apumetodeja.
 */

import { Iterable } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import scrollToElement from 'scroll-to-element';
import validators from 'shared/validators';

/**
 * Palauttaa joko käyttäjän koko nimen tai nimen puuttuessa sähköpostiosoitteen
 * @param user - objekti. Hyväksyy myös immutablet.
 * @param notFoundValue - arvo joka palautetaan mikäli user-objekti on tyhjä.
 * @returns {string} - Koko nimi, sähköposti tai näiden molempien puuttuessa notFoundValue:n arvo.
 */
function getUserFullName(user, notFoundValue = '-') {
    let name, email;

    if (Iterable.isIterable(user)) {
        const fullName = user.get('fullName', false);
        email = user.get('email', false);
        name = fullName ? fullName : `${user.get('firstName', '')} ${user.get('lastName', '')}`;
    } else {
        const fullName = _.get(user, 'fullName', false);
        email = _.get(user, 'email', false);
        name = fullName ? fullName : `${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`;
    }

    return name.trim() ? name : email ? email : notFoundValue;
}

/**
 * Luo satunnaisen RFC4122 yhteensopivan uuid:n.
 * http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 * @returns {string}
 */
function generateUuid() {
    const d0 = Math.random()*0xffffffff|0;
    const d1 = Math.random()*0xffffffff|0;
    const d2 = Math.random()*0xffffffff|0;
    const d3 = Math.random()*0xffffffff|0;
    const lut = []; for (let i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }

    return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
        lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
        lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
        lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
}

/**
 * Tarkistaa onko hetu tilapäinen.
 * @param ssn
 * @returns {function(*): number}
 */
export const isSsnTemporary = (ssn) => ssn && ssn.charAt(7) === '9';

export const scrollToBottom = () => window.scrollTo({ left: 0, top: document.body.scrollHeight });

/**
 * Palauttaa käyttäjän iän vuosina hetun perusteella.
 * @param ssn Käyttäjän henkilötunnus
 */
function getAgeInYearsBySsn(ssn) {
    const yearPrefixes = {
        '+': '18',
        '-': '19',
        'U': '19',
        'V': '19',
        'W': '19',
        'X': '19',
        'Y': '19',
        'A': '20',
        'B': '20',
        'C': '20',
        'D': '20',
        'E': '20',
        'F': '20',
    };

    if (ssn) {
        const yearPrefix = ssn.substring(6,7).toUpperCase();
        const year = yearPrefixes[yearPrefix] + ssn.substring(4,6);

        return moment().diff(moment(ssn.substring(0,4) + year, 'DDMMYYYY'), 'years');
    }
}

/**
 * Yrittää selvittää käyttäjän iän joko etsimällä ageInYears-avainta
 * tai hetun perusteella mikäli aiempaa ei löydy.
 * @param user
 */
export const resolveUserAgeInYears = (user) => {
    let ageInYears = -1;

    if (Iterable.isIterable(user)) {
        ageInYears = user.get('ageInYears', -1);

        // Ikää ei löydy valmiina joten heitetään hetusta (hehe)
        if (ageInYears < 0) {
            const ssn = user.get('socialSecurityNumber', false);
            if (ssn) {
                ageInYears = getAgeInYearsBySsn(ssn);
            }
        }
    } else {
        ageInYears = _.get(user, 'ageInYears', -1);

        // Ikää ei löydy valmiina joten heitetään hetusta (hehe)
        if (ageInYears < 0) {
            const ssn = _.get(user, 'socialSecurityNumber', false);
            if (ssn) {
                ageInYears = getAgeInYearsBySsn(ssn);
            }
        }
    }

    return ageInYears;
};

/**
 * Yrittää muodostaa tekstistä Moment-objektin.
 * @param dateStr
 * @returns {*}
 */
function convertToMoment(dateStr) {
    const date = moment(dateStr, _dateFormat);

    return date.isValid() ? date : '';
}

/**
 * Yrittää muodostaa YYYY-MM-DD päivämääräformaatista Moment-objektin.
 * @param dateStr
 * @returns {*}
 */
function convertISOToMoment(dateStr) {
    const date = moment(dateStr, 'YYYY-MM-DD');

    return date.isValid() ? date : '';
}

/**
 * Päivämäärätekstistä ISO-8601 päivämäärä.
 * @param dateStr
 * @returns {string}
 */
function convertToISO8601Date(dateStr) {
    const date = convertToMoment(dateStr);

    return date !== '' ? date.toISOString() : date;
}

/**
 * Lokalisaation mukaisesta päivämäärästringistä ISO-formaatin päivämäärästringi.
 */
function convertToISO(dateStr) {
    const date = convertToMoment(dateStr);

    return date !== '' ? date.format('YYYY-MM-DD') : date;
}

/**
 * Palauttaa päivien lukumäärän kahden päivämäärän välissä.
 * Päivämäärien on oltava muodossa YYYY-MM-DD.
 * @param startDateStr
 * @param endDateStr
 * @returns {null|number}
 */
function getDurationInDays(startDateStr, endDateStr) {
    const startDate = moment(startDateStr);
    const endDate = moment(endDateStr);

    if (startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate)) {
        return endDate.diff(startDate, 'days');
    }
    return null;
}

/**
 * Kun muutetaan tunnit kokonaisluvusta päivää x tuntia y muotoon
 * Factor kertoo millä jaetaan, defaulttina 24
 * @param units
 * @param factor
 * @param displayZeroDays - Näytetäänkö 0 päivää kuitenkin
 * @returns {string}
 */
const getDurationInDaysAndHours = (units, factor = '1', displayZeroDays) => {
    // Tehdään oletus, ettei tarvi tehdä mitään laskentaa, jos factori on 1 (1 pv = loma/vapaapäivä)
    if (factor === '1') {
        return units;
    }

    const daysLeft = Math.floor(units / factor);
    const daysLeftString = daysLeft !== 0 || displayZeroDays ?`${daysLeft} ${_trans('date.days')}` : '';
    const hoursLeft = units % factor;
    const hoursLeftString = hoursLeft !== 0 ? `${hoursLeft} ${_trans('time.hours')}` : '';

    return `${daysLeftString} ${hoursLeftString}`;
};

/**
 * Kertoo kuinka monta päivää on h-hetkeen.
 * Käytetään vaikkapa kertomaan montako päivää on jonkin featuren tuloon tai vanhan lähtöön.
 * @param countDownTill - päivämäärä DD.MM.YYY-muodossa.
 * @returns {number}
 */
function countDown(countDownTill) {
    const doomsDate = moment(countDownTill, 'DD.MM.YYYY');
    return doomsDate.diff(moment(), 'days');
}

/**
 * Generoi relatiivisen urlin määriteltyjen routejen perusteella ja liimaa loput polut perään.
 * @param routeName - Nimi josta Routing vääntää URLin.
 * @returns {string}
 */
function generateUrl(routeName) {
    // Poistetaan ensimmäinen argumentti joka on routeName ja liitetään palat yhteen / -merkillä
    const paths = Array.prototype.slice.call(arguments, 1).join('/');

    return `${Routing.generate(routeName)}/${paths}`;
}

/**
 * Palauttaa objektista query stringin.
 * @param params
 * @deprecated - käytä createQueryParams
 */
function getQueryString(params) {
    return Object.keys(params).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
}

/**
 * Palauttaa pääsisältöelementin.
 * @returns {HTMLElement | null}
 */
function getRootElement() {
    return document.getElementById('root');
}

/**
 * Palauttaa pääsisältöelementin ylämarginaalin.
 * @returns {number}
 */
function getRootMarginTop() {
    const rootElement = getRootElement();
    // Lasketaan sisällön padding koska yläosa on fiksattu. Muuten osa sisällöstä jäisi sen alle.
    const style = window.getComputedStyle(rootElement);
    return parseFloat(_.get(style, 'marginTop', 0));
}

/**
 * Palauttaa elementin leveyden.
 */
function getElementWidth(element) {
    if (! element) return null;

    const computedStyles = window.getComputedStyle(element);
    return parseFloat(_.get(computedStyles, 'width', 0));
}

/**
 * Scrollaa elementtiin smoothisti.
 * @param selector - elementin CSS-selector
 * @param align - mihin kohtaa ruutua elementti scrollataan: top, middle (oletus), bottom
 * @param offset
 */
function smoothScrollToElement(selector, align = 'middle', offset = 0) {
    scrollToElement(selector, {
        offset,
        duration: 250,
        align,
    });
}

/**
 * Vaihtaa koko sivun taustavärin joko valkoiseksi tai harmaaksi.
 * @param isClear
 */
function clearBackground(isClear = true) {
    const className = 'body--clear';
    const elements = document.getElementsByTagName('body');

    // Löytyikö body-elementti? Jos ei löydy on koko sivu kyllä rikki...
    if (elements.length) {
        const el = elements[0];

        // Cross-browser CSS-luokkien puljailu
        if (isClear) {
            if (el.classList)
                el.classList.add(className);
            else
                el.className += ' ' + className;
        } else {
            if (el.classList)
                el.classList.remove(className);
            else
                // eslint-disable-next-line security/detect-non-literal-regexp
                el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
}

/**
 * Lisää CSS-luokan elementille.
 * @param el
 * @param className
 */
function addClass(el, className) {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ' ' + className;
    }
}

/**
 * Poistaa CSS-luokan elementiltä.
 * @param el
 * @param className
 */
function removeClass(el, className) {
    if (el.classList) {
        el.classList.remove(className);
    } else {
        // eslint-disable-next-line security/detect-non-literal-regexp
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
}

/**
 * Ollaanko kuntapuolella urlin perusteella
 * @returns boolean
 */
export const isCommune = () => !!(/^\/commune\//gm.exec(location.pathname));

/**
 * Ollaanko adminissa urlin perusteella
 * @returns {RegExpExecArray & {groups: {}}}
 */
export const isAdmin = () => /^\/service\//gm.exec(location.pathname);

/**
 * Hakee query parametrin nimellä urlista
 *
 * @param name
 * @param url
 * @returns {*}
 */
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, '\\$&');
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Yksinkertaisesta objektista query parametrit URLSearchParams avulla.
 * Osaa handlata myöskin taulukot
 *
 * @param queryParams
 * @param parameters
 * @returns {string}
 */
export const createQueryParams = (queryParams, parameters = {}) => {
    if (Object.values(queryParams).length === 0) {
        return '';
    }

    // Poistetaanko querystä tyhjät parametrit
    const omitEmptyValues = parameters.omitEmptyValues ?? false;

    const transformValue = (value) => {
        if (value === null) {
            return '';
        }

        switch (typeof value) {
            case 'boolean':
                return Number(value).toString();
            default:
                return value;
        }
    };

    const params = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
        if (value instanceof Array) {
            value.forEach((subValue) => {
                params.append(`${key}[]`, subValue);
            });
        } else if (value instanceof Object) {

            /**
             * Nested objects -tuki
             * dimensions: {
             *     234: [9191, 39393],
             *     400: [1020]
             * }
             * => dimensions[234][]=9191&dimensions[234][]=39393&dimensions[400]=1020
             */

            Object.entries(value).map(([name, values]) => {
                if (values instanceof Array) {
                    values.map((innerValue) => {
                        params.append(`${key}[${name}][]`, transformValue(innerValue));
                    });
                } else {
                    params.append(`${key}.${name}`, transformValue(values));
                }
            });
        } else if (value && value !== '' || (value === '' && omitEmptyValues === false)) {
            params.append(key, transformValue(value));
        }
    });
    return params.toString();
};

/**
 * Querystringistä objekti. Tunnistaa myös arrayt.
 * @param queryString
 * @returns {{}|{[p: string]: any}|any}
 */
export const queryStringToObject = (queryString) => {
    try {
        const searchParams = new URLSearchParams(queryString);

        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            // Onko taulukkomuotoinen (olettaa että string päättyy [])
            const isArray = /.*\[]$/.test(key);
            if (isArray) {
                const arrayName = key.substr(0, key.length - 2);

                return {
                    ...acc,
                    [arrayName]: acc[arrayName]
                        ? [...acc[arrayName], value]
                        : [value]
                };
            } else {
                return {
                    ...acc,
                    [key]: value,
                };
            }
        }, {});
    } catch (e) {
        console.error(e);
        return {};
    }
};

/**
 * Palauttaa multiselectistä valitut arvot arraynä
 *
 * @param event
 * @param asInt
 * @returns {[]}
 */
function getMultiselectSelectedValuesAsArray(event, asInt = false) {
    const result = [];
    const options = event.target && event.target.options;
    let opt;

    for (let i = 0, iLen = options.length; i < iLen; i++) {
        opt = options[i];

        if (opt.selected) {
            if (asInt) {
                result.push(parseInt(opt.value, 10));
            } else {
                result.push(opt.value || opt.text);
            }
        }
    }
    return result;
}

/**
 * Onko pakolliset tiedot täytetty
 *
 * @param employee
 * @param checkEmail bool
 * @param allowEmptyIban bool
 * @return {boolean}
 */
function checkEmployeeReadiness(employee, checkEmail = false, allowEmptyIban = false) {
    const isValidSSN = employee?.socialSecurityNumber && validators.isSsn(employee.socialSecurityNumber);
    const isTemporarySSN = isSsnTemporary(employee?.socialSecurityNumber);
    const isStreetAddressValid = isTemporarySSN ? validators.isRequired(employee?.streetAddress) : true;
    const isPostCodeValid = isTemporarySSN ? validators.isPostCode(employee?.postCode) : true;
    const isTownValid = isTemporarySSN ? validators.isRequired(employee?.town) : true;
    const isAccountNumberValid = allowEmptyIban ? validators.isIban(employee?.accountNumber) :
        (validators.isRequired(employee?.accountNumber || '') && validators.isIban(employee?.accountNumber));

    const isEmailValid = checkEmail ? validators.isEmail(employee?.email) : true;
    const isFirstNameValid = validators.isRequired(employee?.firstName);
    const isLastNameValid = validators.isRequired(employee?.lastName);

    return isValidSSN
        && isStreetAddressValid
        && isPostCodeValid
        && isTownValid
        && isAccountNumberValid
        && isEmailValid
        && isFirstNameValid
        && isLastNameValid;
}

function upperCaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isEmptyString = (value) => ! value || value?.toString().replace('  ', '').trim() === '';

export const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export default {
    getUserFullName,
    generateUuid,

    checkEmployeeReadiness,
    isSsnTemporary,
    getAgeInYearsBySsn,
    resolveUserAgeInYears,

    convertToMoment,
    convertISOToMoment,
    convertToISO8601Date,
    convertToISO,
    getDurationInDays,
    getDurationInDaysAndHours,
    countDown,

    // Url
    getParameterByName,
    generateUrl,
    getQueryString,
    createQueryParams,

    // DOM
    getRootMarginTop,
    getElementWidth,
    getMultiselectSelectedValuesAsArray,
    smoothScrollToElement,

    // UI utils
    clearBackground,
    addClass,
    removeClass,

    // String utils
    upperCaseFirst,

    isCommune,
    isAdmin,
};
