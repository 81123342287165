import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import ProceedButton from './ProceedButton';

/**
 * Vaihtoehdon valintakomponentti. Valinta vahvistetaan napista.
 */
export default class Select extends Component {
    state = {
        value: null,
    };

    static propTypes = {
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
        })).isRequired,
        onAnswerQuestion: PropTypes.func.isRequired,
    };

    static defaultProps = {
        placeholder: _trans('text.select'),
    };

    onChange = (value) => this.setState({ value });

    onProceed = () => {
        this.props.onAnswerQuestion(this.state.value);
    };

    render() {
        const { placeholder, options, } = this.props;

        return (
            <Fragment>
                <select
                    onChange={(event) => this.onChange(event.target.value)}
                    className="c-inquisitor__select u-1/1 u-margin-bottom-small u-margin-bottom@medium"
                >
                    <option value="">
                        {placeholder}
                    </option>
                    {_.map(options, (option) => (
                        <option value={option.value} key={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>

                { this.state.value && (
                    <ProceedButton
                        label="Jatka"
                        onProceed={this.onProceed}
                        canProceedOnEnter={false}
                    />
                )}
            </Fragment>
        );
    }
}
