import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { ActionBar, SubmitButton, Page, MDSpinner } from 'shared/components';
import { StField, StLabel, StHelp } from 'shared/components/StForm';
import { dimensionType } from 'shared/shapes/dimensions';

@withRouter
@connect((state, props) => ({
    isNew: _.get(props, 'match.params.typeId') === 'new',
    typeId: _.get(props, 'match.params.typeId'),
    dimensionType: select.dimensions.getDimensionType(state),
    dimensionTypes: select.dimensions.getDimensionTypes(state),
    isLoading: _.get(state, 'loading.effects.dimensions.fetchDimensionType', false),
    isSaving: _.get(state, 'loading.effects.dimensions.postDimensionType', false)
        || _.get(state, 'loading.effects.dimensions.putDimensionType', false),
    isAdmin: select.userMetadata.isAdmin(state) || select.userMetadata.isCommuneAdmin(state),
    isCommune: select.userMetadata.isCommune(state),
}))
export default class DimensionTypeForm extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        typeId: PropTypes.string,
        isNew: PropTypes.bool,
        dimensionType,
        dimensionTypes: PropTypes.arrayOf(dimensionType),
        isLoading: PropTypes.bool,
        isSaving: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        isCommune: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        typeId: '',
        isNew: false,
        dimensionType: {},
        dimensionTypes: [],
        isLoading: false,
        isSaving: false,
        isAdmin: false,
        isCommune: false
    };

    constructor(props) {
        super(props);
        const { dispatch, typeId, isNew } = props;
        if (! isNew) {
            dispatch.dimensions.fetchDimensionType(typeId);
        }
    }

    onDimensionSubmit(model) {
        const { isNew, dispatch, routes } = this.props;
        if (isNew) {
            dispatch.dimensions.postDimensionType(model, routes.DIMENSION_TYPES);
        } else {
            dispatch.dimensions.putDimensionType(model, routes.DIMENSION_TYPES);
        }
    }

    validateDimensionTypeName(value) {
        const { dimensionTypes, isNew } = this.props;
        // Validoidaan vain uudet
        if (isNew) {
            return ! dimensionTypes.filter((type) => _.get(type, 'name') === value).size > 0;
        }
        return true;
    }

    render() {
        const { isNew, dimensionType, isLoading, isSaving, routes, isAdmin, isCommune } = this.props;
        if (isLoading) {
            return <MDSpinner wrapped/>;
        }
        return (
            <Page
                heading={_trans(isNew ?
                    'company_manager.dimensions.types.heading_create' :
                    'company_manager.dimensions.types.heading_update')}
                isBodyClear
                maxWidth="medium-2"
                goBack={{ to: routes.DIMENSION_TYPES, text: _trans('link.return_without_saving') }}
            >
                <Formik
                    initialValues={isNew ?
                        {
                            name: '',
                            isContract: false,
                            isTimeRegistration: false,
                            isRestricted: false,
                            isSalaryTypeDimension: false,
                            isVisibilityDimension: false,
                            isContractContactDimension: false,
                        } :
                        dimensionType}
                    enableReinitialize
                    onSubmit={(model) => this.onDimensionSubmit(model)}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(_trans('validation.required'))
                            .test('name', _trans('company_manager.dimensions.types.form.name.non_unique'), (value) => this.validateDimensionTypeName(value))
                            .test('name', _trans('validation.required'), (value) => value?.trim().length !== 0)
                    })}
                >
                    {(props) => (
                        <form className="o-form o-form--responsive" onSubmit={props.handleSubmit}>
                            <StLabel htmlFor="name">
                                {_trans('company_manager.dimensions.types.form.name.label')}
                            </StLabel>
                            <StField>
                                <Field
                                    id="name"
                                    name="name"
                                    type="text"
                                />
                                <FormikErrors name="name"/>
                            </StField>
                            { ! isCommune &&
                                <StField>
                                    <Field
                                        id="isContract"
                                        name="isContract"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => {
                                                        form.setFieldValue(field.name, ! field.value);
                                                        if (field.value) {
                                                            form.setFieldValue('isVisibilityDimension', false);
                                                            form.setFieldValue('isContractContactDimension', false);
                                                        }
                                                    }}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_contract.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            <StField>
                                <Field
                                    id="isContractContactDimension"
                                    name="isContractContactDimension"
                                >
                                    {({ field, form }) => (
                                        <label>
                                            <input
                                                disabled={ isCommune? ! form.values.isVisibilityDimension : ! form.values.isContract}
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={() => {
                                                    form.setFieldValue(field.name, ! field.value);
                                                }}
                                            />
                                            {_trans('company_manager.dimensions.types.form.is_contract_contact_dimension.text')}
                                        </label>
                                    )}
                                </Field>
                            </StField>
                            {!isCommune &&
                                <StField>
                                    <Field
                                        name="isSalaryTypeDimension"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    disabled={!_.get(form, 'values.isContract')}
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => form.setFieldValue(field.name, !field.value)}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_salary_type.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            {! isCommune &&
                                <StField>
                                    <Field
                                        id="isTimeRegistration"
                                        name="isTimeRegistration"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => {
                                                        form.setFieldValue(field.name, !field.value);
                                                        if (field.value) {
                                                            // Jos oli valittu, niin nollataan myöskin isRestricted
                                                            form.setFieldValue('isRestricted', false);
                                                        }
                                                    }}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_time_registration.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            <StField>
                                <Field
                                    id="isSearchDimension"
                                    name="isSearchDimension"
                                >
                                    {({ field, form }) => (
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={() => {
                                                    form.setFieldValue(field.name, !field.value);
                                                    if (field.value) {
                                                        // Jos oli valittu, niin nollataan myöskin isRestricted
                                                        form.setFieldValue('isRestricted', false);
                                                        form.setFieldValue('isPrimarySearchDimension', false);
                                                    }
                                                }}
                                            />
                                            {isCommune ? _trans('company_manager.dimensions.types.form.commune_is_search_dimensions.text') : _trans('company_manager.dimensions.types.form.is_search_dimension.text')}
                                        </label>
                                    )}
                                </Field>
                            </StField>
                            { ! isCommune &&
                                <StField>
                                    <Field
                                        id="isPrimarySearchDimension"
                                        name="isPrimarySearchDimension"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    disabled={! _.get(form, 'values.isSearchDimension')}
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => form.setFieldValue(field.name, ! field.value)}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_primary_search_dimension.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            { ! isCommune &&
                                <StField>
                                    <Field
                                        id="isPaymentFocusingDimension"
                                        name="isPaymentFocusingDimension"
                                        aria-describedby="isPaymentFocusingDimensionHelp"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => {
                                                        form.setFieldValue(field.name, ! field.value);
                                                        if (field.value) {
                                                            // Jos oli valittu, niin nollataan myöskin isRestricted
                                                            form.setFieldValue('isRestricted', false);
                                                        }
                                                    }}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_payment_focusing_dimension.text')}
                                            </label>
                                        )}
                                    </Field>
                                    <StHelp id="isPaymentFocusingDimensionHelp">
                                        Tämän dimension eri arvoilla syntyy erillisiä maksueriä. HUOM: Valtuushallintadimensio ohittaa tämän ja maksuserät syntyvät valtuushallintadimension perusteella.
                                    </StHelp>
                                </StField>
                            }
                            {! isCommune && (
                                <StField isLabeless>
                                    <Field
                                        id="isCustomerInvoicingDimension"
                                        name="isCustomerInvoicingDimension"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => {
                                                        form.setFieldValue(field.name, ! field.value);
                                                    }}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_customer_invoicing_dimension.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            )}
                            <StField>
                                <Field
                                    id="isServiceChargeInvoicingDimension"
                                    name="isServiceChargeInvoicingDimension"
                                    aria-describedby="isServiceChargeInvoicingDimensionHelp"
                                >
                                    {({ field, form }) => (
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={() => {
                                                    form.setFieldValue(field.name, ! field.value);
                                                    if (field.value) {
                                                        // Jos oli valittu, niin nollataan myöskin isRestricted
                                                        form.setFieldValue('isRestricted', false);
                                                    }
                                                }}
                                            />
                                            {_trans('company_manager.dimensions.types.form.is_service_charge_invoicing_dimension.text')}
                                        </label>
                                    )}
                                </Field>
                                <StHelp id="isServiceChargeInvoicingDimensionHelp">
                                    Asiakkaan laskulle palvelumaksuista tehdään erilliset rivit tämän dimension eri arvoista.
                                </StHelp>
                            </StField>
                            {isAdmin && (
                                <StField>
                                    <Field
                                        id="isSalesInvoiceGrouping"
                                        name="isSalesInvoiceGrouping"
                                        aria-describedby="isSalesInvoiceGroupingHelp"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    className="u-color-admin"
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => {
                                                        form.setFieldValue(field.name, ! field.value);
                                                    }}
                                                />
                                                <span className="u-color-admin">
                                                    {_trans('Erilliset laskut tämän dimension mukaan')}
                                                </span>
                                            </label>
                                        )}
                                    </Field>
                                    <StHelp id="isSalesInvoiceGroupingHelp">
                                        Asiakkaalle lähetetään erillinen lasku palvelumaksuista tämän dimension eri arvoista.
                                    </StHelp>
                                </StField>
                            )}
                            <StField>
                                <Field
                                    id="isVisibilityDimension"
                                    name="isVisibilityDimension"
                                    aria-describedby="isVisibilityDimensionHelp"
                                >
                                    {({ field, form }) => (
                                        <label>
                                            <input
                                                disabled={! form.values.isContract && ! form.values.isBenefitDecisionDimension}
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={() => {
                                                    form.setFieldValue(field.name, ! field.value);
                                                }}
                                            />
                                            {_trans('company_manager.dimensions.types.form.is_visibility_dimension.text')}
                                        </label>
                                    )}
                                </Field>
                                <StHelp id="isVisibilityDimensionHelp">
                                    Asiakas säätelee näkyvyyttä henkilötietoihin tämän dimension perusteella. Hyvivointialueen käyttäjille määritellään mitä valtuushallinta-alueita he näkevät.
                                </StHelp>
                            </StField>
                            {isCommune && <StField>
                                <Field
                                    id="isBenefitDecisionDimension"
                                    name="isBenefitDecisionDimension"
                                >
                                    {({ field, form }) => (
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={() => {
                                                    form.setFieldValue(field.name, ! field.value);
                                                    if (field.value) {
                                                        form.setFieldValue('isContractContactDimension', false);
                                                    }
                                                }}
                                            />
                                            {_trans('company_manager.dimensions.types.form.is_benefit_decision_dimension.text')}
                                        </label>
                                    )}
                                </Field>
                            </StField>}
                            { ! isCommune &&
                                <StField>
                                    <Field
                                        id="isRestricted"
                                        name="isRestricted"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    disabled={! _.get(form, 'values.isTimeRegistration')}
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => form.setFieldValue(field.name, ! field.value)}
                                                />
                                                {_trans('company_manager.dimensions.types.form.is_restricted.text')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            { ! isCommune &&
                                <StField>
                                    <Field
                                        id="isBookkeepDefaultDimension"
                                        name="isBookkeepDefaultDimension"
                                    >
                                        {({ field, form }) => (
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={field.value}
                                                    onChange={() => form.setFieldValue(field.name, ! field.value)}
                                                />
                                                {_trans('Käytetäänkö dimensioita tiliöinnin oletusdimensioina', {}, 'company_manager')}
                                            </label>
                                        )}
                                    </Field>
                                </StField>
                            }
                            <ActionBar>
                                <SubmitButton
                                    mdIcon="save"
                                    isPending={isSaving}
                                >
                                    {_trans('button.save')}
                                </SubmitButton>
                            </ActionBar>
                        </form>
                    )}
                </Formik>
            </Page>
        );
    }
}
