import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { actions, LocalForm, Control } from 'react-redux-form';
import { Iterable, List } from 'immutable';
import classNames from 'classnames';
import _ from 'lodash';
import UpdateUserContractData from '../private/UpdateUserContractData';
import SsnQuery from './SsnQuery';
import utils from 'shared/utils/commonUtils';
import formUtils, { parseApiErrors } from 'shared/utils/formUtils';
import { StLabel, StField, StErrors, StHelp } from 'shared/components/StForm/index';
import request from 'BubbleWrapAgent';
import ActionBar from 'shared/components/ActionBar/index';
import Button from 'shared/components/Button';
import SubmitButton from 'shared/components/SubmitButton';
import Layout from 'shared/components/Layout/index';
import { locales } from 'shared/constants';
import ChangeHeta from 'shared/UserDetails/containers/commune/ChangeHeta';
import {
    FirstName,
    LastName,
    StreetAddress,
    PostCode,
    Town,
    Email,
    PhoneNumber,
    Locale,
    AccountNumber,
} from 'shared/components/Form';
import ButtonGroup from 'shared/components/ButtonGroup';
import { Feedback, Switch, Tooltip, MDIcon, InputGroup } from 'shared/components';
import alertTypes from 'shared/constants/alertTypes';
import EmployeeWorkExperience from 'shared/components/Form/EmployeeWorkExperience';
import { userTypes } from 'shared/constants/userTypes';
import DimensionPricing from 'shared/UserDetails/containers/shared/DimensionPricing';
import validators from 'shared/validators';
import commonUtils from 'shared/utils/commonUtils';
import InputPostfix from 'shared/components/InputPostfix';

export default class UserForm extends PureComponent {

    state = {
        user: null,
        isPending: false,
        isEditMode: false,
        showSelectContracts: false,
        contractIds: [],
        model: {},
        accountNumberValue: '',
        email: '',
    };

    UNSAFE_componentWillMount() {
        // Tehdään tämä vain tässä, jotta kun tallennetaan, laitetaan edit mode pois päältä.
        // (WillReceiveProps asettaa koko ajan editModen päälle muuten)
        //Jos wizard query string tai edit = 1, niin laitetaan editMode päälle (YP: jos tullaan dashboardin wizardista)
        let isEditMode = this.props.isEditMode;
        if (utils.getParameterByName('wizard') || utils.getParameterByName('edit') === '1') {
            isEditMode = true;
        }
        this.setState({
            isEditMode,
        });
        this.setInitialUser(this.props);
    }

    /**
     * Aseta LocalFormiin vain User-entityn vaatimat kentät tietojen
     * muuttuessa tai muuten Symfony möksähtää.
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (!_.isEqual(nextProps.initialUser, this.props.initialUser)
            || nextProps.initialSsn !== this.props.initialSsn
            || nextProps.email !== this.props.email
        ) {
            this.setInitialUser(nextProps);
        }
    };

    setInitialUser(props) {
        const initialSsn = _.get(props, 'initialSsn', '');
        const initialUser = Iterable.isIterable(props.initialUser)
            ? props.initialUser.toJS()
            : props.initialUser;
        const isSearchingUser = initialSsn.trim() !== '' && validators.isSsn(initialSsn);

        // RRF ei tykkää jos joskus tulee array ja joskus objekti (tyhjä attributes => array)
        const exposedAttributes = Array.isArray(initialUser?.exposedAttributes)
            ? {}
            : initialUser?.exposedAttributes;

        const user = Object.assign({}, initialUser ?? {}, exposedAttributes);

        this.setState({
            user,
            initialSsn,
            isSearchingUser,
            email: _.get(initialUser, 'email', ''),
        });
    }

    /**
     * Palauta vain se käyttäjädata jonka bäkkäri hyväksyy
     */
    filterUserData = (user) => ({
        userId: _.get(user, 'userId', ''),
        socialSecurityNumber: _.get(user, 'socialSecurityNumber', ''),
        firstName: _.get(user, 'firstName', ''),
        lastName: _.get(user, 'lastName', ''),
        streetAddress: _.get(user, 'streetAddress', ''),
        postCode: _.get(user, 'postCode', ''),
        town: _.get(user, 'town', ''),
        email: _.get(user, 'email', ''),
        phoneNumber: _.get(user, 'phoneNumber', ''),
        attributes: this.filterHideFullSSN(_.get(user, 'exposedAttributes', {})),
        locale: _.get(user, 'locale', 'fi'),
        accountNumber: _.get(user, 'accountNumber', ''),
        employeeWorkExperience: _.get(user, 'employeeWorkExperience', null),
        jobTitle: _.get(user, 'jobTitle', null),
        relationType: this.props.relationType,
        customerInvoicingMonthlyPrice: user.customerInvoicingMonthlyPrice ?? null,
    });

    /**
     * Poistetaan hideFullSSN-attribuutti, koska tämän säätöön on oma valitsin.
     * Ja esim. yrityksellä aiheuttaa validaatio virheen bäkkärillä.
     *
     * @param attributes
     */
    filterHideFullSSN = (attributes) => _.omit(attributes, 'hideFullSSN');

    changeHideFullSSN = () => {
        const value = _.get(this.state.user.exposedAttributes, 'hideFullSSN', false);
        this.updateUserAttribute('hideFullSSN', !value);
    };

    updateUserAttribute(path, value) {
        this.setState({ isLoading: true });

        const data = {
            op: 'replace',
            path,
            value,
        };

        request
            .patch(Routing.generate('api_1_patch_user', { user: this.state.user.userId }))
            .send({ data: [data] })
            .end((error, response) => {
                const user = _.cloneDeep(this.state.user);
                if (error) {
                    this.props.onError(response.body.message ? response.body.message : _trans('form.save.error'));
                } else {
                    // jos ei löydy attribuutteja, bäkkäri palautta tyhjän arrayn, muuten objektin, muutetaan tässä objektiksi.
                    if (_.isEmpty(user.exposedAttributes) && _.isArray(user.exposedAttributes)) {
                        user.exposedAttributes = { [path]: value };
                    } else {
                        user.exposedAttributes[path] = value;
                    }
                }

                this.setState({ isLoading: false, user });
                // Pävitetään mahdolliseen storeen pävitetty data.
                // Passataan myös tarkempi tieto siitä että kyseessä on "vain" patch.
                this.props.onUserUpdate(user, {
                    isPatchRequest: true,
                });
            });
    }

    fetchCreatedUser(url) {
        request
            .get(url)
            .end((error, response) => {
                this.setState({ isEditMode: false });

                const user = _.get(response, 'body', null);

                if (error || user === null) {
                    this.props.addNotification({
                        type: 'error',
                        message: _trans('notifications.user.save.error'),
                    });
                } else {
                    this.props.addNotification({
                        type: 'success',
                        message: _trans('notifications.user.save.success'),
                    });
                    this.onUserUpdate(user);
                    this.setState({ user });
                }
            });
    }

    canSubmit() {
        return this.isEditable() && this.state.sendOngoing === false;
    }

    isExistingUser = () => {
        const userId = parseInt(_.get(this.state.user, 'userId', -1), 10);
        return userId > 0;
    };

    // Näillä rooleilla käyttäjä ei saa muokata omia tietojaan koska sillä ei vaikutusta ulospäin
    disableAddressAndBankAccountInputs = () => {
        // TODO: ei riitä pelkästään tämä
        const { isPrivate } = this.props;

        return [isPrivate].some((value) => value === true);
    };

    isEditable() {
        return _.has(this.state.user, 'userId');
    }

    onEnableEditMode = (event) => {
        event.preventDefault();
        this.setState({ isEditMode: true });
    };

    onDisableEditMode = (event) => {
        event.preventDefault();
        this.setState({ isEditMode: false });
        // Perutaan muutokset, niin ladataan statesta alkuperäiset muutokset.
        this.formDispatch(actions.change(this.props.model, this.state.user));
        this.props.onCancel();
    };

    canEdit() {
        return this.isExistingUser();
    }

    // Jos tilapäinen => vaaditaan osoitetiedot koska tulorekisteri
    isSsnTemporary = () => utils.isSsnTemporary(_.get(this.state.user, 'socialSecurityNumber', ''));

    /**
     * Renderöi muokkaustilassa Peruuta ja Tallenna -napit. Muutoin Muokkaa-napin.
     */
    renderActionButtons() {
        const {
            isEditMode,
        } = this.state;

        const { isPrivate, canCancel } = this.props;

        const SaveButton = () => (
            <SubmitButton
                primary
                isPending={this.state.isSaving}
            >
                {isPrivate ? _trans('users.action.save_own') : _trans('users.action.save')}
            </SubmitButton>
        );

        const EditButton = () => (
            <Button
                onClick={this.onEnableEditMode}
                mdIcon="edit"
            >
                {isPrivate ? _trans('button.edit') :_trans('users.action.edit')}
            </Button>
        );

        const CancelButton = () => (
            <Button
                onClick={this.onDisableEditMode}
                disabled={this.state.isSaving}
            >
                {_trans('users.action.cancel')}
            </Button>
        );

        return (
            <div className="u-padding-top-small u-padding-top@medium">
                <ActionBar alignItems={ (isEditMode && canCancel) ? 'justify' : 'right' }>
                    { !isEditMode && <EditButton/> }
                    { (isEditMode && canCancel) && <CancelButton/> }
                    { isEditMode && <SaveButton/> }
                </ActionBar>
            </div>
        );
    }

    onHetaChange = (obj) => {
        const user = _.clone(this.state.user);
        _.merge(user, { exposedAttributes: obj, attributes: obj });
        this.onUserUpdate(user);
        this.setState({ user });
    };

    /**
     * Renderöi Heta-liiton jäsenyystiedon mikäli kyseessä on kunta työnantaja.
     */
    renderHetaMembership(isReadOnly) {
        if (! this.props.isCommune) {
            return null;
        }
        // Jos ei ole TA tai uusi käyttäjä ja avust
        if (! this.props.isEmployer && ! (this.props.isNewUser && this.props.isBeneficiary)) {
            return null;
        }

        let isHetaMember = _.get(this.state.user, 'attributes.isHeta', false);

        // Kannassa on '0', 0, false, '1', 1 tai true arvoja, niin muutetaan numeroksi, josta sitten booleaniksi,
        // jos ei ole boolean valmiiksi.
        if (! _.isBoolean(isHetaMember)) {
            isHetaMember = Boolean(parseInt(isHetaMember, 10));
        }

        const hetaJoinDate = _.get(this.state.user, 'attributes.hetaJoinDate', false);
        const userId = _.get(this.state.user, 'userId', -1);

        if (!this.props.isNewUser && userId > 0) {
            return (
                <ChangeHeta
                    isHetaMember={isHetaMember}
                    userId={userId}
                    onError={this.props.onError}
                    onHetaSave={this.onHetaChange}
                    hetaJoinDate={hetaJoinDate}
                />
            );
        }

        if (isReadOnly) {
            return (
                <div>
                    <span className="u-display-inline-block">{`${_trans('userForm.hetaMember.label')}:`}</span>
                    <strong className="u-text-uppercase u-align-middle u-margin-tiny">
                        { _trans(isHetaMember ? 'yes' : 'no') }
                    </strong>
                    {isHetaMember &&
                        <li >
                            {hetaJoinDate ? _trans('userForm.hetaMember.join_date', { date: _toLocaleDate(hetaJoinDate) }) : _trans('userForm.hetaMember.unknown_date')}
                        </li>
                    }
                </div>
            );
        } else {
            return (
                <div className="u-padding-top-small">
                    <label>
                        <Control.checkbox
                            aria-describedby="isHetaHelp"
                            model=".exposedAttributes.isHeta"
                        /> {_trans('userForm.hetaMemberShip.label')}
                    </label>
                    <StHelp id="isHetaHelp">
                        {_trans('benefit_decision.form.heta_member.help')}
                    </StHelp>
                    <StErrors model=".attributes.isHeta" />
                    <StErrors model=".attributes.startDate" />
                </div>
            );
        }
    }

    renderToggleCommuneTimeRegistrationCheckbox(isReadOnly) {
        // Uudelle käyttäjälle ei näytetä
        if (this.props.isNewUser) {
            return null;
        }
        let notification = null;
        const hasEmail = !!this.state.email;
        if (!hasEmail && !isReadOnly) {
            notification = (
                <Feedback type={alertTypes.WARNING}>
                    {_trans('userForm.self_priming.warning')}
                </Feedback>
            );
        }

        const isPassiveConfirmationType = _.get(this.state, 'user.type') === userTypes.PASSIVE_USER_CONFIRMATION;
        const emailConfirmationKey = _.get(this.state, 'user.emailConfirmationKey');
        const isTimeregistrationEnabled = !! _.get(this.state, 'user.exposedAttributes.enableCommuneTimeregistration');

        return (
            <Fragment>
                {notification}
                {/* Annetaan mahdollisuus kopioida linkki  */}
                {isPassiveConfirmationType && emailConfirmationKey && isTimeregistrationEnabled && (
                    <Feedback type="info">
                        {_trans('userForm.communeEmployeeTimeregistration.info')}
                        <br />
                        <a
                            href={Routing.generate('suoratyo_register_upgrade', { param: emailConfirmationKey }, true)}
                        >
                            {Routing.generate('suoratyo_register_upgrade', { param: emailConfirmationKey }, true)}
                        </a>
                    </Feedback>
                )}
                <StField isLabeless>
                    <label>
                        <Control.checkbox
                            model=".exposedAttributes.enableCommuneTimeregistration"
                            disabled={isReadOnly || !hasEmail}
                        />
                        {_trans('userForm.communeEmployeeTimeregistration.label')}
                    </label>
                </StField>
            </Fragment>
        );
    }

    renderToggleYELInsuredEmployee(isReadOnly) {
        return (
            <span>
                <StField isLabeless>
                    <label>
                        <Control.checkbox
                            model=".exposedAttributes.isYelInsured"
                            disabled={isReadOnly}
                        />
                        <Tooltip title={_trans('company.form.yel_insured.tooltip')}>
                            {_trans('company.form.yel_insured.label')}
                        </Tooltip>
                    </label>
                </StField>
            </span>
        );
    }

    renderTogglePrepaymentRegisterEmployee(isReadOnly) {
        const model = '.exposedAttributes.isPrepaymentRegisterFamilyCarer';
        return (
            <StField isLabeless>
                <label>
                    <Control.checkbox
                        model={model}
                        disabled={isReadOnly}
                        aria-labelledby={`${model}Label`}
                    />
                    <Tooltip title={_transMd('userForm.prepayment_register.tooltip', true)}>
                        {_trans('userForm.prepayment_register.label')} <MDIcon icon="help_outline" focusable modifierClass="u-cursor-pointer" />
                    </Tooltip>
                </label>
            </StField>
        );
    }

    renderAccountFeedback() {
        // Näytetään vain jos on TT, mutta ei kuitenkaan yrityksen tai kunnan tai kotitalouden hallinnoimalle TT:lle
        if (this.state.user.accountNumber
            && this.state.user.accountNumber !== this.state.accountNumberValue
            && this.state.accountNumberValue !== ''
            && this.props.isEmployee
            && (! this.props.isCompany && ! this.props.isCommune && _.get(this.state, 'user.isCurrentUser', false))
        ) {
            return (
                <Feedback
                    type="error"
                    title={_trans('userForm.accountNumber.feedback.title')}
                    message={_trans('userForm.accountNumber.feedback.message')}
                    modifierClass="u-margin-bottom-small"
                />
            );
        }
    }

    renderAddAccountNumberLater() {
        const { isEmployee, isCompany, isAssignmentContract, canAddAccountNumberLater } = this.props;

        if (this.state.accountNumberValue === ''
            // tilinron jos on syöttäny niin pitää syöttää uus tilalle
            && (! this.state.user.accountNumber)
            && this.state.isEditMode
            && isEmployee
            && canAddAccountNumberLater === true
        ) {
            const tooltipTitle = isCompany && isAssignmentContract ?
                _trans('Hoitajan voi lisätä vain keskeneräiselle sopimukselle', {}, 'extract') :
                _trans('Työntekijän voi lisätä vain keskeneräiselle sopimukselle', {}, 'extract');
            return (
                <label>
                    <Control.checkbox
                        model=".exposedAttributes.allowEmployerWithoutAccountNumber"
                        onChange={(event) => {
                            this.setState({
                                user: Object.assign({}, this.state.user, {
                                    exposedAttributes: Object.assign({}, this.state.user.exposedAttributes, {
                                        allowEmployerWithoutAccountNumber: (event.target.checked === true),
                                    })
                                })
                            });
                        }}
                    />
                    <Tooltip title={tooltipTitle}>
                        {_trans('Lisää tilinumero myöhemmin', {}, 'extract')}
                        <MDIcon icon="help_outline" focusable modifierClass="u-cursor-pointer u-margin-left-tiny" />
                    </Tooltip>
                </label>
            );
        }
    }

    /**
     * Tilinumero sekä kuntien TT tuntiensyöttö checkboxi
     */
    renderEmployeeFields(isReadOnly) {
        const { isEmployee, isEmployer, isCommune, isCompany, isPrivate, isNewUser, isAssignmentContract, isMultipleWorkExpLimitsApplied, relationType } = this.props;
        if (! isEmployee && ! isPrivate) {
            return null;
        }

        const fields = [];

        if (_.get(this.state.user, 'exposedAttributes.allowEmployerWithoutAccountNumber', false)) {
            fields.push(this.renderAddAccountNumberLater());
        } else {
            fields.push((
                <span key={0}>
                    <AccountNumber
                        isCommune={isCommune}
                        isReadOnly={isReadOnly || this.disableAddressAndBankAccountInputs()}
                        isEmployer={isEmployer}
                        relationType={relationType}
                    />
                    {this.renderAccountFeedback()}
                    {this.renderAddAccountNumberLater()}
                </span>
            ));
        }

        if (isCompany && ! isAssignmentContract) {
            fields.push(this.renderToggleYELInsuredEmployee(isReadOnly));
        }

        if (isCommune) {
            fields.push(this.renderToggleCommuneTimeRegistrationCheckbox(isReadOnly));
        }

        if (isCompany && isAssignmentContract) {
            fields.push(this.renderTogglePrepaymentRegisterEmployee(isReadOnly));
        }

        if (isNewUser && (isCommune || isCompany) && ! isAssignmentContract) {
            fields.push(<EmployeeWorkExperience
                jobTitles={this.props.jobTitles}
                isReadOnly={isReadOnly}
                isCommune={isCommune}
                isMultipleWorkExpLimitsApplied={isMultipleWorkExpLimitsApplied}
                model=".employeeWorkExperience"
            />);
        }

        return fields.map((field, index) => {
            if (field) {
                return (
                    <Layout.Item key={`employeeField-${index}`} medium="1/2">
                        {field}
                    </Layout.Item>
                );
            }
        });
    }

    renderCaredFields(isReadOnly) {
        const { isCompany, isMonthlySalaryCustomerInvoicingEnabled, isCared, } = this.props;

        // Pitää olla kompanion
        if (! isCompany) {
            return null;
        }

        // Pittää olla hoidettava-relaatiolla
        if (! isCared) {
            return null;
        }

        if (! isMonthlySalaryCustomerInvoicingEnabled) {
            return null;
        }

        return (
            <Layout.Item medium="1/2">
                <StLabel htmlFor="customerInvoicingMonthlyPrice">
                    {_trans('Kuukausiperusteinen omavastuuhinta', {}, 'extract')}
                </StLabel>
                <StField>
                    <InputGroup>
                        <Control.text
                            id="customerInvoicingMonthlyPrice"
                            model=".customerInvoicingMonthlyPrice"
                            parser={(value) => String(value || '').replace(',', '.')}
                            formatter={(value) => String(value || '').replace('.', ',')}
                            validators={{
                                isDecimal: validators.isDecimalAndAllowEmptyString,
                            }}
                            aria-describedby="customerInvoicingMonthlyPriceHelp"
                            readOnly={isReadOnly}
                        />
                        <InputPostfix postfix="€ / kk" />
                    </InputGroup>
                    <StErrors model=".customerInvoicingMonthlyPrice" />
                </StField>
                <StHelp id="customerInvoicingMonthlyPriceHelp">
                    {_trans('Aseta tähän hinta, joka hoidettavalta laskutetaan kuukausittain. Tyhjänä hoidettavalta ei laskuteta mitään.', {}, 'extract')}
                </StHelp>
            </Layout.Item>
        );
    }

    renderHideFullSSNButton() {
        if (
            (this.props.isCommune || this.props.isCompany || ! _.get(this.state, 'user.isCurrentUser', false)) &&
            !this.props.isNewUser && this.props.isEmployee
        ) {
            return (
                <label>
                    <Switch
                        id="showSsnSwitch"
                        disabled={this.state.isLoading}
                        isOn={_.get(this.state.user, 'exposedAttributes.hideFullSSN', false) === false}
                        onChange={this.changeHideFullSSN}
                    >
                        {_trans('userForm.socialSecurityNumber.ssn_postfix_visible_on_payslip_reports.label')}
                    </Switch>
                </label>
            );
        }
        return null;
    }

    renderHolidayDayReportButton() {
        const { isCommune, isCompany, isEmployee, isEmployer, isAssignmentContract } = this.props;
        if (isAssignmentContract) {
            return null;
        }
        if (! isCommune && ! isCompany) {
            return null;
        }
        const userId = this.state.user.userId;

        if (! userId) {
            return null;
        }

        const communeRoute = isCommune ? 'commune_' : '';

        if (isEmployee && isEmployer) {
            return (
                <span>
                    <h4>{_trans('reports.report.holiday_days_by_employer_and_employee.title')}</h4>
                    <ButtonGroup>
                        <Button
                            href={Routing.generate(`${communeRoute}suoratyo_reports_available_holidays_by_employee`, { employee: userId })}
                            target="_blank"
                        >{_trans('role.employee')}</Button>
                        <Button
                            href={(() => {
                                // kuntapuolella TA pitää laittaa
                                if (isCommune) return Routing.generate(`${communeRoute}suoratyo_reports_available_holidays_by_employer`, { employer: userId });
                                // YP:llä TA on aina tiedossa
                                else return Routing.generate('suoratyo_reports_available_holidays_by_employer');
                            })()}
                            target="_blank"
                        >{_trans('role.employer')}</Button>
                    </ButtonGroup>
                </span>
            );
        }

        return (
            <Button
                href={(() => {
                    // kummallakin puolella TT pitää laittaa
                    if (! isEmployer) return Routing.generate(`${communeRoute}suoratyo_reports_available_holidays_by_employee`, { employee: userId });
                    // kuntapuolella TA pitää laittaa
                    else if (isCommune) return Routing.generate(`${communeRoute}suoratyo_reports_available_holidays_by_employer`, { employer: userId });
                    // YP:llä TA on aina tiedossa
                    else return Routing.generate('suoratyo_reports_available_holidays_by_employer');
                })()}
                target="_blank"
            >
                {_trans('reports.report.holiday_days_by_employer_and_employee.title')}
            </Button>
        );
    }

    onRequestResponse = (error, response) => {

        this.setState({ isEditMode: false, isSaving: false });

        if (error) {
            this.props.addNotification({
                type: 'error',
                message: parseApiErrors(response.body),
            });

        } else {
            if (_.get(response, 'body.status', '') === 'error') {
                if (_.get(response, 'body.error', '') === 'email_in_use') {
                    this.setEmailValidity('user', false);
                } else {
                    this.props.addNotification({
                        type: 'error',
                        message: _trans(_.get(response.body, 'message', 'notifications.user.save.error')),
                    });
                }
                return;
            }
            // Palauttaako bäkkäri tallennetun käyttäjän?
            if (response.header['location'] && ! this.props.isAssignmentContract && ! this.props.isEmployer) {
                this.fetchCreatedUser(response.header['location']);
            } else {
                // TODO: getUserAction ei palauta normaalpia api vastausta (putin jäleen), kun taas postUsers palauttaa, joten kikkakutonen tässä
                // Kun ulkoiset api käyttäjät poistuvat voisi yhdistää responset saman näköisiksi...
                const user = response.body.data ?? response.body;
                this.formDispatch(actions.load(this.props.model, user));
                this.setState({ user });
                this.onUserUpdate(user);
                const url = new URL(window.location.href);
                // Jos ollaan wizard-moodissa ohjataan takaisin etusivulle 1s kuluttua
                if (url.searchParams.get('wizard') === '1') {
                    setTimeout(() => {
                        window.location.href = '/home';
                    }, 1000);
                }
                this.props.addNotification({
                    type: 'success',
                    message: _trans('notifications.user.save.success'),
                });
            }
        }
    };

    updateUser(contractIds, model) {
        if (! model) {
            model = this.state.model;
        }
        if (! contractIds) {
            contractIds = this.state.contractIds;
        }
        this.setState({ showSelectContracts: false });
        if (contractIds.length > 0) {
            _.assign(model, { contractIds });
        }
        // Asetetaan formin ulkopuolelta käyttäjän tietoihin mahdollisesti käyttäjällä ennestään oleva costCenter,
        // jotta se ei katoaisi uuden UserDatan muodostuksessa
        const costCenter = _.get(this.state, 'user.costCenter', '');
        _.assign(model, { costCenter });

        request
            .put(Routing.generate('api_1_put_user', { user: this.state.user.userId } ))
            .send(model)
            .end(this.onRequestResponse);
    }

    onSubmit = (model) => {

        this.setState({ isSaving: true });

        model = this.filterUserData(model);
        model = _.omit(model, ['userId']);//, 'attributes']);

        if (this.isExistingUser()) {
            if (this.props.userContracts.count() > 1) {
                this.setState({ showSelectContracts: true, model });
            } else {
                const contractIds = [];
                if (! this.props.userContracts.isEmpty()) {
                    const contract = this.props.userContracts.first();
                    contractIds.push(contract.get('jobContractId', null));
                }
                this.updateUser(contractIds, model);
            }
        } else if (this.props.isEmployer) {
            // Lisätään postiin bd jotta saadaan käyttäjä oikean hyveen alle.
            request
                .post(Routing.generate('api_1_post_user'))
                .query({ allowOver100yUser: 1, benefitDecision: this.props.benefitDecisionId })
                .send(model)
                .end(this.onRequestResponse);
        } else {
            /*
             OY-188: Älä salli yli 100v hetuja rajapinnasta
             Bäkkäri ei hyväksy enää yli 100v hetuja ellei anneta parametria joka sallii tämän.
             frontilla varmistetaan jo erikseen joten voidaan vain heittää parametri mukaan
             */
            request
                .post(Routing.generate('api_1_post_user'))
                .query({ allowOver100yUser: 1 })
                .send(model)
                .end(this.onRequestResponse);
        }
    };

    onSubmitFailed = (model) => {
        this.props.onSubmitFailed(model);
        formUtils.scrollToFirstError(model);
    };

    updateUserFromVtjResponse = (user, response) => {
        const userInformation = response.body?.data?.user_information;

        if (typeof userInformation !== 'object' || userInformation === null || Object.values(userInformation).length === 0) {
            return user;
        }

        return Object.assign({}, user, {
            firstName: userInformation.first_name ?? user.firstName,
            lastName: userInformation.last_name ?? user.lastName,
            streetAddress: userInformation.street_address ?? user.streetAddress,
            postCode: userInformation.post_code ?? user.postCode,
            town: userInformation.town ?? user.town,
            exposedAttributes: Object.assign({}, user.exposedAttributes, {
                isFromVtj: true,
                isAddressFromVtj: (!! userInformation.street_address && !! userInformation.post_code && !! userInformation.town),
                blockVtjUpdate: false
            })
        });
    }

    onUserFound = (user) => {
        const { isEmployee, isCommune, isVtjEnabled } = this.props;

        if (!isVtjEnabled || (isEmployee && isCommune) || !!user?.exposedAttributes?.isFromVtj) {
            this.afterUserFound(user);
            return;
        }

        request
            .post(Routing.generate('update_user_information_from_vtj'))
            .send({ ssn: user.socialSecurityNumber })
            .end((error, response) => {
                const updatedUser = this.updateUserFromVtjResponse(user, response);

                this.afterUserFound(updatedUser);
                this.setState({ user: updatedUser });
            });
    };

    afterUserFound = (user) => {
        const { isEmployee, isBeneficiary, isEmployer } = this.props;

        if (!_.isUndefined(this.formDispatch)) {
            this.formDispatch(actions.change(this.props.model, user));
        }

        if (isEmployee) {
            if (! commonUtils.checkEmployeeReadiness(user)) {
                this.props.onError(_trans('contract.notifications.error.employee_missing_data'));
                this.setState({
                    isSearchingUser: false,
                    user,
                    isEditMode: true,
                });

                return;
            }
        }

        if (isBeneficiary || isEmployer) {
            const hasAddressData = user.streetAddress?.length > 0 && user.postCode?.length > 0 && user.town?.length > 0;
            if (! hasAddressData) {
                const errorMessage = isBeneficiary
                    ? _trans('Avustettavalta puuttuu pakollisia tietoja', {}, 'extract')
                    : _trans('Työnantajalta puuttuu pakollisia tietoja', {}, 'extract');
                this.props.onError(errorMessage);
                this.setState({
                    isSearchingUser: false,
                    user,
                    isEditMode: true,
                });
                return;
            }
        }

        // Käyttäjä löytyi
        this.props.addNotification({
            type: 'success',
            message: _trans('notifications.user.ssn_query.success'),
        });

        this.props.onUserUpdate(user);

        this.setState({
            isSearchingUser: false,
        });
    };

    onUserNotFound = (ssn, blockVtjUpdate) => {
        const {
            isSubstituteEmployer,
            jobTitles,
        } = this.props;

        if (utils.getAgeInYearsBySsn(ssn) < 18 && isSubstituteEmployer) {
            this.props.addNotification({
                type: 'error',
                message: _trans('benefit_decision.notifications.error.underage_error'),
            });

            return;
        }

        // Jos lisätään uutta käyttäjää, tutkitaan jos on vain yksi jobTitle, niin lisäillään se valmiiks tässä
        // Kunnnalla käytännössä
        const jobTitle = jobTitles.length === 1 ? jobTitles[0].id : null;

        const user = {
            socialSecurityNumber: ssn,
            firstName: '',
            lastName: '',
            streetAddress: '',
            postCode: '',
            town: '',
            email: '',
            phoneNumber: '',
            jobTitle,
            exposedAttributes: this.props.isVtjEnabled ? {
                blockVtjUpdate
            } : {},

            // Oletuksena suomi
            locale: _.head(Object.keys(locales)),
        };

        // ei haeta jos työntekijä ja ha-puoli
        if (this.props.isVtjEnabled && (! this.props.isEmployee || ! this.props.isCommune) && ! blockVtjUpdate) {
            request
                .post(Routing.generate('user_information'))
                .send({ ssn })
                .end((error, response) => {
                    // Käyttäjää ei löytynyt kannasta joten mennään editointi tilaan (mahdollisesti vjt:stä ladattujen tietojen kanssa)
                    this.setState({
                        isEditMode: true,
                        user: this.updateUserFromVtjResponse(user, response),
                        isSearchingUser: false
                    });
                });
            return;
        }

        this.setState({
            // Käyttäjää ei löytynyt joten
            // mennään suoraan editointitilaan syöttämään tietoja
            isEditMode: true,

            user,
            isSearchingUser: false,
        });

        //this.props.onUserUpdate(user);
    };

    /**
     * Käyttäjän vaihto.
     * Resetoi käyttäjän tiedot.
     */
    onChangeUser = (event) => {
        event.preventDefault();


        this.setState({
            user: null,
            isSearchingUser: false,
        });
        if (!_.isUndefined(this.formDispatch)) {
            this.formDispatch(actions.reset(this.props.model));
        }
        this.props.onUserChange();
    };

    onUserUpdate = (user) => {
        const {
            isSubstituteEmployer,
        } = this.props;

        const socialSecurityNumber = _.get(user, 'socialSecurityNumber', null);

        if (!_.isNull(socialSecurityNumber) && utils.getAgeInYearsBySsn(socialSecurityNumber) < 18 && isSubstituteEmployer) {
            this.props.addNotification({
                type: 'error',
                message: _trans('benefit_decision.notifications.error.underage_error'),
            });

            return;
        }

        this.props.onUserUpdate(user);
    };

    onCheckUniqueEmail = (event, field) => {
        const {
            model,
        } = this.props;

        const email = event.target.value;
        const ssn = _.get(this.state, 'user.socialSecurityNumber');
        const userId = _.get(this.state, 'user.userId');

        if (email.trim() === '') {
            this.setEmailValidity(model, field, false);
            return;
        }
        //Laitetaan pending jos email ei ole tyhjä
        this.setState({ isEmailPending: true });

        request
            .get(Routing.generate('api_1_find_existing_email', { email, ssn, user: userId }))
            .end((error, response) => {
                if (!_.isUndefined(this.formDispatch)) {
                    error = _.get(response, 'body.status', '') !== 'ok';
                    if (error) {
                        const errorCode = response.body?.error ?? 'email_in_use';
                        this.setEmailValidity(model, field, true, errorCode);
                    } else {
                        this.setEmailValidity(model, field, false);
                    }
                }
                this.setState({ isEmailPending: false });

            });
    };

    setEmailValidity(model, field, hasError, errorCode = 'email_in_use') {
        const resolveErrorKey = (code) => {
            switch (code) {
                case 'email_in_use_not_identified':
                    return 'isUniqueEmailNotIdentified';
                case 'email_in_use':
                    return 'isUniqueEmail';
                case 'email_not_valid':
                    return 'isEmailValid';
            }
        };
        const errorKey = resolveErrorKey(errorCode);
        this.formDispatch(actions.setValidity(`${model}.email`, {
            ...field.validity,
            [errorKey]: hasError,
        }));
        this.formDispatch(actions.setErrors(`${model}.email`, {
            ...field.errors,
            [errorKey]: hasError,
        }));
    }

    renderUserChangeButton() {
        if (!this.props.isNewUser) return null;

        return (
            <Button onClick={this.onChangeUser} modifierClass="u-margin-left-tiny u-align-top" mdIcon="swap_horiz">
                {_trans('userForm.changeUser.button')}
            </Button>
        );
    }

    /**
     * Renderöi hetun. Jos kyseessä tilapäinen hetu kerrotaan että tällöin on annettava osoitetiedot.
     * @returns {*}
     */
    renderSocialSecurityNumber() {
        return (
            <Fragment>
                <span>
                    <StLabel htmlFor="socialSecurityNumber">
                        {_trans('userForm.socialSecurityNumber.label')}
                    </StLabel>
                    <StField>
                        <Control
                            type="hidden"
                            model=".socialSecurityNumber"
                        />
                        <span className="c-input-static">
                            {_.get(this.state.user, 'socialSecurityNumber', '-')}
                        </span>
                        {this.renderUserChangeButton()}
                    </StField>
                </span>
                { this.isSsnTemporary() && (
                    <Feedback
                        type="info"
                        modifierClass="u-margin-top-small"
                        message={_trans('userForm.socialSecurityNumber.employee_temporary_ssn_address_obligatory.feedback')}
                    />
                )}
            </Fragment>
        );
    }

    handleChange(values) {
        this.setState({
            accountNumberValue: values.accountNumber ?? '',
            email: values.email,
            user: {
                ...this.state.user,
                streetAddress: values.streetAddress,
                postCode: values.postCode,
                town: values.town
            }
        });
    }

    isAddressRequired() {
        const { isEmployee, isAssignmentContract } = this.props;

        if (isAssignmentContract && !isEmployee) {
            return false;
        }

        if (isEmployee) {
            return this.isSsnTemporary();
        }

        return true;
    }

    render() {
        const {
            model,
            ssnQueryLabel,
            isBeneficiary,
            isSubstituteEmployer,
            onError,
            isPrivate,
            isEmployee,
            onBenefitDecisionFound,
            isAdmin,
            isEmployer,
            benefitDecisionId,
        } = this.props;

        const {
            user,
            showSelectContracts,
            initialSsn,
            isSearchingUser,
        } = this.state;

        // Jos uuden käyttäjän tietoja ei ole vielä annettu tai hetu ei ole tyhjä näytetään hetukenttä.
        if (_.isEmpty(user) || isSearchingUser) {
            return (
                <div>
                    <StLabel>
                        {ssnQueryLabel}
                    </StLabel>
                    <SsnQuery
                        hasAutoFocus
                        initialSsn={initialSsn}
                        isBeneficiary={isBeneficiary}
                        isSubstituteEmployer={isSubstituteEmployer}
                        onUserFound={this.onUserFound}
                        onUserNotFound={this.onUserNotFound}
                        onUserNotVisibilityAccess={this.props.onUserNotVisibilityAccess}
                        onError={onError}
                        onBenefitDecisionFound={onBenefitDecisionFound}
                        isAdmin={isAdmin}
                        isEmployer={isEmployer}
                        benefitDecisionId={benefitDecisionId}
                        isVtjEnabled={this.props.isVtjEnabled}
                    />
                </div>
            );
        }

        const isReadOnly = !this.state.isEditMode;
        const isReadOnlyForImportantFields = this.disableAddressAndBankAccountInputs();

        const formClass = classNames('o-form o-form--vertical o-form--small', {
            'o-form--readonly': isReadOnly,
        });

        if (showSelectContracts) {
            return (
                <div>
                    <UpdateUserContractData onContractSelect={(contracts) => this.setState({ contractIds: contracts })} contracts={this.props.userContracts}/>
                    <ActionBar>
                        <Button onClick={() => this.setState({ showSelectContracts: false, isSaving: false, })}>Peruuta</Button>
                        <Button onClick={() => this.updateUser()} primary >Päivitä</Button>
                    </ActionBar>
                </div>
            );
        }

        const isAddressRequired = this.isAddressRequired();
        const isReadOnlyForVTJ = !!user?.exposedAttributes?.isFromVtj && !user?.exposedAttributes?.blockVtjUpdate && this.props.isVtjEnabled;
        const isReadOnlyAddressForVTJ = isReadOnlyForVTJ && !!user?.exposedAttributes?.isAddressFromVtj;
        const vtjMessage = isReadOnlyForVTJ && isReadOnlyAddressForVTJ ?
            _trans('Nimi- ja osoitetiedot haettu väestötietojärjestelmästä', {}, 'extract') : _trans('Nimitiedot haettu väestötietojärjestelmästä', {}, 'extract');

        return (
            <Fragment>
                <LocalForm
                    model={model}
                    initialState={user}
                    className={formClass}
                    onSubmit={this.onSubmit}
                    onSubmitFailed={this.onSubmitFailed}
                    getDispatch={ (dispatch) => this.formDispatch = dispatch }
                    onChange={ (values) => this.handleChange(values) }
                    hideNativeErrors
                >
                    <ActionBar hasMargin>
                        {this.renderHolidayDayReportButton()}
                        {this.renderHideFullSSNButton()}
                    </ActionBar>
                    <fieldset>
                        <Layout.Container verticalGutter gutterSize="small">
                            {!isPrivate &&
                                <Layout.Item>
                                    {this.renderSocialSecurityNumber()}
                                </Layout.Item>
                            }
                            {isReadOnlyForVTJ &&
                                <Layout.Item>
                                    <Feedback
                                        type="info"
                                        modifierClass="u-margin-top-small"
                                        message={vtjMessage}
                                    />
                                </Layout.Item>
                            }

                            {this.props.isVtjEnabled && (!isEmployee || !this.props.isCommune) &&
                                <Layout.Item>
                                    <StField isLabeless>
                                        <label>
                                            <Control.checkbox
                                                model=".exposedAttributes.blockVtjUpdate"
                                                disabled={isReadOnly}
                                                aria-labelledby={`${model}Label`}
                                            />
                                            {_trans('Älä hae henkilön tietoja väestötietojärjestelmästä', {}, 'extract')}
                                        </label>
                                    </StField>
                                </Layout.Item>
                            }

                            <Layout.Item medium="4/12">
                                <FirstName
                                    isRequired
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields || isReadOnlyForVTJ}
                                />
                            </Layout.Item>

                            <Layout.Item medium="8/12">
                                <LastName
                                    isRequired
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields || isReadOnlyForVTJ}
                                />
                            </Layout.Item>
                        </Layout.Container>
                    </fieldset>

                    <fieldset>
                        <legend>{_trans('userForm.contactInfo.legend')}</legend>
                        <Layout.Container verticalGutter gutterSize="small">
                            <Layout.Item>
                                <StreetAddress
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields || isReadOnlyAddressForVTJ}
                                    isRequired={isAddressRequired}
                                    onSelect={({ postCode, city }) => {
                                        // Jos osoite valitaan noukitaan siitä postinumero ja tmipaikka
                                        this.formDispatch(actions.change(`${model}.postCode`, postCode));
                                        this.formDispatch(actions.change(`${model}.town`, city));
                                    }}
                                />
                            </Layout.Item>

                            <Layout.Item small="1/3">
                                <PostCode
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields || isReadOnlyAddressForVTJ}
                                    isRequired={isAddressRequired}
                                />
                            </Layout.Item>

                            <Layout.Item small="2/3">
                                <Town
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields || isReadOnlyAddressForVTJ}
                                    isRequired={isAddressRequired}
                                />
                            </Layout.Item>

                            <Layout.Item medium="1/2">
                                <PhoneNumber
                                    className="u-1/1"
                                    readOnly={isReadOnly || isReadOnlyForImportantFields}
                                />
                            </Layout.Item>

                            <Layout.Item medium="1/2">
                                <Email
                                    className="u-1/1"
                                    isPrivate={isPrivate}
                                    isEmployee={isEmployee}
                                    readOnly={isReadOnly || this.state.isEmailPending}
                                    isRequired={isPrivate}
                                    debounce={500}
                                    onChange={(ev) => {
                                        if (validators.isEmail(ev.target.value)) {
                                            this.onCheckUniqueEmail(ev, 'email');
                                        }
                                    }}
                                />
                            </Layout.Item>

                            {this.renderEmployeeFields(isReadOnly)}
                            {this.renderCaredFields(isReadOnly)}
                        </Layout.Container>
                    </fieldset>

                    <fieldset>
                        <legend>{_trans('userForm.additionalInfo.legend')}</legend>
                        <Layout.Container verticalGutter gutterSize="small">
                            <Layout.Item medium="1/2">
                                <Locale
                                    fullWidth
                                    isReadOnly={isReadOnly}
                                />

                                {this.renderHetaMembership(isReadOnly)}
                            </Layout.Item>
                        </Layout.Container>
                    </fieldset>

                    {this.renderActionButtons()}
                </LocalForm>
                <DimensionPricing
                    user={user}
                    isReadOnly={isReadOnly}
                    {...this.props}
                />
            </Fragment>
        );
    }
}

UserForm.propTypes = {
    initialUser: PropTypes.object,
    ssnQueryLabel: PropTypes.string,
    isBeneficiary: PropTypes.bool,
    isEmployee: PropTypes.bool,
    isCared: PropTypes.bool,
    isCompany: PropTypes.bool,
    isSubstituteEmployer: PropTypes.bool,
    onUserUpdate: PropTypes.func.isRequired,
    onUserNotVisibilityAccess: PropTypes.func,
    onUserChange: PropTypes.func,
    onSubmitFailed: PropTypes.func,
    onError: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    /**
     * Voiko lomakkeen muokkaustilan peruuttaa. Täysin turha esim.
     * uutta asiakkuutta luotaessa.
     */
    canCancel: PropTypes.bool,
    isNewUser: PropTypes.bool,
    model: PropTypes.string,
    addNotification: PropTypes.func.isRequired,
    isCommune: PropTypes.bool,
    isEmployer: PropTypes.bool,
    isPrivate: PropTypes.bool,
    userContracts: PropTypes.object,
    onBenefitDecisionFound: PropTypes.func,
    /**
     * Mitä tehdään kun painetaan "Peruuta".
     */
    onCancel: PropTypes.func,
    isDimensionPricingEnabled: PropTypes.bool,
    jobTitles: PropTypes.array,
    relationType: PropTypes.number,
    // Perhehoitajasta kertova, menee samalla relaatiolal kuin TT
    isAssignmentContract: PropTypes.bool,
    initialSsn: PropTypes.string,
    email: PropTypes.string,
    isMonthlySalaryCustomerInvoicingEnabled: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isVtjEnabled: PropTypes.bool,
    canAddAccountNumberLater: PropTypes.bool,
    benefitDecisionId: PropTypes.number,
    isMultipleWorkExpLimitsApplied: PropTypes.bool,
};

UserForm.defaultProps = {
    initialUser: null,
    ssnQueryLabel: _trans('userForm.socialSecurityNumber.label'),
    isBeneficiary: false,
    isEmployee: false,
    isCared: false,
    isCompany: false,
    isSubstituteEmployer: false,
    onSubmitFailed: () => {},
    isEditMode: false,
    canCancel: true,
    isNewUser: false,
    model: 'user',
    isCommune: false,
    userContracts: List(),
    isPrivate: false,
    onUserChange: () => {},
    onUserNotVisibilityAccess: () => {},
    onBenefitDecisionFound: () => {},
    onCancel() {},
    isEmployer: false,
    isDimensionPricingEnabled: false,
    jobTitles: [],
    relationType: null,
    isAssignmentContract: false,
    initialSsn: '',
    email: '',
    isMonthlySalaryCustomerInvoicingEnabled: false,
    isAdmin: false,
    isVtjEnabled: false,
    canAddAccountNumberLater: true,
    benefitDecisionId: null,
    isMultipleWorkExpLimitsApplied: false,
};

