import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import MDSpinner from 'shared/components/MDSpinner';
import EmptyState from 'shared/components/EmptyState';
import { ChoiceList } from 'shared/components';

export const ReportingFormTemplateList = ({ onSelectTemplate }) => {
    const templates = useSelector(select.reportingForms.getReportingFormTemplates);
    const isLoadingTemplates = useSelector((state) => state.loading.effects.reportingForms.fetchReportingFormTemplates);

    if (isLoadingTemplates) return <MDSpinner wrapped />;

    if (Array.isArray(templates) && templates.length === 0) {
        return <EmptyState message={_trans('Sinulla ei ole yhtään lomaketta valittavana.', {}, 'reporting_forms')} />;
    }

    return (
        <ChoiceList
            hasOptionAsValue
            options={templates}
            valueKey="id"
            labelKey="name"
            id="reportingFormTemplateList"
            onChange={onSelectTemplate}
        />
    );
};

ReportingFormTemplateList.propTypes = {
    onSelectTemplate: PropTypes.func.isRequired,
};
