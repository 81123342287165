import React from 'react';
import { select } from '@rematch/select';
import { useDispatch, useSelector } from 'react-redux';
import { ForeclosureSummary } from './ForeclosureSummary';
import { ForeclosureForm } from './ForeclosureForm';
import { Button, Feedback, FreshdeskGuideLink, MDSpinner, Page, TabContainer } from 'shared/components';
import { ForeclosuresList } from 'shared/UserDetails/containers/shared/Foreclosure/ForeclosuresList';
import { AdminOpenForeclosuresList } from 'Admin/User/List/AdminOpenForeclosuresList';

export const ForeclosureContainer = hot(() => {
    const dispatch = useDispatch();
    const isEditMode = useSelector(select.foreclosure.isEditMode);
    const isCopy = useSelector(select.foreclosure.isCopy);
    const canEdit = useSelector(select.foreclosure.canEdit);
    const isLoading = useSelector((state) => state.loading?.effects.foreclosure.fetchForeclosure ?? false);
    const openForeclosure = useSelector(select.foreclosure.getOpenForeclosure);
    const openForeclosures = useSelector(select.foreclosure.getOpenForeclosures);
    const openOimaForeclosures = useSelector(select.foreclosure.getOpenOimaForeclosures);
    const closedForeclosures = useSelector(select.foreclosure.getClosedForeclosures);
    const isAdmin = useSelector(select.foreclosure.isAdmin);
    const isCommune = useSelector(select.userMetadata.isCommune);

    if (isLoading) {
        return (
            <Page
                heading={_trans(isEditMode ? 'foreclosure.heading_new' : 'foreclosure.heading')}
                actionItems={canEdit && <FreshdeskGuideLink articleId={101000400259} />}
            >
                <MDSpinner wrapped />
            </Page>
        );
    }

    if (isAdmin && openOimaForeclosures.length > 1) {
        return <AdminOpenForeclosuresList
            openForeclosures={openOimaForeclosures}
            closeForeclosure={dispatch.foreclosure.closeForeclosure}
        />;
    }

    if (isEditMode && canEdit) {
        const isNew = (!openForeclosure.foreclosureId || isCopy) ?? false;

        return (
            <Page
                heading={_trans(isNew ? 'foreclosure.heading_new' : 'foreclosure.heading_modify')}
                actionItems={canEdit && <FreshdeskGuideLink articleId={101000400259} />}
            >
                <Button flat mdIcon="arrow_back" modifierClass="u-margin-bottom" onClick={() => dispatch.foreclosure.goBack()}>
                    {_trans('button.back')}
                </Button>
                { isAdmin && (
                    <Feedback type="warning" message={_trans('Maksukielton omistajaksi tulee Oima. Älä käytä jos maksukielto on saapunu hyvinvointialueelle.')} modifierClass="u-margin-bottom" />
                )}
                { isCopy && (
                    <Feedback type="info" message={_trans('foreclosure.feedback.is_a_copy')} modifierClass="u-margin-bottom" />
                )}
                <ForeclosureForm values={openForeclosure} />
            </Page>
        );
    }

    return (
        <Page heading={_trans('foreclosure.heading')} actionItems={canEdit && <FreshdeskGuideLink articleId={101000400259} />}>
            {! canEdit
                ? <ForeclosureSummary/>
                : (<TabContainer
                    tabs={getTabs(isAdmin, openForeclosures, closedForeclosures, isCommune)}
                />)
            }
        </Page>
    );
});

const getTabs = (isAdmin, openForeclosures, closedForeclosures, isCommune) => {
    const tabs = {};

    tabs['active'] = {
        label: ! isAdmin ? _trans('foreclosure.tab.active') : _trans('Voimassa (Oiman omistama)', {}, 'extract'),
        content: (
            <ForeclosureSummary/>
        )
    };

    if (isAdmin) {
        tabs['activeForeclosure'] = {
            label: _trans('Muut voimassa olevat maksukiellot', {}, ' extract'),
            badge: (openForeclosures || []).length,
            content: (
                <ForeclosuresList
                    foreclosures={openForeclosures}
                    isAdmin={isAdmin}
                />
            )
        };
    }

    tabs['closed'] = {
        label: _trans('foreclosure.tab.closed'),
        badge: (closedForeclosures || []).length,
        content: (
            <ForeclosuresList
                foreclosures={closedForeclosures}
                isAdmin={isAdmin}
                isClosed={true}
                isCommune={isCommune}
            />
        )
    };

    return tabs;
};
