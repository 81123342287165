import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    YesNo,
} from 'shared/components';

/**
 * Renderöi kysymyksen poliittisesta vaikutusvallasta tarkempine selitetooltippeineen.
 */
export default class PoliticalInfluenceQuestion extends Component {
    static propTypes = {
        answerValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        onAnswerQuestion: PropTypes.func,
    };

    static defaultProps = {
        answerValue: null,
        onAnswerQuestion() {},
    };

    render() {
        const {
            answerValue,
            onAnswerQuestion,
        } = this.props;

        return (
            <Fragment>
                <div className="u-margin-bottom">
                    {`${_trans('kyc.questions.political-influence.description')} `}
                    <Popover maxWidth={375} position="auto">
                        <button className="u-color-link">
                            {_trans('kyc.questions.political-influence.influence_tooltip_trigger')}
                        </button>
                        <div>
                            {_transMd('kyc.questions.political-influence.influence_tooltip', true)}
                        </div>
                    </Popover>
                    {` ${_trans('kyc.questions.political-influence.description_2')} `}
                    <Popover maxWidth={375} position="auto">
                        <button className="u-color-link">
                            {_trans('kyc.questions.political-influence.family_member_tooltip_trigger')}
                        </button>
                        <div>
                            {_transMd('kyc.questions.political-influence.family_member_tooltip')}
                        </div>
                    </Popover>
                    {` ${_trans('or')} `}
                    <Popover maxWidth={375} position="auto">
                        <button className="u-color-link">
                            {_trans('kyc.questions.political-influence.associate_tooltip_trigger')}
                        </button>
                        <div>
                            {_transMd('kyc.questions.political-influence.associate_tooltip')}
                        </div>
                    </Popover>
                    ?
                </div>

                <YesNo
                    canUseAccessKeys
                    onChange={(answerValue) => onAnswerQuestion(answerValue)}
                    value={answerValue}
                    noLabel={_trans('kyc.questions.political-influence.no_label')}
                />
            </Fragment>
        );
    }
}
