import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useGet } from 'shared/hooks/useApi';
import { Feedback, Page, SummaryList, MDSpinner, Accordion, AccordionItem } from 'shared/components';

const DataList = ({ data }) => (
    <SummaryList
        list={[
            {
                term:_trans('Hyvinvointialue', {}, 'common'),
                description: data.dataOwnerFullName,
            },
            {
                term:_trans('Sukunimi', {}, 'common'),
                description: data.lastName,
            },
            {
                term:_trans('Etunimi', {}, 'common'),
                description: data.firstName,
            },
            {
                term:_trans('Osoite', {}, 'common'),
                description: data.fullAddress,
            },
            {
                term:_trans('Sähköposti', {}, 'common'),
                description: data.email,
            },
            {
                term:_trans('Puhelinnumero', {}, 'common'),
                description: data.phoneNumber,
            },
            {
                term:_trans('Tilinumero', {}, 'common'),
                description: data.accountNumber,
            },
        ]}
    />
);

DataList.propTypes = {
    data: PropTypes.object.isRequired,
};

export const UserDatas = () => {
    const [{ isLoading, data }] = useGet('/api/v2/user/self-user-datas');
    const isIdentified = useSelector(select.userMetadata.isIdentified);

    if (isLoading) {
        return <MDSpinner wrapped />;
    }

    const datas = data?.data ?? [];

    return (
        <Page
            maxWidth="large"
            heading={_trans('userForm.basicInfo.legend')}
        >
            {isIdentified && <Feedback
                type="success"
                title={_trans('user_data.user_is_identified')}
                modifierClass="u-margin-bottom-small"
            >
                {_transMd('authentication.identified.message', {
                    termsOfUseUrl: 'https://www.oima.fi/kayttoehdot',
                })}
            </Feedback>}
            <Feedback type="info">
                {_trans('Näet tästä henkilökohtaiset tietosi Oima-palvelussa. Mikäli tiedoissa on virheitä olethan yhteydessä tahoon, joka hoitaa sinun palkka- tai palkkioasioita.', {}, 'common')}
            </Feedback>
            <div className="u-margin-top-small">
                {datas.length > 1 && (
                    <Accordion preExpanded="0">
                        {datas.map((data, key) => (
                            <AccordionItem key={key} uuid={key.toString()} title={data.dataOwnerFullName}>
                                <DataList data={data} />
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}
                {datas.length === 1 && (
                    <DataList data={datas[0]} />
                )}
            </div>
        </Page>
    );
};
