import moment from 'moment';

const numberGenerator = {
    generate: function (number, year) {
        const oneDigitYear = `01${year.slice(-1)}`;
        const twoDigitYear = `01${year.slice(-2)}`;
        const janMonthYear = number.indexOf(oneDigitYear) !== -1 ? oneDigitYear : twoDigitYear;
        const janFirstIndex = number.indexOf(janMonthYear);
        const janLastIndex = number.indexOf(janMonthYear) + janMonthYear.length;
        const identicalDigits = number.slice(0, janFirstIndex);
        const numberBody = number.slice(0, -1);
        const refNumbers = {
            referenceNumber0: number,
        };

        for (let i = 1; i < 12; i++) {
            const month = moment().month(i).format('MM');
            const yearShape = number.indexOf(oneDigitYear) !== -1 ? year.slice(-1) : year.slice(-2);
            const startOfBody = identicalDigits + month + yearShape;
            const lastIdenticalDigits = numberBody.length !== startOfBody.length
                ? number.slice(janLastIndex, number.lastIndexOf(numberBody) + numberBody.length)
                : '';
            const refBody = startOfBody + lastIdenticalDigits;
            const multiplier = [7, 3, 1];
            let ki = 0;
            let total = 0;

            for (let j = refBody.length; j > 0; j--) {
                total += refBody.charAt(j - 1) * multiplier[ki++ % 3];
            }

            const check = ((10 - (total % 10)) % 10);
            refNumbers[`referenceNumber${i}`] = refBody + check;
        }
        return refNumbers;
    }
};

export default numberGenerator;
