import React, { Component } from 'react';
import PropTypes from 'prop-types';
import kycQuestions from './constants/kycQuestions';
import {
    Page,
} from 'shared/components';
import {
    Inquisitor,
} from 'shared/containers';
import { userAttributeNames } from 'shared/constants/index';

/**
 * KYC (Know Your Customer) -kysymysten kysyminen.
 * Tallentuu käyttäjän attribuutteihin.
 */
export default class KYCQuestions extends Component {
    static propTypes = {
        shadowUserId: PropTypes.number,
        saveAttribute: PropTypes.func,

        // Kun kaikkiin kysymyksiin on vastattu ja ne vahvistetaan
        onFinish: PropTypes.func,
    };

    static defaultProps = {
        shadowUserId: -1,
        saveAttribute() {},
        onFinish() {},
    };

    /**
     * Tallennetaan KYC-vastaukset yrityksen varjokäyttäjän attribuutteihin.
     * @param answers
     */
    onFinish = (answers) => {
        const { shadowUserId } = this.props;

        if (shadowUserId > 0) {
            this.props.saveAttribute(userAttributeNames.KYC, JSON.stringify(answers), shadowUserId);
        }
        this.props.onFinish();
    };

    render() {
        return (
            <Page maxWidth={600} isBodyClear>
                <Inquisitor
                    questions={kycQuestions}
                    isSimplified
                    onFinish={this.onFinish}
                />
            </Page>
        );
    }
}
