export const types = {
    JOB_CONTRACT: 1,
    ASSIGNMENT_CONTRACTS_FAMILY_CARE: 2,
    ASSIGNMENT_CONTRACTS_RELATIVE_CARE: 3,
    ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER: 4,
};

export const isAssignmentContractType = (type) => [
    types.ASSIGNMENT_CONTRACTS_FAMILY_CARE,
    types.ASSIGNMENT_CONTRACTS_RELATIVE_CARE,
    types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER,
].includes(type);

export const isJobContractType = (type) => parseInt(type, 10) === types.JOB_CONTRACT;

export const isCompensationEarnerContractType = (type) => type === types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER;
