import React, { Fragment } from 'react';
import { Layout, Page, MDSpinner } from 'shared/components';
import Uploader from 'shared/Import/containers/Uploader';
import { useUserMetadata } from 'shared/hooks';

export const Import = () => {
    const [data, isLoading] = useUserMetadata();

    if (isLoading && data === null) {
        return (
            <MDSpinner />
        );
    }

    const { endpoints, isCommuneAdmin, communeName, isCompany, allowAssignmentContracts } = data;

    return (
        <Page
            maxWidth="large"
            heading={_trans('import.title')}
            title={_trans('import.title')}
        >
            <Layout.Container verticalGutter>
                {/* Näkyvillä vain kuntapuolen admineille, koska bäkkäri vaatii uudelleenkirjoittamista */}
                {isCommuneAdmin &&
                    <Fragment>
                        <Layout.Item>
                            <Uploader
                                isAdminOnly
                                communeName={communeName}
                                uploadUrl={endpoints.employerUploadUrl}
                                uploadButtonText={_trans('import.button.employer_upload')}
                                uploadErrorMessage={_trans('import.message.employer_upload_error')}
                                uploadedSuccessfullyMessage={_trans('import.message.employers_uploaded_successfully')}
                                downloadBaseFileName="tyonantajien_massatuonti.xlsx"
                                downloadBaseButtonText={_trans('import.button.download_employer_base_excel')}
                            />
                        </Layout.Item>
                        <Layout.Item>
                            <Uploader
                                isAdminOnly
                                communeName={communeName}
                                uploadUrl={endpoints.employeeUploadUrl}
                                uploadButtonText={_trans('import.button.employee_upload')}
                                uploadErrorMessage={_trans('import.message.employee_upload_error')}
                                uploadedSuccessfullyMessage={_trans('import.message.employees_uploaded_successfully')}
                                downloadBaseFileName="tyontekijoiden_massatuonti.xlsx"
                                downloadBaseButtonText={_trans('import.button.download_employee_base_excel')}
                            />
                        </Layout.Item>
                    </Fragment>
                }
                <Layout.Item>
                    <Uploader
                        uploadUrl={endpoints.taxCardUploadUrl}
                        uploadButtonText={_trans('import.button.tax_card_upload')}
                        uploadErrorMessage={_trans('import.message.tax_card_upload_error')}
                        uploadedSuccessfullyMessage={_trans('import.message.tax_cards_uploaded_successfully')}
                        downloadBaseFileName="verokorttien_massatuonti.xlsx"
                        downloadBaseButtonText={_trans('import.button.download_tax_card_base_excel')}
                        isTaxCardUploader
                    />
                </Layout.Item>
                {isCompany && allowAssignmentContracts && (
                    <Fragment>
                        <Layout.Item>
                            <Uploader
                                uploadUrl={endpoints.employeeUploadUrl}
                                uploadButtonText={_trans('Tuo hoitajia', {}, 'extract')}
                                uploadErrorMessage={_trans('Tiedoissa oli virheitä, eikä kaikkia hoitajia voitu tuoda.', {}, 'extract')}
                                uploadedSuccessfullyMessage={_trans('%added%/%total% hoitajia tuotu onnistuneesti.', {}, 'extract')}
                                downloadBaseFileName="hoitajat_tuonti.xlsx"
                                downloadBaseButtonText={_trans('Lataa hoitajien tuontipohja', {}, 'extract')}
                            />
                        </Layout.Item>
                        <Layout.Item>
                            <Uploader
                                uploadUrl="/_uploader/caredimport/upload"
                                uploadButtonText={_trans('Tuo hoidettavia', {}, 'extract')}
                                uploadErrorMessage={_trans('Tiedoissa oli virheitä, eikä kaikkia hoidettavia voitu tuoda.', {}, 'extract')}
                                uploadedSuccessfullyMessage={_trans('%added%/%total% hoidettavaa tuotu onnistuneesti.', {}, 'extract')}
                                downloadBaseFileName="hoidettavat_tuonti.xlsx"
                                downloadBaseButtonText={_trans('Lataa hoidettavien tuontipohja', {}, 'extract')}
                            />
                        </Layout.Item>
                        <Layout.Item>
                            <Uploader
                                uploadUrl="/_uploader/taxcardprevioussalaryimport/upload"
                                uploadButtonText={_trans('Tuo verokorttien ansaintatietoja', {}, 'extract')}
                                uploadErrorMessage={_trans('Tiedoissa oli virheitä, eikä kaikkia ansaintatietoja voitu tuoda.', {}, 'extract')}
                                uploadedSuccessfullyMessage={_trans('%added%/%total% verokortin ansaintatietoja tuotu onnistuneesti.', {}, 'extract')}
                                downloadBaseFileName="verokorttien_ansiot_oph.xlsx"
                                downloadBaseButtonText={_trans('Lataa verokorttien ansio -tuontipohja', {}, 'extract')}
                            />
                        </Layout.Item>
                    </Fragment>
                )}
            </Layout.Container>
        </Page>
    );
};
