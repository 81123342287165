import React from 'react';
import PropTypes from 'prop-types';

export const PdfIcon = ({ size }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size} fill="none" className="u-margin-bottom-tiny">
        <path fill="#fff" d="M0 0h24v24H0z"/>
        <path
            fill="#2A0E09"
            d="M3 11.25V4.5A1.5 1.5 0 0 1 4.5 3h9a.75.75 0 0 1 .53.22l5.25 5.25a.748.748 0 0 1 .22.53v2.25a.75.75 0 1 1-1.5 0v-1.5h-4.5a.75.75 0 0 1-.75-.75V4.5H4.5v6.75a.75.75 0 1 1-1.5 0Zm11.25-3h2.69l-2.69-2.69v2.69Z"
        />
        <path
            fill="#EB5645"
            d="M20.03 15.53a.75.75 0 0 0-.53-1.28h-3a.75.75 0 0 0-.75.75v5.25a.75.75 0 1 0 1.5 0v-1.5h1.5a.75.75 0 1 0 0-1.5h-1.5v-1.5h2.25a.75.75 0 0 0 .53-.22Z"
        />
        <path
            fill="#EB5645" fillRule="evenodd"
            d="M7.875 16.875A2.625 2.625 0 0 1 5.25 19.5H4.5v.75a.75.75 0 1 1-1.5 0V15a.75.75 0 0 1 .75-.75h1.5a2.625 2.625 0 0 1 2.625 2.625Zm-1.5 0A1.125 1.125 0 0 0 5.25 15.75H4.5V18h.75a1.125 1.125 0 0 0 1.125-1.125ZM14.625 17.625A3.375 3.375 0 0 1 11.25 21h-1.5a.75.75 0 0 1-.75-.75V15a.75.75 0 0 1 .75-.75h1.5a3.375 3.375 0 0 1 3.375 3.375Zm-1.5 0a1.875 1.875 0 0 0-1.875-1.875h-.75v3.75h.75a1.875 1.875 0 0 0 1.875-1.875Z"
            clipRule="evenodd"
        />
    </svg>
);

PdfIcon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PdfIcon.defaultProps = {
    size: 24,
};
