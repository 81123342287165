import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Popover-komponentin käytössä. Ei tule käyttää sellaisenaan!
 */
export const PopoverContent = ({ id, children, hasContentPadding, title }) => {
    const popoverContentClass = classNames('c-popover__content', {
        'u-padding-small': hasContentPadding === true,
        'c-popover__content--with-title': title !== '',
    });

    return (
        <div>
            { title !== '' && (
                <header className="c-popover__header o-stack o-stack--center u-padding-small">
                    <h3 className="c-heading-subheading u-margin-bottom-none" id={`${id}Heading`}>{title}</h3>
                </header>
            )}
            <div className={popoverContentClass} id={`${id}Content`}>
                {children}
            </div>
        </div>
    );
};

PopoverContent.defaultProps = {
    hasContentPadding: true,
    title: '',
};

PopoverContent.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    openOn: PropTypes.oneOf(['click', 'hover']).isRequired,
    onClose: PropTypes.func.isRequired,
    hasContentPadding: PropTypes.bool,
    title: PropTypes.string,
};

