import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import questionTypes from '../constants/questionTypes';
import {
    Select,
    TextInput,
    ProceedButton,
    QuestionHeader,
    Rating,
} from '../components';
import { getUidByQuestion, getAttribute } from '../utils';
import OnboardingIntro from './OnboardingIntro';
import {
    ChoiceList,
    YesNo,
} from 'shared/components';
import { alignments } from 'shared/constants';

/**
 * Renderöi kysymyksen tyypin perusteella oiken kysymyksen (tai pelkän tekstin).
 */
export default class QuestionManager extends Component {
    state = {
        errors: [],
    };

    /**
     * Onko tällä kysymyksellä focus?
     * @returns {boolean}
     */
    hasFocus = () => {
        const {
            question,
            focusedQuestionId,
        } = this.props;

        const id = _.get(question, 'id', null);
        return id === focusedQuestionId || focusedQuestionId === null;
    };

    /**
     * Fokusoidaan kysymyksen tiettyyn elementtiin CSS selectorilla.
     * @param questionUid
     * @param selector
     */
    /*focusElementBySelector(questionUid, selector) {
        const el = document.querySelector(`#${questionUid} ${selector}`);

        if (el && this.hasFocus()) {
            setTimeout(() => el.focus(), 25);
        }
    }*/

    /**
     * Jatka seuraavaan kysymykseen.
     * @param answerValue
     */
    onAnswerQuestion = (answerValue) => {
        const {
            question,
            questionIndex,
            onAnswerQuestion,
        } = this.props;

        onAnswerQuestion(question, answerValue, questionIndex);
    };

    renderQuestion() {
        const {
            question,
            answerValue,
            isSimplified,
            onFocus,
            onBlur,
        } = this.props;

        const questionType = _.get(question, 'type', null);
        const validators = _.get(question, 'validators', {});
        const useAccessKeys = isSimplified;

        switch (questionType) {
            case questionTypes.INTRO:
                return (
                    <Fragment>
                        <ProceedButton
                            label={getAttribute(question, 'buttonLabel', 'Jatka')}
                            onProceed={() => this.onAnswerQuestion(true)}
                        />
                    </Fragment>
                );

            // Intro joka sisältää roolinvaihtonapin. Onboardingissa käytettävä.
            case questionTypes.ONBOARDING_INTRO:
                return (
                    <OnboardingIntro
                        question={question}
                        onAnswerQuestion={this.onAnswerQuestion}
                        hasFocus={this.hasFocus()}
                    />
                );

            case questionTypes.YES_NO: {
                return (
                    <YesNo
                        id={_.get(question, 'id', '')}
                        yesLabel={getAttribute(question, 'yesLabel', _trans('button.yes'))}
                        yesAccessKey={getAttribute(question, 'yesAccessKey', null)}
                        noLabel={getAttribute(question, 'noLabel', _trans('button.no'))}
                        noAccessKey={getAttribute(question, 'noAccessKey', null)}
                        alignment={getAttribute(question, 'alignment', alignments.HORIZONTAL)}
                        onChange={(answerValue) => this.onAnswerQuestion(answerValue)}
                        canUseAccessKeys={useAccessKeys}
                        value={answerValue}
                    />
                );
            }

            case questionTypes.CHOICE:
            case questionTypes.PICTURE_CHOICE: {
                // this.focusElementBySelector(questionUid, '.c-choice--one.is-selected');

                const alignment = getAttribute(question, 'alignment', alignments.VERTICAL);
                return (
                    <ChoiceList
                        id={_.get(question, 'id', '')}
                        options={_.get(question, 'options', [])}
                        onChange={this.onAnswerQuestion}
                        canUseAccessKey={useAccessKeys}
                        value={answerValue}
                        hasFocus={this.hasFocus()}
                        alignment={alignment}
                        modifierClass="c-inquisitor__choice-list"
                        itemModifierClass="c-inquisitor__choice"
                    />
                );
            }

            case questionTypes.RATING:
                // this.focusElementBySelector(questionUid, '.c-choice--rating.is-selected');
                return (
                    <Rating
                        value={answerValue}
                        type={getAttribute(question, 'type', 'default')}
                        maxRating={getAttribute(question, 'maxRating', 5)}
                        onClick={(rating) => this.onAnswerQuestion(rating)}
                        useAccessKey={useAccessKeys}
                    />
                );

            case questionTypes.STATEMENT: {
                // Jos on jo "vastattu" (eli painettu Jatka-nappia) ei näytetä ko. nappia(!)
                // if (answerValue === true) return null;

                return (
                    <ProceedButton
                        label={getAttribute(question, 'buttonLabel', _trans('onboarding.proceed_button.label'))}
                        onProceed={() => this.onAnswerQuestion(true)}
                        href={getAttribute(question, 'buttonHref', '')}
                    />
                );
            }

            case questionTypes.SHORT_INPUT:
                // this.focusElementBySelector(questionUid, '[type="text"]');
                return (
                    <TextInput
                        onEnter={this.onAnswerQuestion}
                        hasFocus={this.hasFocus()}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={answerValue}
                        validators={validators}
                    />
                );

            case questionTypes.LONG_INPUT:
                // this.focusElementBySelector(questionUid, '[type="text"]');
                return (
                    <TextInput
                        hasParagraphs
                        onEnter={this.onAnswerQuestion}
                        hasFocus={this.hasFocus()}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={answerValue}
                        validators={validators}
                    />
                );

            case questionTypes.SELECT: {
                // this.focusElementBySelector(questionUid, '.c-inquisitor__select');
                const placeholder = getAttribute(question, 'placeholder', _trans('text.select'));
                const options = _.get(this.props.question, 'options', []);

                return (
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onAnswerQuestion={this.onAnswerQuestion}
                    />
                );
            }

            case questionTypes.THANK_YOU: {
                // this.focusElementBySelector(questionUid, '.c-button--primary');
                const showButton = getAttribute(question, 'showButton', true);

                if (! showButton) return null;

                return (
                    <ProceedButton
                        label={_trans('text.send_answers')}
                        onProceed={() => this.onAnswerQuestion(true)}
                        mdIcon="check"
                    />
                );
            }

            // Itse tehty kysymyssisältö. Komponentille passataan onAnswerQuestion-propsi joka passaa
            // saadun vastauksen eteenpäin.
            case questionTypes.CUSTOM: {
                const CustomComponent = _.get(this.props.question, 'component', null);

                if (CustomComponent === null) {
                    console.error('Custom component not found!');
                    return null;
                }

                return (
                    <CustomComponent
                        answerValue={answerValue}
                        onAnswerQuestion={this.onAnswerQuestion}
                        hasFocus={this.hasFocus()}
                        {...this.props.externalData}
                    />
                );
            }

            default:
                console.error(`Unknown question type "${questionType}"`);
                return null;
        }
    }

    /**
     * Tutkitaan löytyykö tekstisisällöstä placeholdereita (esim. %first-name%) ja etsitään
     * niihin sisältö jo annetuista vastauksista.
     * @param text
     * @returns {*}
     */
    fillPlaceholders = (text) => {
        const { questions, answers } = this.props;

        return text.replace(/%(.*?)%/g, (match, token) => {
            const placeholder = _.find(answers, (answer) => answer.id === token);

            if (placeholder) {
                let value = null;

                const answerId = _.get(placeholder, 'id', null);
                const question = _.find(questions, (question) => question.id === answerId);
                if (question) {
                    const questionType = _.get(question, 'type', null);

                    // Kysymystyypin ollessa lista vaihtoehtoja haetaan placeholder listasta valinnan mukaisesti.
                    if (questionType === questionTypes.CHOICE) {
                        const questionOptions = _.get(question, 'options', []);

                        // Etsitään indeksi
                        const questionIndex = _.findIndex(questionOptions, (option) => option.value === placeholder.value);
                        // Otetaan placeholderiksi label (käyttäjälle näkyvä teksti)
                        const selectedOption = questionOptions[questionIndex];
                        if (selectedOption) {
                            value = selectedOption.label;
                        } else {
                            value = '';
                            console.error(`Value not found at index ${questionIndex} in ${answerId}`);
                        }
                    } else {
                        value = placeholder.value;
                    }
                }

                return value !== null ? value : '?';
            } else {
                return match;
            }
        });
    };

    render() {
        const {
            question,
            questionIndex,
            isSimplified,
            hasIntro,
            onClick,
        } = this.props;

        const title = _.get(question, 'title', '');
        const description = _.get(question, 'description', '');
        const questionType = _.get(question, 'type', null);
        const hasFocus = this.hasFocus() && isSimplified;

        const sectionClass = classNames('c-inquisitor', {
            'c-inquisitor--simplified u-padding-vertical-huge': isSimplified,
            'c-inquisitor--default u-padding-vertical': !isSimplified,
            'u-muted': !hasFocus && isSimplified,
        });

        const questionUid = getUidByQuestion(question);
        const isIntro = questionType === questionTypes.INTRO;

        // Vangitaan fokukset valittuun kysymyskomponenttiin (helpompi hypellä vastausvaihtoehdosta toiseen) mutta sallitaan
        // liikkuminen kysymysten välillä jos klikataan jotakin muuta kysymystä.
        return (
            <FocusTrap
                active={hasFocus}
                // paused={!hasFocus}
                focusTrapOptions={{
                    clickOutsideDeactivates: true,
                    allowOutsideClick: true,
                }}
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
                <section
                    className={sectionClass}
                    id={questionUid}
                    onClick={onClick}
                    key={questionUid}
                >
                    <div className="c-inquisitor-inner">
                        <QuestionHeader
                            isIntro={isIntro}
                            questionIndex={ hasIntro ? questionIndex : questionIndex + 1 }
                            title={this.fillPlaceholders(title)}
                            description={this.fillPlaceholders(description)}
                            isSimplified={isSimplified}
                        />
                        <div
                            className={classNames('c-inquisitor__question', {
                                'u-padding-left-none': isIntro,
                            })}
                        >
                            {this.renderQuestion()}
                        </div>
                    </div>
                </section>
            </FocusTrap>
        );
    }
}

QuestionManager.defaultProps = {
    answerValue: null,
    onClick() {},
    onFocus() {},
    onBlur() {},
    externalData: {},
    focusedQuestionId: null,
};

QuestionManager.propTypes = {
    /**
     * Nykyinen kysymys.
     */
    question: PropTypes.object.isRequired,

    /**
     * Kaikki kysymykset.
     */
    questions: PropTypes.array.isRequired,

    /**
     * Nykyisen kysymyksen indeksi.
     */
    questionIndex: PropTypes.number.isRequired,

    /**
     * Mahdollinen fokusoitu kysymyksen id.
     */
    focusedQuestionId: PropTypes.string,

    /**
     * Mitä tapahtuu kun kenttä saa fokuksen.
     */
    onFocus: PropTypes.func,

    /**
     * Mitä tapahtuu kun kenttä menettää fokuksen.
     */
    onBlur: PropTypes.func,

    /**
     * Onko koko kysymyspatteristolla introa?
     */
    hasIntro: PropTypes.bool.isRequired,

    /**
     * Kyseisen kysymyksen vastauksen arvo.
     */
    answerValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
    ]),

    /**
     * Kaikki vastaukset.
     */
    answers: PropTypes.array.isRequired,

    /**
     * Callback kun vastataan kysymykseen.
     */
    onAnswerQuestion: PropTypes.func.isRequired,

    /**
     * Näytetäänkö kysymykset yksi kerrallaan.
     */
    isSimplified: PropTypes.bool.isRequired,

    onClick: PropTypes.func,

    /**
     * Kustomoitujen kysymysten käyttämä data.
     */
    externalData: PropTypes.object,
};
