import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

/**
 * Käytä Formikin kanssa kun haluat päästä kiinni vaihtuviin
 * arvoihin lomakkeella. Laita Formikin form:n sisään.
 *
 * Esimerkki:
 * <OnFieldChange fields={['duration']} onChange={({ duration }) => {
 *     dispatch.quickPayment.selectDuration(duration);
 * }}/>
 *
 * Kuuntelee kun lomakkeen "duration" arvot vaihtuvat ja tekee onChangessa
 * sille asioita (kuten tässä dispatchaa muutokset storeen).
 */
const OnFormikFieldChange = React.memo(({ onChange, fields }) => {
    const formik = useFormikContext();
    React.useEffect(() => {
        const values = fields.reduce((acc, fieldName) => {
            acc[fieldName] = formik.getFieldMeta(fieldName).value;
            return acc;
        }, {});
        onChange(values);
    }, [...fields.map((field) => formik.getFieldMeta(field).value)]);
    return null;
});

OnFormikFieldChange.propTypes = {
    onChange: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
};

export default OnFormikFieldChange;
