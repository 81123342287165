import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const stepShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool,
    isDisabled: PropTypes.bool,
});

/**
 * Renderöi annetut vaiheet ja niiden tilan.
 * Korvaa Steps-komponentin.
 * @param steps
 * @returns {*}
 * @constructor
 */
export default class ProgressSteps extends Component {
    static propTypes = {
        /**
         * Koostuu taulukosta joista jokainen solu
         * on objekti muodossa:
         * title - vaiheen nimi
         * id - vaiheen tunniste (käytetään linkin ankkurissa)
         * isCompleted - onko vaihe suoritettu
         * isDisabled - onko vaihe disabloitu, esim. kun vaaditaan että edellinen vaihe on suoritettu.
         */
        steps: PropTypes.arrayOf(stepShape).isRequired,

        currentStep: PropTypes.number,

        /**
         * Mitä tapahtuu kun vaihetta klikataan?
         * Passataan tässä kaikki vaiheen tiedot eteenpäin.
         */
        onStepClick: PropTypes.func,

        theme: PropTypes.string,
    };

    static defaultProps = {
        currentStep: -1,
        onStepClick() {},
        theme: 'default',
    };

    render() {
        const {
            currentStep,
            steps,
            onStepClick,
            theme,
        } = this.props;

        return (
            <aside className="c-progress-steps-container">
                <nav className={classNames('c-progress-steps', {
                    'c-progress-steps--theme-dark': theme === 'dark',
                })}>
                    <ul className="c-progress-steps-list o-list-bare u-margin-bottom-none" role="menubar" aria-label="Vaiheet">
                        {_.map(steps, (step, key) => {
                            const stepNumber = key + 1;
                            const isSelected = stepNumber === currentStep;
                            const isDisabled = _.get(step, 'isDisabled', false);

                            const sideNavItemClass = classNames('c-progress-steps-list__item', {
                                'is-completed': step.isCompleted,
                                'is-disabled': isDisabled,
                                'is-selected': isSelected,
                            });

                            // Lukee Siirry kohtaan työntekijä | työtehtävä | työaika... jne.
                            const ariaLabel = `${_trans('quick_payment.move_to_step')}: ${step.title}`;

                            return (
                                <li
                                    role="presentation"
                                    key={key}
                                >
                                    <button
                                        className={sideNavItemClass}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            onStepClick({ ...step, number: stepNumber });
                                        }}
                                        aria-label={ariaLabel}
                                        disabled={isDisabled}
                                    >
                                        <span className="c-progress-steps-list__title">
                                            {step.title}
                                        </span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </aside>
        );
    }
}
