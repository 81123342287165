import { actions } from 'react-redux-form/lib/immutable';
import { fromJS, List } from 'immutable';
import moment from 'moment';
import { push } from 'connected-react-router';
import { dispatch as rmDispatch } from '../../../User/index';
import { promised as request } from 'BubbleWrapAgent';
import { actions as notificationActions } from 'shared/stores/notifications';
import { companyUserRelationTypes, relationRoles, userRoles } from 'shared/constants/index';
import { ARCHIVE, PRESENT_YEAR, PREVIOUS_YEAR } from 'shared/UserDetails/containers/shared/PayrollList';
import { routeKeys } from 'User/constants/routes';
import api from 'api';

const initialUser = {
    ssn: '',
    firstName: '',
    lastName: '',
    streetAddress: '',
    postCode: '',
    town: '',
    email: '',
    phoneNumber: '',
    accountNumber: '',
    attributes: [],
};

/**
 * Actions
 */

const setRoutes = (routes) => (dispatch) => {
    dispatch(actions.change('routes', fromJS(routes)));
};

/**
 * Haetaan käyttäjän tiedot
 *
 * @param userId
 */
const fetchUserDetails = (userId) => (dispatch, getState) => {
    if (userId < 0) {
        return null;
    }
    const routes = getRoutes(getState());
    dispatch(actions.setPending('user', true));
    request.get(Routing.generate('api_1_get_user', {
        user: userId,
    }))
        .end((error, response) => {
            if (error) {
                console.log(error);
                // Saatiin 404. Tyhjennetään käyttäjä. Muutoin kerrotaan virheviesti (joka toivon mukaan on suht järkevä).
                if (response.status === 404) {
                    dispatch(actions.change('user', fromJS({})));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: error,
                    }));
                }

            } else {
                if (response.body === null) {
                    dispatch(push(routes.getIn([routeKeys.BASE, 'route'])));
                } else {
                    dispatch(actions.change('user', fromJS(response.body)));
                }
            }
            dispatch(actions.setPending('user', false));
        });
};

const fetchUserSelf = () => async (dispatch) => {
    const json = await api.get('/api/v2/user/self');
    dispatch(actions.change('user', fromJS(json)));
    return json;
};

/**
 * Asettaa käyttäjän storeen (käytetään kun päivitetään käyttäjän tiedota UserFormilla), jotta store pysyy synkronissa UserFormin kanssa
 *
 * @param user
 */
const setUser = (user) => (dispatch) => {
    dispatch(actions.change('user', fromJS(user)));
};

/**
 * Käyttäjän sopimukset (vain admin)
 * Huomaa: BC-syistä TA- ja TT-rooleilla use-kaasina liksatodistus
 *
 * @param userId
 * @param role
 */
const fetchUserContracts = (userId, role) => (dispatch) => {
    if (userId < 0) {
        console.error('UserId not valid!', userId);
        return null;
    }
    let route;
    switch (role) {
        case userRoles.EMPLOYEE:
            route = `/api/contracts/v1/wages-certificate/${userId}?useCase=employee`;
            break;
        case userRoles.EMPLOYER:
            route = `/api/contracts/v1/wages-certificate/${userId}?useCase=employer`;
            break;
        // Käytössä userDatan päivityksellä sopimuksille
        case 'userContracts':
            route = Routing.generate('contracts_api_1_get_user_contracts', { user: userId });
            break;
        case 'admin':
            route = Routing.generate('contracts_api_1_get_user_all_contracts_with_beneficiary_details', { user: userId });
    }
    rmDispatch.ui.setLoading({ userContracts: true });
    request
        .get(route)
        .end((error, response) => {
            const contracts = _.get(response, 'body', null);
            if (error || contracts === null) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('commune_contract.data_table.notifications.fetch_error'),
                }));
            } else {
                let contracts = null;
                if (_.has(response.body, 'contracts')) {
                    contracts = response.body.contracts;
                } else {
                    contracts = response.body;
                }
                dispatch(actions.change('userContracts', fromJS(contracts)));
            }
            rmDispatch.ui.setLoading({ userContracts: false });
        });
};

/**
 * Työnantajan asiakkuudet (vain admin)
 *
 * @param userId
 */
const fetchEmployerBenefitDecisions = (userId) => (dispatch) => {
    if (userId < 0) {
        console.error('UserId not valid!', userId);
        return null;
    }
    rmDispatch.ui.setLoading({ employerBenefitDecisions: true });
    request
        .get(Routing.generate('api_1_get_employer_all_decisions', { user: userId }))
        .end((error, response) => {
            const contracts = _.get(response, 'body', null);
            if (error || contracts === null) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('benefit_decision.data_table.notifications.fetch_error'),
                }));
            } else {
                dispatch(actions.change('employerBenefitDecisions', fromJS(response.body)));
            }
            rmDispatch.ui.setLoading({ employerBenefitDecisions: false });
        });
};

/**
 * Käyttäjän palkkalaskelmat (vain tt)
 *
 * @param userId
 * @param state
 */
const fetchUserPayrolls = (userId, state) => (dispatch) => {
    if (userId < 0) {
        console.error('UserId not valid!', userId);
        return null;
    }
    dispatch(actions.change(`payrollList.isLoading.${state}`, true));
    const queryParams = getPayrollListStartAndEndDateFromState(state);
    request
        .get(Routing.generate('api_1_get_user_payrolls', { user: userId }))
        .query(queryParams)
        .end((error, response) => {
            if (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: _trans('commune_settings.notifications.fetch_error'),
                }));
            } else {
                dispatch(actions.change(`payrollList.${state}`, fromJS(_.get(response.body, 'data.payrolls', []))));
                dispatch(actions.change(`payrollList.totalMonetarySalary.${state}`, _.get(response.body, 'data.totalMonetarySalary', 0)));
            }
            dispatch(actions.change(`payrollList.isLoading.${state}`, false));
        });
};

/**
 * Apufunkkari jolla saadaan oikeat päivämäärät palkkalaskelmia varten
 *
 * @param state
 * @returns {*}
 */
const getPayrollListStartAndEndDateFromState = (state) => {
    const lastYear = moment().subtract(1, 'year');
    switch (state) {
        case PRESENT_YEAR:
            return {
                start: moment().startOf('year').format('YYYY-MM-DD'),
                end: moment().endOf('year').format('YYYY-MM-DD'),
                active: 1
            };
        case PREVIOUS_YEAR:
            return {
                start: lastYear.startOf('year').format('YYYY-MM-DD'),
                end: lastYear.endOf('year').format('YYYY-MM-DD'),
                active: 1
            };
        case ARCHIVE:
            return {};
        default:
            return {
                start: moment().startOf('year').format('YYYY-MM-DD'),
                end: moment().endOf('year').format('YYYY-MM-DD'),
            };
    }
};

const fetchEmployerAccidentInsurances = (userId, date) => (dispatch) => request
    .get(Routing.generate('api_1_get_accident_insurance_by_date', { employer: userId, date }))
    .end(((error, response) => {
        if (error) {
            dispatch(notificationActions.addNotification({
                type: 'error',
                message: _trans('insurances.notifications.error.accident_insurance_fetch_failed'),
            }));
        } else {
            dispatch(actions.change('employerAccidentInsurances', fromJS(response.body)));
        }
    }));

const putUserPassword = (userId, model) => (dispatch) => {
    rmDispatch.ui.setSaving({ passwordChange: true });
    return request
        .put(Routing.generate('api_1_put_user_password_change', { user: userId }))
        .send(model)
        .end((error, response) => {
            if (error) {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: response.body.message,
                }));
            } else {
                dispatch(notificationActions.addNotification({
                    type: 'success',
                    message: response.body.message,
                }));
            }
            rmDispatch.ui.setSaving({ passwordChange: false });
        });
};

const fetchUsers = (type) => (dispatch) => {
    dispatch(actions.change(`users.${type}.isLoading`, true));
    request
        .get(Routing.generate('api_1_get_users'))
        .query({
            offset: 0,
            limit: 20000,
            type
        })
        .end((error, response) => {
            const users = _.get(response, 'body.data', null);

            if (error || users === null) {
                if (response.body.error === 'error_no_access_to_dimensions') {
                    dispatch(actions.change(`users.${type}.error`, response.body.message));
                } else {
                    dispatch(notificationActions.addNotification({
                        type: 'error',
                        message: _trans('users.notifications.error.fetch_users')
                    }));
                }
            } else {
                dispatch(actions.change(`users.${type}.users`, fromJS(users)));
            }
            dispatch(actions.change(`users.${type}.isLoading`, false));
        });
};

const deleteUser = (userId) => (dispatch, getState) => {
    const state = getState();
    request
        .del(Routing.generate('api_1_delete_user', { user: userId }))
        .then(
            () => {
                dispatch(push(state.routes.getIn([routeKeys.BASE, 'route'])));
            },
            (err) => {
                dispatch(notificationActions.addNotification({
                    type: 'error',
                    message: err.response.body.message,
                }));
            }
        );
};

/**
 * Hakee asiakkuuden tiedot
 *
 * @param userId
 * @returns {Function}
 */
const fetchUserBenefitDecision = (userId) => (dispatch) => {
    request
        .get(Routing.generate('api_1_get_decision_by_user', { user: userId }))
        .then(
            (resp) => dispatch(actions.change('benefitDecision', fromJS(_.get(resp, 'body') ? resp.body : {}))),
            (err) => dispatch(notificationActions.addNotification({
                type: 'error',
                message: err.response.body.message,
            }))
        );
};

/**
 * Selectors
 */

const getUser = (state) => state.user;
const getUserRoles = (state) => getUser(state).get('userRoles', List());
const getUserId = (state) => getUser(state).get('userId', -1);


const isEmployee = (state) => {
    // Jos katsotaan kirjautuneen käyttäjän tietoja, tutkitaan userRolesta
    if (isCurrentUser(state) && state.user.get('userRole') === userRoles.EMPLOYEE) {
        return true;
    }
    // Muuten relaatiorooleista kuntaa/yritykseen
    return getUserRoles(state).includes(relationRoles.RELATION_EMPLOYEE);
};
const isCared = (state) => getUserRoles(state).includes(companyUserRelationTypes.RELATION_CARED);
const isEmployer = (state) => {
    // Jos katsotaan kirjautuneen käyttäjän tietoja, tutkitaan userRolesta
    if (isCurrentUser(state) && state.user.get('userRole') === userRoles.EMPLOYER) {
        return true;
    }
    // Muuten relaatiorooleista kuntaa
    return getUserRoles(state).includes(relationRoles.RELATION_EMPLOYER);
};
const isBeneficiary = (state) => getUserRoles(state).includes(relationRoles.RELATION_BENEFICIARY);

const isCurrentUser = (state) => getUser(state).get('isCurrentUser', false);

const isFetchingUser = (state) => state.forms.user.$form.pending;

const getUiProps = (state, key) => state.ui.get(key);

const hasActiveFenniaInsurance = (state) => !!state.employerAccidentInsurances.get('hasActiveFenniaInsurance', false);

const getRoutes = (state) => state.routes;

const getBenefitDecisionId = (state) => state.benefitDecision.get('id', null);

/**
 * Exports
 */
export const userActions = {
    fetchUserDetails,
    fetchUserSelf,
    setUser,
    fetchUserContracts,
    fetchEmployerBenefitDecisions,
    fetchUserPayrolls,
    putUserPassword,
    deleteUser,

    fetchEmployerAccidentInsurances,

    setRoutes,

    fetchUsers,

    fetchUserBenefitDecision,
};

export const userSelectors = {
    getUser,
    getUserRoles,
    getUserId,

    isEmployee,
    isCared,
    isEmployer,
    isBeneficiary,
    isCurrentUser,

    isFetchingUser,

    getUiProps,

    hasActiveFenniaInsurance,

    getRoutes,

    getBenefitDecisionId,
};

export default {
    user: fromJS(initialUser),
    userContracts: fromJS([]),
    employerBenefitDecisions: fromJS([]),
    employerAccidentInsurances: fromJS({}),
    payrollList: fromJS({
        [PRESENT_YEAR]: [],
        [PREVIOUS_YEAR]: [],
        [ARCHIVE]: [],
        totalMonetarySalary: {
            [PRESENT_YEAR]: '0',
            [PREVIOUS_YEAR]: '0',
            [ARCHIVE]: '0',
        },
        isLoading: {
            [PRESENT_YEAR]: false,
            [PREVIOUS_YEAR]: false,
            [ARCHIVE]: false,
        }
    }),
    users: fromJS({}),
    routes: fromJS({}),
    benefitDecision: fromJS({}),
};
