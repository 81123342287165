import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { useField } from 'formik';
import Variable from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/CollectiveAgreement/Variable';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { types as contractTypes } from 'shared/constants/contract';
import types from 'shared/constants/collectiveAgreementVariableTypes';
import { resolveValueByType } from 'shared/ReForm/constants/resolveValueByType';

const allowedVariableTypes = [
    types.BOOLEAN,
    types.CHOICE,
    types.CHOICE_CHAIN,
    types.DECIMAL,
    types.DECIMAL_EURO,
    types.INTEGER,
];

// HUBBA BUBBAA! Masteris-tessiltä valuu vähän liikaa variaabeleita, myös sellaisia jotka ei esim. omaishoidossa päde.
// Filtteröidään ne tällä listalla pois. Saishan tähän kait olla joku fiksumpi kätöstys.
const getBlackListByContractType = (type) => {
    switch (type) {
        case contractTypes.ASSIGNMENT_CONTRACTS_FAMILY_CARE:
        case contractTypes.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER:
            return [
                'phone_benefit',
            ];

        case contractTypes.ASSIGNMENT_CONTRACTS_RELATIVE_CARE:
            return [
                'phone_benefit',
                'allow_transportations' // tämähän sinällään jo toimii eli ei tuu omaishoidon tessillä mutta varma on varmaa..
            ];
            
        default:
            return [];
    }
};

/**
 * TES-muuttujat.
 * Renderöi kaikki loput muuttujat joita ei pohjasta jo löytynt.
 */
const Variables = ({ attributes, templateFieldNames }) => {
    // Haetaan sopparin tyypin perusteella mustalista variaabeleille.
    const type = useSelector(select.contract.getType);
    const blackList = getBlackListByContractType(type);

    const isLoading = useSelector((state) => state.loading.effects.collectiveAgreement.fetchCollectiveAgreements);

    const [collectiveAgreementField] = useField(fieldNames.COLLECTIVE_AGREEMENT);
    const [field,,helpers] = useField(fieldNames.COLLECTIVE_AGREEMENT_VARIABLES);

    // Uuden sopparin ollessa kyseessä tes-variablet haetaan annetun category:n perusteella.
    // Tallennetun sopparin tapauksessa hyväksytään kaikki sille tallennetut tes-variablet (category=null).
    const isNewContract = useSelector(select.contract.isNewContract);
    const category = isNewContract
        ? attributes?.category
        : null;

    const variables = useSelector((state) => select.collectiveAgreement.getCollectiveAgreementVariablesByCaId(state, collectiveAgreementField.value));
    const contractVariables = useSelector((state) =>
        select.contract.getCollectiveAgreementVariables(state, category)
    );

    // Tutkitaan löytyykö pohjasta jo yksittäisiä variableseja.
    const existingVariablesOnTemplate = templateFieldNames
        // Matkaan vain ne jotka alkavat "collectiveAgreementVariables." (huom piste lopussa)
        .filter((fieldName) => fieldName.startsWith(`${fieldNames.COLLECTIVE_AGREEMENT_VARIABLES}.`))
        // Jätetään vain itse nimi jäljelle
        .map((fieldName) => fieldName.substring(`${fieldNames.COLLECTIVE_AGREEMENT_VARIABLES}.`.length));

    // Otetaan alle TES-muuttujat ja yliajetaan sopparilta tulevilla jos olemassa oleva soppari
    // Tämä siksi että voitu säätää tessiä jolloin tullut lisää variaabeleita joiden on näyttävä.
    const mergedVariables = isNewContract
        ? variables
        : Object.assign({}, variables, contractVariables);

    //const variableNames = Object.keys(variables);
    const variableList = useMemo(() => (
        Object.entries(mergedVariables)
            // Pois variablet joita ei valitusta TES:stä löydy. Voi tulla esim. jos ränkätään eri tessien välillä.
            //.filter(([name]) => variableNames.includes(name))
            // Blacklistit pois
            .filter(([name]) => ! blackList.includes(name))
            // Vain sallitut muuttujatyypit
            .filter(([, { variable }]) => allowedVariableTypes.includes(variable.type))
            // Piilotetut pois
            .filter(([, { variable }]) => !variable.isHidden)
    ), [mergedVariables]);

    // Tärkeä! Resolvoidaan tässä oletusarvot kentille.
    useEffect(() => {
        if (variableList.length === 0 || isLoading) return;

        const existingVariableNames = Object.keys(field.value);
        const mergedFieldValue = variableList
            .reduce((acc, [name, { variable }]) => {
                const value = existingVariableNames.includes(name)
                    ? field.value[name]
                    : variable.defaultValue;

                return (
                    Object.assign(
                        {},
                        acc,
                        {
                            [name]: resolveValueByType({
                                type: variable.type,
                                value
                            })
                        }
                    )
                );
            }, {});

        helpers.setValue(mergedFieldValue);
    }, [variableList.length, isLoading]);

    // Filtteröidään renderöitävältä listalta pois ne variablet jotka löytyvät jo pohjasta erikseen annettuina.
    const filteredVariableList = useMemo(() => variableList
        .filter(([name]) => ! existingVariablesOnTemplate.includes(name)), [variableList]);

    if (isLoading || isNaN(collectiveAgreementField.value) || variableList.length === 0) return null;

    // Piilotetut TES-muuttujat
    const hiddenVariables = attributes?.hiddenVariables ?? [];

    return filteredVariableList
        .filter(([, { variable }]) => ! hiddenVariables.includes(variable.name))
        .map(([, { variable }], key) => (
            <Variable
                key={key}
                name={`${fieldNames.COLLECTIVE_AGREEMENT_VARIABLES}.${variable.name}`}
                variable={variable}
            />
        ));
};

Variables.propTypes = {
    attributes: PropTypes.object.isRequired,
    templateFieldNames: PropTypes.array.isRequired,
};

export default Variables;
