/**
 * Käyttäjän attribuutteja.
 */
export default {
    KYC: 'kyc',

    // UserAttributeType.php
    HAS_PERSONAL_ACCIDENT_INSURANCE: 'hasPersonalAccidentInsurance',

    HAS_REQUESTED_TYEL_INSURANCE_TRANSFER: 'hasRequestedTyelInsuranceTransfer',

    // PikaPalkan palaute
    FEEDBACK_QP: 'feedback.qp',
};
