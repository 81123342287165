export default {
    INTRO: 'intro',
    ONBOARDING_INTRO: 'onboarding-intro', // Sisältää roolinvaihtonapin
    YES_NO: 'yes/no',
    CHOICE: 'choice',
    PICTURE_CHOICE: 'picture-choice',
    RATING: 'rating',
    STATEMENT: 'statement',
    SHORT_INPUT: 'short-input',
    LONG_INPUT: 'long-input',
    SELECT: 'select',
    THANK_YOU: 'thank-you',
    CUSTOM: 'custom',
};
