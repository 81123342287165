import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { ChoiceList, } from 'shared/components/index';
import { alignments } from 'shared/constants';

/**
 * Yksinkertainen kyllä/ei -valinta.
 *
 * @param yesAccessKey
 * @param yesLabel
 * @param noAccessKey
 * @param noLabel
 * @param canUseAccessKeys
 * @param alignment
 * @param value
 * @param onSelect
 * @param id
 * @returns {*}
 * @constructor
 */
function YesNo({ yesAccessKey, yesLabel, noAccessKey, noLabel, canUseAccessKeys, alignment, value, onChange, id, }) {
    // Jos näppäinoikoteitä ole annettu ota ne labeleiden ensimmäisestä kirjaimesta.
    let yesAK = yesAccessKey ? yesAccessKey : yesLabel[0];
    let noAK = noAccessKey ? noAccessKey : noLabel[0];

    // Näppäinoikotiet eivät saa olla samat...
    if (yesAK === noAK) {
        yesAK = 'A';
        noAK = 'B';
        console.error('Duplicate accesskeys!');
    }

    return (
        <ChoiceList
            id={id}
            options={[
                {
                    value: true,
                    label: yesLabel,
                    accessKey: yesAK,
                },
                {
                    value: false,
                    label: noLabel,
                    accessKey: noAK,
                },
            ]}
            itemModifierClass={classNames({
                'c-inquisitor__choice': canUseAccessKeys,
                'c-choice--one': alignment === alignments.HORIZONTAL,
            })}
            alignment={alignment}
            canUseAccessKey={canUseAccessKeys}
            value={value}
            onChange={onChange}
        />
    );
}


YesNo.defaultProps = {
    yesAccessKey: null,
    yesLabel: _trans('button.yes'),
    noAccessKey: null,
    noLabel: _trans('button.no'),
    canUseAccessKeys: false,
    alignment: alignments.HORIZONTAL,
    value: null,
    id: '',
};

YesNo.propTypes = {
    yesAccessKey: PropTypes.string,
    yesLabel: PropTypes.string,
    noAccessKey: PropTypes.string,
    noLabel: PropTypes.string,
    /**
     * Käytetäänkö näppäinoikoteitä. Käytännössä tätä käytetään ÄÄRIMMÄISEN harvoissa tilanteissa.
     */
    canUseAccessKeys: PropTypes.bool,
    /**
     * Nappien sijoittelu.
     */
    alignment: PropTypes.oneOf(_.map(alignments)),
    /**
     * Valittu arvo.
     */
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

    /**
     * Mitä tehdään kun valintaa painetaan.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Komponentin yksilöivä tunniste. Käytössä pääsääntöisesti Inquisitorissa näppisnavigointiin.
     */
    id: PropTypes.string,
};

export default YesNo;
