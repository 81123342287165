import React, { Component, Fragment, } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
} from 'shared/components';

/**
 * Kuittausnappi.
 *
 */
export default class ProceedButton extends Component {
    onProceed = (event) => {
        event.stopPropagation();
        this.props.onProceed();
    };

    render() {
        const {
            label,
            hintText,
            mdIcon,
            isFocusable,
            href,
            hasShortCut,
        } = this.props;

        return (
            <Fragment>
                <Button
                    primary
                    type="submit"
                    onClick={this.onProceed}
                    mdIcon={mdIcon}
                    iconSize="small"
                    isFocusable={isFocusable}
                    href={href}
                    iconAfterText
                    modifierClass="c-inquisitor__button"
                    style={{
                        minWidth: '12em',
                    }}
                >
                    {label}
                </Button>
                { hasShortCut && (
                    <span className="u-muted u-margin-left-tiny">
                        {hintText}
                    </span>
                )}
            </Fragment>
        );
    }
}

ProceedButton.defaultProps = {
    hintText: 'tai paina Enter',
    modifierClass: '',
    mdIcon: null,
    onProceed() {},
    isFocusable: true,
    hasShortCut: true,

    href: '',
};

ProceedButton.propTypes = {
    /**
     * Napin teksti.
     */
    label: PropTypes.string.isRequired,

    /**
     * Vihjeteksti napin vieressä.
     */
    hintText: PropTypes.string,

    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Napin ikoni.
     */
    mdIcon: PropTypes.string,

    /**
     * Mitä tehdään kun edetään.
     */
    onProceed: PropTypes.func,

    /**
     * Voidaanko nappiin fokusoida tabilla?
     */
    isFocusable: PropTypes.bool,

    /**
     * Väliaikainen kova linkki. Käytössä vain siirryttäessä muille kuin React-sivuille.
     */
    href: PropTypes.string,

    /**
     * Onko vastauksella näppäinoikotie?
     */
    hasShortCut: PropTypes.bool,
};
