import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import produce from 'immer';
import QuestionManager from './containers/QuestionManager';
import questionTypes from './constants/questionTypes';
import {
    validateQuestions,
    getScrollData,
    getUidById,
    scrollToElementById,
    scrollToQuestion,
    getQuestionShortcuts,
    QUESTION_SELECTOR,
    UID_PREFIX,
} from './utils';
import {
    Button,
    BottomBar,
    ProgressBar,
} from 'shared/components';


export default class Inquisitor extends Component {
    static propTypes = {
        /**
         * Itse kysymykset.
         */
        questions: PropTypes.array.isRequired,

        /**
         * TODO: Kysytäänkö kysymykset yksi kerrallaan.
         */
        isSimplified: PropTypes.bool,

        /**
         * Kun yhteen kysymykseen vastataan passataan tämä yksi kysymys ja kaikki loputkin kysymykset tälle objektina.
         */
        onAnswer: PropTypes.func,

        /**
         * Kun kaikki kysymykset on kysytty loppuun passataan tälle vastaukset objektina.
         */
        onFinish: PropTypes.func,

        /**
         * Alustetut vastaukset. Voidaan käyttää esim. jos kesken kyselyn tarvitsee hypätä ulkoiseen palveluun.
         * Tällöin tallennetaan vastaukset johonkin ja takaisin tullessa palautetaan vastaukset ja jatketaan siitä
         * mihin jäätiin.
         */
        initialAnswers: PropTypes.array,

        /**
         * Kysymysten määrä. Tämän voi antaa manuaalisesti jos mukana on kyselijään kuulumattomia osioita.
         */
        maxQuestions: PropTypes.number,

        /**
         * Mahdollinen lisädata jota kustomoidut komponentit käyttävät.
         */
        externalData: PropTypes.object,

        /**
         * Näytetäänkö eteneminen?
         */
        hasProgressBar: PropTypes.bool,

        /**
         * Mikä teksti etenemisestä kertovan prosenttimerkin perässä on?
         */
        progressBarText: PropTypes.string,

        /**
         * Scrollataanko ensimmäiseen kysymykseen?
         */
        isScrolledOnMount: PropTypes.bool,
    };

    static defaultProps = {
        isSimplified: false,
        onAnswer() {},
        onFinish() {},
        initialAnswers: [],
        maxQuestions: 0,
        externalData: {},
        hasProgressBar: true,
        progressBarText: _trans('text.ready'),
        isScrolledOnMount: false,
    };

    /**
     * Validoidaan kysymykset päällisin puolin.
     */
    constructor(props) {
        super(props);
        const { questions, initialAnswers } = props;

        this.state = {
            answers: initialAnswers ?? [],
            hasValidQuestions: validateQuestions(questions),
            hasIntro: questions.map((question) => question.type).includes(questionTypes.INTRO),

            // Fokusoitu kysymys
            focusedQuestionId: null,

            // Niiden kysymysten id:t jotka ovat näkyvillä.
            renderedIds: [],

            // TODO: Kysymysten ryhmittelyt
            isGrouped: false,

            percentScrolled: 0,

            // Haetaan mahdolliset alustetut vastaukset.
            maxQuestions: this.props.maxQuestions ?? questions.length,
        };
    }

    componentDidMount() {
        // Lisätään scroll- ja ikkunan resize-event. Tätä käytetään selvittämään mikä kysymyksistä on näkymän keskellä.
        document.addEventListener('scroll', this.calculateFocusedQuestion, false);
        window.addEventListener('resize', this.calculateFocusedQuestion, false);

        // Kuunnellaan näppäimiä mahdollisten pikanäppäinten varalta.
        if (this.props.isSimplified) {
            document.body.addEventListener('keyup', this.onKeyUp, false);
        }

        // Odotetaan hetki jotta kysymykset on varmasti injektoitu DOM:iin...
        if (this.props.isSimplified || this.props.isScrolledOnMount) {
            setTimeout(() => this.scrollToLatestQuestion(), 250);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.calculateFocusedQuestion);
        window.removeEventListener('resize', this.calculateFocusedQuestion);
        document.body.removeEventListener('keyup', this.onKeyUp);
    }

    /**
     * Kuunnellaan näppäinpainalluksia ja haetaan fokusoidun kysymyksen näppäinoikotiet (jos on).
     * @param event
     */
    onKeyUp = (event) => {
        const key = event.key.toUpperCase();
        let shortCuts = null;

        let currentQuestion = null;
        const currentQuestionIndex = this.getQuestionIndexById(this.state.focusedQuestionId);
        if (currentQuestionIndex > -1) {
            currentQuestion = this.props.questions[currentQuestionIndex];
            shortCuts = getQuestionShortcuts(currentQuestion);
        }

        if (shortCuts !== null && currentQuestion !== null) {
            if (_.has(shortCuts, key)) {
                let answerValue = shortCuts[key];
                if (Array.isArray(shortCuts)) {
                    answerValue = parseInt(shortCuts[key], 10) - 1;
                }
                this.onAnswerQuestion(currentQuestion, answerValue, currentQuestionIndex);
            }
        }

        // Shift + Tab = edellinen kysymys (jos voidaan siirtyä)
        // Tab = seuraava kysymys (jos voidaan siirtyä)
        if (key === 'TAB') {
            event.preventDefault();
            if (event.shiftKey && this.canGoPrevious) {
                this.onPreviousQuestion();
            } else if (this.canGoNext) {
                this.onNextQuestion();
            }
        }
    };

    /**
     * Laskee mikä renderöidyistä elementeistä (kysymyksistä) on lähimpänä ruudun keskikohtaa ja
     * asettaa sen "fokusoiduksi". Käytetään BottomBar:in nuolien kanssa selvittäessä mihin kysymykseen niillä
     * mennään seuraavaksi.
     */
    calculateFocusedQuestion = () => {
        let focusedQuestionUid = null;

        // Haetaan kaikki DOM:issa olevat kysymyselementit
        const questionElements = document.getElementsByClassName(QUESTION_SELECTOR);

        const renderedIds = Object.keys(questionElements).map((idx) => {
            const element = questionElements[idx];
            const questionUid = element.id ?? '';
            return questionUid.substr(UID_PREFIX.length);
        });

        // Päivitetään tieto siitä mitkä elementit on renderöity
        this.setState({
            renderedIds,
        });

        const {
            windowHeight,
            // percentScrolled,
        } = getScrollData();

        // Päivitetään tieto siitä kuinka paljon sivua scrollattu
        // this.percentScrolled = percentScrolled;

        // Ikkunan keskikohta
        const windowCenter = windowHeight / 2;

        _.forEach(questionElements, (element) => {
            const windowMinY = windowCenter - 15;
            const windowMaxY = windowCenter + 15;

            const rect = element.getBoundingClientRect();
            const elementMinY = rect.top;
            const elementMaxY = elementMinY + rect.height;

            // Menevätkö ikkunan "keskikohta" ja elementti päällekkäin
            if (windowMaxY >= elementMinY && elementMaxY >= windowMinY) {
                focusedQuestionUid = element.id ?? null;
            }
        });

        // Poistetaan alun prefix jolloin jäljelle jää oikea kysymyksen id. Voisi ehkä tehdä fiksumminkin...
        if (focusedQuestionUid !== null) {
            this.setState({
                focusedQuestionId: focusedQuestionUid.substr(UID_PREFIX.length),
            });
        }
    };

    /**
     * Scrollataan näkymä mahdolliseen viimeisimpään kysymykseen (esim. tilanteessa jossa osa vastauksista on jo
     * annettu etukäteen).
     */
    scrollToLatestQuestion = () => {
        const questionElements = document.getElementsByClassName(QUESTION_SELECTOR);

        // Jos kysymyksiä on vain yksi scrollaa sen yläosaan
        const align = questionElements.length > 1 ? 'middle' : 'top';

        // Vastauksia on varmasti jo annettu. Scrollataan viimeisimpään DOM:ssa.
        if (questionElements.length) {
            const lastQuestion = _.last(questionElements);
            scrollToElementById(_.get(lastQuestion, 'id', ''), align);
        }
    };

    getAnswerById = (id) => this.state.answers.find((answer) => answer.id === id);
    getAnswerIndexById = (id) => this.state.answers.findIndex((answer) => answer.id === id);
    getQuestionById = (id) => this.props.questions.find((question) => question.id === id);
    getQuestionIndexById = (id) => this.props.questions.findIndex((question) => question.id === id);

    getCurrentQuestionIndex = () => (
        this.state.renderedIds
            .findIndex((renderedId) => renderedId === this.state.focusedQuestionId)
    );

    /**
     * Etsitään seuraavan kysymyksen indeksi vastauksen perusteella.
     * Jos vastaustyyppi on:
     * a) iteroitava: etsitään oikea vastaus sen sisältä.
     * b) string: muutetaan iteroitavaksi
     * c) funktio: seuraava kysymys päätellään aiemmin annettujen vastausten perusteella.
     * @param question
     * @param answerValue
     * @returns {number}
     */
    getNextIndexByAnswer = (question, answerValue) => {
        const jumpTo = _.get(question, 'jumpTo', null);
        let nextIndex = -1;

        // Vaihtoehtoja löytyi. Etsitään seuraava kysymys.
        if (jumpTo !== null) {
            let nextId = null;

            // Jos vaihtoehtoja on useita käy ne läpi
            if (typeof jumpTo === 'object') {
                // Etsitään vastauksen perusteella seuraavan kysymyksen id
                nextId = _.findKey(jumpTo, (choice) => {
                    let value = null;

                    if (typeof choice === 'object') {
                        value = choice;
                    } else if (typeof choice === 'function') {
                        value = choice(this.getAnswers());
                    } else {
                        value = [choice];
                    }
                    // const value = (typeof choice === 'object') ? choice : [choice];
                    return _.includes(value, answerValue);
                });
            } else if (typeof jumpTo === 'string') { // Käytä vaihtoehtoa id:nä
                nextId = jumpTo;
            } else if (typeof jumpTo === 'function') { // Seuraava kysymys päätellään aiemmin annetuista vastauksista tai niiden yhdistelmistä
                nextId = jumpTo(this.getAnswers());
            }

            // Jos se löytyi etsitään sen kysymyksen indeksi johon id osuu
            nextIndex = this.props.questions.findIndex((question) => question.id === nextId);
        }

        return nextIndex;
    };

    /**
     * Äärimmäisen yksinkertainen etenemisprosentin kertova metodi. Vaatinee viilaamista...
     * @returns {string}
     */
    getProgress() {
        // Ei oteta mahdollista intro- tai kiitos-blokkeja mukaan.
        const viableAnswers = this.getAnswers();
        const viableAnswersLength = Object.keys(viableAnswers).length;

        return viableAnswersLength > 0 ? (viableAnswersLength / this.state.maxQuestions) * 100 : 0;
    }

    /**
     * Scrollataan käyttäjän valitsemaan kysymykseen.
     * @param question
     */
    onSelectQuestion = (question) => scrollToQuestion(question);

    canGoPrevious = () => this.getCurrentQuestionIndex() > 0;

    canGoNext = () => this.getCurrentQuestionIndex() < this.state.renderedIds.length - 1;

    onPreviousQuestion = () => {
        const previousQuestionIndex = this.getCurrentQuestionIndex() - 1;
        scrollToElementById(getUidById(this.state.renderedIds[previousQuestionIndex]));
    };

    // TODO: Tarkistus ettei mennä yli loogisen hypyn
    onNextQuestion = () => {
        const nextQuestionIndex = this.getCurrentQuestionIndex() + 1;
        scrollToElementById(getUidById(this.state.renderedIds[nextQuestionIndex]));
    };

    /**
     * Palauttaa vastaukset yksinkertaisena key/value -parina.
     * @returns {*|{}}
     */
    getAnswers = () => {
        const { answers } = this.state;
        return (Array.isArray(answers) ? answers : [answers]).reduce((r, { id, value }) => (r[id] = value, r), {});
    };

    /**
     * Renderöi kysymykset sen mukaan mitä on vastattu. Ladies and gentlemen may I present you: a while loop.
     * @returns {*}
     */
    renderQuestions() {
        const el = [];
        let index = 0;
        let questionIndex = 0;
        let hasQuestions = true;
        const {
            questions,
            externalData,
        } = this.props;

        while (hasQuestions && index < questions.length) {
            const question = typeof questions[questionIndex] !== 'undefined' ? questions[questionIndex] : null;

            // Kysymystä ei löydy
            if (question === null) {
                console.error(`Question not found at index ${questionIndex}`);
                break;
            }

            // Kysymksellä ei ole id:tä
            const questionId = _.get(question, 'id', null);
            if (questionId === null) {
                console.error(`Question ID not found at index ${questionIndex}`);
                break;
            }

            const answer = this.getAnswerById(questionId);
            const answerValue = answer?.value ?? null;

            // TODO: Propsien injektointi
            el.push(
                <QuestionManager
                    key={questionId}
                    hasIntro={this.state.hasIntro}
                    question={question}
                    questions={questions}
                    questionId={questionId}
                    questionIndex={index}
                    focusedQuestionId={this.state.focusedQuestionId}
                    onAnswerQuestion={this.onAnswerQuestion}
                    answerValue={answerValue}
                    answers={this.state.answers}
                    isSimplified={this.props.isSimplified}
                    onClick={() => this.onSelectQuestion(question)}
                    externalData={externalData}
                />
            );

            if (answerValue === null) {
                break;
            }

            // Jos kysymykseen on vastattu tutkitaan mihin kohtaan kysymyspatteristoa mennään seuraavaksi
            const nextIndex = this.getNextIndexByAnswer(question, answerValue);

            // Muutoin rullaillaan kysymyslistaa läpi yksi kerrallaan
            if (nextIndex === -1) {
                questionIndex++;

                if (questionIndex > questions.length - 1) {
                    hasQuestions = false;
                }
            } else {
                questionIndex = nextIndex;
            }

            index++;
        }

        return el;
    }

    /**
     * Lisätään / päivitetään oikeaan kohtaan vastausobjektia ja selvitetään mikä kysymys kysytään seuraavaksi.
     * @param question
     * @param answerValue
     */
    onAnswerQuestion = (question, answerValue = null) => {
        const {
            onAnswer,
            onFinish,
        } = this.props;

        let answers = [];
        const questionId = question.id ?? null;
        const questionTitle = question.title ?? '';

        // Ollaanko jo viimeisessä kysymyksessä
        const lastQuestionId = _.last(this.props.questions).id ?? -1;
        const isLastQuestion = (questionId === lastQuestionId);

        // Tallennetaan vastaus
        if (questionId !== null) {
            // Etsitään löytyykö kysymys jo vastauksista. Jos löytyy palautetaan sen indeksi.
            const answerIndex = this.getAnswerIndexById(questionId);
            const answerObj = {
                id: questionId,
                value: answerValue,
            };

            // Kysymykseen on jo vastattu. Päivitetään vastaus. Muutoin lisätään vastauslistaan.
            if (answerIndex > -1) {
                answers = produce(this.state.answers, (draftState) => {
                    draftState[answerIndex] = answerObj;
                });
                // this.answers[answerIndex] = answerObj;
            } else {
                answers = produce(this.state.answers, (draftState) => {
                    draftState.push(answerObj);
                });
            }

            // Mihin kysymykseen scrollataan seuraavaksi?
            let nextIndex = this.getNextIndexByAnswer(question, answerValue);
            if (nextIndex === -1) {

                nextIndex = this.getQuestionIndexById(questionId) + 1;
            }
            const nextQuestion = this.props.questions[nextIndex];

            // Timeoutilla, koska elementtiä ei välttämättä ole ehditty mountata.
            const scrollTimeout = 125 + 500;
            setTimeout(() => scrollToQuestion(nextQuestion), scrollTimeout);

            this.setState({ answers });

            // Kun ollaan vastattu viimeiseen kysymykseen passataan vastaukset eteenpäin.
            if (isLastQuestion) {

                // Poistetaan turhat kuten intro ja kiitossivun tiedot
                onFinish(_.omit(answers, [
                    questionTypes.INTRO,
                    questionTypes.ONBOARDING_INTRO,
                    questionTypes.THANK_YOU
                ]));
            }

            onAnswer(answerObj, answers, questionTitle, questionId, answerIndex);
        }
    };

    /**
     * Näytä kyselyn eteneminen.
     * @returns {*}
     */
    renderProgressBar() {
        const progress = this.getProgress();
        const progressText = `${parseFloat(progress).toFixed(0)}% ${this.props.progressBarText}`;

        return (
            <BottomBar
                isInset
            >
                <div className="u-align-item-center" style={{ maxWidth: '560px' }}>
                    <div className="o-pack o-pack--middle o-pack--auto">
                        <div className="o-pack__item">
                            {progressText}
                            <ProgressBar
                                value={progress}
                                max={100}
                                isValueVisible={false}
                            />
                        </div>
                        <div className="o-pack__item u-text-right" style={{ width: '142px' }}>
                            <Button
                                mdIcon="arrow_upward"
                                size="medium"
                                primary
                                hasPadding={false}
                                onClick={this.onPreviousQuestion}
                                disabled={! this.canGoPrevious()}
                                ariaLabel={_trans('onboarding.previous_question')}
                            />
                            <Button
                                mdIcon="arrow_downward"
                                size="medium"
                                primary
                                hasPadding={false}
                                modifierClass="u-margin-left-tiny"
                                onClick={this.onNextQuestion}
                                disabled={! this.canGoNext()}
                                ariaLabel={_trans('onboarding.next_question')}
                            />
                        </div>
                    </div>
                </div>
            </BottomBar>
        );
    }

    render() {
        if (! this.state.hasValidQuestions) return null;

        return (
            <div>
                <div className={classNames('c-inquisitor-container', {
                    'c-inquisitor-container--simplified': this.props.isSimplified,
                })}>
                    {this.renderQuestions()}
                </div>
                { (this.props.isSimplified && this.props.hasProgressBar) && this.renderProgressBar() }
            </div>
        );
    }
}
