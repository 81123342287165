import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Choice } from 'shared/components';
/*import Rating1 from 'images/rate-1.svg';
import Rating2 from 'images/rate-2.svg';
import Rating3 from 'images/rate-3.svg';
import Rating4 from 'images/rate-4.svg';
import Rating5 from 'images/rate-5.svg';*/
import Icons from './Icons';

/*const iconSize = 36;
const svgAttributes = {
    width: iconSize,
    height: iconSize,
    viewBox: '0 0 24 24',
};

const RatingSmileys = [
    <Rating1 {...svgAttributes} key={0} />,
    <Rating2 {...svgAttributes} key={1} />,
    <Rating3 {...svgAttributes} key={2} />,
    <Rating4 {...svgAttributes} key={3} />,
    <Rating5 {...svgAttributes} key={4} />,
];*/

/**
 * Arvostelukomponentti. Voidaan antaa raja mihin saakka arvionumerot menevät.
 *
 */
function Rating({
    maxRating,
    onClick,
    value,
    type,
}) {
    const ratings = [];

    const usePicture = type === 'smiley';
    if (usePicture) {
        maxRating = 5;
    }

    for (let i = 0; i < maxRating; i++) {
        const rate = (i + 1);

        ratings.push(
            <Choice
                label={rate}
                choiceAccessKey={rate}
                showAccessKey={false}
                onClick={() => onClick(parseInt(rate, 10))}
                key={`rating${i}`}
                isSelected={rate === value}
                modifierClass="c-choice--rating"
                picture={ usePicture ? Icons[i] : null }
            />
        );
    }

    return (
        <div className={classNames('c-choice-rating', {
            'c-choice-rating--picture': usePicture,
        })}>
            {ratings}
        </div>
    );
}

Rating.defaultProps = {
    type: 'default',
    value: null,
};

Rating.propTypes = {
    /**
     * Arvostelukomponentin tyyppi.
     */
    type: PropTypes.string,

    /**
     * Maksimiarvo jonka voi antaa.
     */
    maxRating: PropTypes.number.isRequired,

    /**
     * Mitä tehdään kun valintaa painetaan.
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Valittu arvo.
     */
    value: PropTypes.number,
};

export default Rating;
