import React from 'react';
import PropTypes from 'prop-types';

/**
 * Pelkkä tekstuaalinen presentaatio. Tässä voidaan kertoa esim. lisätietoa pohjautuen
 * aiempiin valintoihin.
 */
function Statement({
    description,
}) {
    return (
        <section>
            {description}
        </section>
    );
}

Statement.defaultProps = {
    buttonLabel: 'Enter',
};

Statement.propTypes = {
    /**
     * Tekstisisältö, markdownia.
     */
    description: PropTypes.string.isRequired,
};

export default Statement;
