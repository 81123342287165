import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import DimensionTypeCell from '../components/DimensionTypeCell';
import DataTable from 'shared/containers/DataTable';
import { Button, ButtonGroup, ConfirmButton, LinkIcon, MDSpinner, Page, Tooltip } from 'shared/components';
import { mainBreakPoints } from 'shared/constants/index';
import resolveUrl from 'shared/utils/resolveUrl';
import { dimensionType } from 'shared/shapes/dimensions';

const breakpointMatches = matchMedia(`(min-width: ${mainBreakPoints.MEDIUM}px)`).matches;

/**
 * Booleanista Kyllä / Ei -teksti.
 */
const boolToText = (bool) => {
    const text = (bool === true) ? _trans('yes') : _trans('no');
    return _.capitalize(text);
};

@connect((state) => ({
    dimensionTypes: select.dimensions.getDimensionTypes(state),
    isLoadingDimensions: _.get(state, 'loading.effects.dimensions.fetchDimensionTypes', false),
    isDeleting: _.get(state, 'loading.effects.dimensions.deleteDimensionType', false),
    isCommune: select.userMetadata.isCommune(state),
}))
export default class DimensionsTypeList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        dimensionTypes: PropTypes.arrayOf(dimensionType),
        isLoadingDimensions: PropTypes.bool,
        isDeleting: PropTypes.bool,
        routes: PropTypes.object.isRequired,
        isCommune: PropTypes.bool,
    };

    static defaultProps = {
        dispatch() {},
        dimensionTypes: [],
        isLoadingDimensions: false,
        isDeleting: false,
        isCommune: false
    };
    constructor(props) {
        super(props);
        props.dispatch.dimensions.fetchDimensionTypes({ restrictDimensionVisibility: 0 });
    }

    onEdit = (typeId) => this.props.dispatch(push(resolveUrl(this.props.routes.DIMENSION_TYPE_FORM, { typeId })));

    onDelete = (id) => this.props.dispatch.dimensions.deleteDimensionType(id);

    render() {
        const { dimensionTypes, isLoadingDimensions, isDeleting, routes, isCommune } = this.props;
        if (isLoadingDimensions) {
            return <MDSpinner wrapped/>;
        }
        return (
            <Page
                title={_trans('company_manager.dimensions.types.title')}
                heading={_trans('company_manager.dimensions.types.heading')}
                isBodyClear
                actionItems={(
                    <LinkIcon mdIcon="add" to={resolveUrl(routes.DIMENSION_TYPE_FORM, { typeId: 'new' })}>
                        {_trans('company_manager.dimensions.types.heading_create')}
                    </LinkIcon>
                )}
                maxWidth={1400}
            >
                <DataTable
                    data={dimensionTypes.reverse()}
                    emptyDataMessage={_trans('company_manager.dimensions.list.no_dimension_types')}
                    actionsColumnWidth={260}
                    actionsColumn={(breakpointMatches ?
                        <ActionColumn
                            onDelete={this.onDelete}
                            onEdit={this.onEdit}
                            isDeleting={isDeleting}
                        />
                        : null
                    )}
                    columns={[
                        {
                            Header: _trans('company_manager.dimensions.list.cells.name'),
                            accessor: 'name',
                            Cell: (props) =>
                                <DimensionTypeCell
                                    isDeleting={isDeleting}
                                    onDelete={this.onDelete}
                                    onEdit={this.onEdit}
                                    route={routes.DIMENSIONS}
                                    { ...props }
                                />
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_contract'),
                            id: 'isContract',
                            accessor: (props) => boolToText(_.get(props, 'isContract', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_time_registration'),
                            id: 'isTimeRegistration',
                            accessor: (props) => boolToText(_.get(props, 'isTimeRegistration', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 120,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_search_dimension'),
                            id: 'isSearchDimension',
                            accessor: (props) => boolToText(_.get(props, 'isSearchDimension', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 140,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_payment_focusing_dimension'),
                            id: 'isPaymentFocusingDimension',
                            accessor: (props) => boolToText(_.get(props, 'isPaymentFocusingDimension', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 140,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_service_charge_invoicing_dimension'),
                            id: 'isServiceChargeInvoicingDimension',
                            accessor: (props) => boolToText(_.get(props, 'isServiceChargeInvoicingDimension', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 140,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_restricted'),
                            id: 'isRestricted',
                            show: ! isCommune && breakpointMatches,
                            accessor: (props) => boolToText(_.get(props, 'isRestricted', false)),
                            width: 140,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_salary_type'),
                            id: 'isSalaryType',
                            show: ! isCommune && breakpointMatches,
                            accessor: (props) => boolToText(_.get(props, 'isSalaryTypeDimension', false)),
                            width: 140,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_benefit_decision_dimension.text'),
                            id: 'isBenefitDecisionDimension',
                            show: isCommune,
                            accessor: (props) => boolToText(_.get(props, 'isBenefitDecisionDimension', false)),
                            width: 200,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_visibility_dimension.text'),
                            id: 'isVisibilityDimension',
                            show: isCommune,
                            accessor: (props) => boolToText(_.get(props, 'isVisibilityDimension', false)),
                            width: 200,
                        },
                        {
                            Header: _trans('company_manager.dimensions.types.form.is_contract_contact_dimension.text'),
                            id: 'isContractContactDimension',
                            show: isCommune,
                            accessor: (props) => boolToText(_.get(props, 'isContractContactDimension', false)),
                            width: 200,
                        },
                        {
                            Header: _trans('company_manager.dimensions.list.cells.is_search_dimension.text'),
                            id: 'isSearchDimension',
                            accessor: (props) => boolToText(_.get(props, 'isSearchDimension', false)),
                            show: isCommune,
                            width: 200,
                        },
                        {
                            Header: _trans('Kirjanpidon oletusdimensio', {}, 'company_manager'),
                            id: 'isBookkeepDefaultDimension',
                            accessor: (props) => boolToText(_.get(props, 'isBookkeepDefaultDimension', false)),
                            show: ! isCommune && breakpointMatches,
                            width: 200,
                        },
                    ]}

                />
            </Page>
        );
    }
}


const ActionColumn = (props) => (
    <ButtonGroup>
        <Button
            onClick={() => props.onEdit(_.get(props, 'original.id'))}
            mdIcon="edit"
        >
            {_trans('button.edit')}
        </Button>
        <ConfirmButton
            onClick={() => props.onDelete(_.get(props, 'original.id'))}
            confirmText={_trans('button.are_you_sure')}
            text={_trans('button.remove')}
            disabled={_.get(props, 'original.dimensions', []).length > 0}
            isPending={_.get(props, 'isDeleting', false)}
        />
        {_.get(props, 'original.dimensions', []).length > 0 && (
            <Tooltip title={_trans('company_manager.dimensions.notifications.error.has_values')}/>
        )}
    </ButtonGroup>
);

ActionColumn.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
