// Legacy magic number: tarkoittanut aiemmin ulosoton "toistaiseksi" voimassaoloa
export const MAX_FORECLOSURE_AMOUNT = 99999999999999;
export const ONE_THIRD_EMPLOYEE_REFUGE_INCOME = 0;
export const INDEFINITE = 1;

export const TYPE_MIN = 2;

export const TYPE_MAX = 3;

export const ONCE = 4;

export const EXPIRES_UNTIL_CHARGED= 6;

export const EXPIRES_UNTIL_DATE = 7;

export const getForeclosureTypeOptions = () => [
    {
        value: INDEFINITE,
        label: _trans('text.indefinite'),
    },
    {
        value: EXPIRES_UNTIL_CHARGED,
        label: _trans('Päättyy kunnes veloitettu', {}, 'exctract'),
    },
    {
        value: EXPIRES_UNTIL_DATE,
        label: _trans('Ulosoton pidätys päättyy valittuna päivämääränä', {}, 'extract'),
    },
    {
        value: ONCE,
        label: _trans('Kertapidätys', {}, 'extract'),
    },
];
