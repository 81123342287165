import React from 'react';
/**
 * Ikonit JSX:nä kunnes gulp poistunut...
 * gulp kun ei ymmärrä svg importteja ja turha alkaa siihen tukea rakentamaan.
 **/

const Icons = [
    (
        <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={0}>
            <circle cx="12" cy="12" r="12" fill="white"/>
            <path d="M12 24C9.408 24 6.94 23.183 4.86 21.638C4.812 21.609 4.769 21.576 4.73 21.539C1.724 19.243 0 15.768 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 15.768 22.276 19.243 19.27 21.54C19.232 21.576 19.189 21.609 19.142 21.638C17.061 23.183 14.592 24 12 24ZM6.014 20.619C7.78 21.85 9.845 22.5 12 22.5C14.155 22.5 16.22 21.85 17.986 20.619C17.787 17.503 15.143 15 12 15C8.857 15 6.213 17.503 6.014 20.619ZM12 13.5C15.602 13.5 18.644 16.029 19.347 19.49C21.359 17.517 22.5 14.824 22.5 12C22.5 6.21 17.79 1.5 12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 14.824 2.641 17.517 4.653 19.49C5.356 16.029 8.398 13.5 12 13.5Z" fill="#F05050"/>
            <path d="M16.63 11.252C16.29 11.252 15.953 11.194 15.629 11.079C14.77 10.775 14.103 10.109 13.8 9.25C13.733 9.061 13.744 8.858 13.831 8.677C13.917 8.496 14.069 8.36 14.258 8.293C14.339 8.265 14.422 8.25 14.507 8.25C14.825 8.25 15.109 8.451 15.215 8.751C15.367 9.18 15.7 9.513 16.129 9.665C16.292 9.722 16.46 9.752 16.63 9.752C16.853 9.752 17.071 9.702 17.276 9.604C17.637 9.431 17.91 9.128 18.043 8.75C18.148 8.451 18.432 8.25 18.75 8.25C18.835 8.25 18.919 8.264 18.999 8.292C19.188 8.359 19.34 8.495 19.426 8.676C19.512 8.857 19.523 9.061 19.456 9.249C19.189 10.004 18.644 10.611 17.921 10.956C17.513 11.153 17.079 11.252 16.63 11.252Z" fill="#F05050"/>
            <path d="M7.36901 11.251C7.03001 11.251 6.69401 11.193 6.37201 11.08C5.51301 10.777 4.84601 10.11 4.54301 9.251C4.47601 9.062 4.48701 8.859 4.57301 8.678C4.66001 8.496 4.81101 8.36 5.00001 8.293C5.08001 8.264 5.16501 8.25 5.25001 8.25C5.56701 8.25 5.85101 8.451 5.95701 8.751C6.10901 9.18 6.44201 9.513 6.87201 9.665C7.03301 9.722 7.20101 9.751 7.37001 9.751C8.00601 9.751 8.57501 9.349 8.78601 8.751C8.89201 8.452 9.17601 8.251 9.49401 8.251C9.57901 8.251 9.66301 8.265 9.74301 8.294C10.133 8.432 10.338 8.861 10.201 9.251C9.77701 10.447 8.64001 11.251 7.36901 11.251Z" fill="#F05050"/>
        </svg>
    ),
    (
        <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={1}>
            <path d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z" fill="white" stroke="#FF8A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.048 18C17.4894 16.8727 16.627 15.9239 15.5581 15.2606C14.4891 14.5973 13.2561 14.2458 11.998 14.2458C10.7399 14.2458 9.50691 14.5973 8.43793 15.2606C7.36896 15.9239 6.50656 16.8727 5.948 18" stroke="#FF8A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" fill="#FF8A00"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" stroke="#FF8A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" fill="#FF8A00"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" stroke="#FF8A00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    (
        <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={2}>
            <path d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z" fill="white" stroke="#F4BA4A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" fill="#F4BA4A"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" stroke="#F4BA4A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" fill="#F4BA4A"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" stroke="#F4BA4A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 15.75H16.5" stroke="#F4BA4A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    (
        <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={3}>
            <path d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z" fill="white" stroke="#8BA84F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" fill="#8BA84F"/>
            <path d="M8.25 8.625C8.32417 8.625 8.39667 8.64699 8.45834 8.6882C8.52001 8.7294 8.56807 8.78797 8.59645 8.85649C8.62484 8.92502 8.63226 9.00042 8.61779 9.07316C8.60332 9.1459 8.56761 9.21272 8.51517 9.26517C8.46272 9.31761 8.3959 9.35332 8.32316 9.36779C8.25042 9.38226 8.17502 9.37484 8.10649 9.34645C8.03797 9.31807 7.9794 9.27001 7.9382 9.20834C7.89699 9.14667 7.875 9.07417 7.875 9C7.875 8.90054 7.91451 8.80516 7.98484 8.73483C8.05516 8.66451 8.15054 8.625 8.25 8.625" stroke="#8BA84F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" fill="#8BA84F"/>
            <path d="M15.75 8.625C15.8242 8.625 15.8967 8.64699 15.9583 8.6882C16.02 8.7294 16.0681 8.78797 16.0965 8.85649C16.1248 8.92502 16.1323 9.00042 16.1178 9.07316C16.1033 9.1459 16.0676 9.21272 16.0152 9.26517C15.9627 9.31761 15.8959 9.35332 15.8232 9.36779C15.7504 9.38226 15.675 9.37484 15.6065 9.34645C15.538 9.31807 15.4794 9.27001 15.4382 9.20834C15.397 9.14667 15.375 9.07417 15.375 9C15.375 8.90054 15.4145 8.80516 15.4848 8.73483C15.5552 8.66451 15.6505 8.625 15.75 8.625" stroke="#8BA84F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.048 15C17.4894 16.1273 16.627 17.076 15.5581 17.7394C14.4891 18.4027 13.2561 18.7542 11.998 18.7542C10.7399 18.7542 9.50691 18.4027 8.43793 17.7394C7.36896 17.076 6.50656 16.1273 5.948 15" fill="white"/>
            <path d="M18.048 15C17.4894 16.1273 16.627 17.076 15.5581 17.7394C14.4891 18.4027 13.2561 18.7542 11.998 18.7542C10.7399 18.7542 9.50691 18.4027 8.43793 17.7394C7.36896 17.076 6.50656 16.1273 5.948 15" stroke="#8BA84F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    (
        <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={4}>
            <path d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z" fill="white" stroke="#219653" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.75 13.5C18.75 15.2902 18.0388 17.0071 16.773 18.273C15.5071 19.5388 13.7902 20.25 12 20.25C10.2098 20.25 8.4929 19.5388 7.22703 18.273C5.96116 17.0071 5.25 15.2902 5.25 13.5" stroke="#219653" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.493 9.74999C18.338 9.31119 18.0507 8.93125 17.6708 8.66252C17.2908 8.39379 16.8369 8.24948 16.3715 8.24948C15.9061 8.24948 15.4522 8.39379 15.0722 8.66252C14.6923 8.93125 14.405 9.31119 14.25 9.74999" stroke="#219653" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.75 9.74999C9.59498 9.31119 9.30771 8.93125 8.92776 8.66252C8.54781 8.39379 8.09388 8.24948 7.6285 8.24948C7.16312 8.24948 6.70919 8.39379 6.32925 8.66252C5.9493 8.93125 5.66203 9.31119 5.507 9.74999" stroke="#219653" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
];

export default Icons;
