import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuestionDescription from './QuestionDescription';

/**
 * Kysymyksen otsikko ja mahdollinen selite.
 *
 */
function QuestionHeader(props) {
    const { questionIndex, isIntro, isSimplified } = props;
    const title = props.title.trim();

    const questionNumber = (
        <span className="u-display-inline-block c-inquisitor__number o-pin o-pin--left o-pin--top u-z-index-1">
            {questionIndex}.
        </span>
    );

    const titleClass = classNames('c-inquisitor__title o-stack o-stack--start u-position-relative', {
        'c-inquisitor__title--intro u-padding-left-none': isIntro,
    });

    return (
        <header>
            <h2 className={titleClass}>
                { (!isIntro && isSimplified) && questionNumber }
                {_transMd(title, {}, { inline: true })}
            </h2>
            <QuestionDescription
                isIntro={isIntro}
                description={props.description}
            />
        </header>
    );
}

QuestionHeader.propTypes = {
    /**
     * Kysymyksen indeksi.
     */
    questionIndex: PropTypes.number.isRequired,

    /**
     * Onko kyseessä intro?
     */
    isIntro: PropTypes.bool.isRequired,

    /**
     * Esittelyn otsikko.
     */
    title: PropTypes.string.isRequired,

    /**
     * Esittelyteksti.
     */
    description: PropTypes.string.isRequired,

    /**
     *
     */
    isSimplified: PropTypes.bool.isRequired,
};

export default QuestionHeader;
