import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import _ from 'lodash';
import { StLabel, StField, StErrors, StHelp } from 'shared/components/StForm';
import { validators } from 'shared/validators';
import VisibilityContainer from 'shared/components/VisibilityContainer';
import { errorMessages } from 'shared/errorMessages';
import { MDSpinner } from 'shared/components/index';
import { useUserMetadata } from 'shared/hooks';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { relationTypes } from 'shared/constants/communeCustomerRelationTypes';

function AccountNumber(props) {
    const {
        model,
        isEmployer,
        isCommune,
        isReadOnly,
        relationType,
    } = props;

    const filteredProps = _.omit(props, ['isEmployer', 'isCommune', 'isReadOnly']);

    const [isPfsAccount, setIsPfsAccount] = useState(false);
    // tätä palasta käytetään niin monessa eri paikassa että haetaan metadata tässä niin ei tarvi yrittää sitä
    // valuttaa kaikista eri storeista tänne asti
    const [data, isLoading] = useUserMetadata();

    const isPfsAccountNumber = (value) => {
        let isPfsNumber = false;
        if (value) {
            isPfsNumber = (value.toString().toUpperCase().includes('IE')
                    && value.toString().toUpperCase().includes('PFSR'))
                || value.toString().toUpperCase().includes('DK');
            setIsPfsAccount(isPfsNumber);
            return isPfsNumber;
        }
        return isPfsNumber;
    };

    /**
     * Lisäillään välilyöntejä, jotta tilinumero olisi helpompi lukuinen
     *
     * @param number
     * @returns {*}
     */
    const beautifyAccountNumber = (number) => number
        .toUpperCase()
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();

    const accountNumber = (data?.hasOwnAccount && data?.accountNumber)
        ? beautifyAccountNumber(data?.accountNumber)
        : null;

    const isRequired = isEmployer || (relationType && [companyUserRelationTypes.RELATION_EMPLOYEE, relationTypes.RELATION_EMPLOYEE].includes(relationType));
    return (
        <div>
            <StLabel id={`${model}Label`} htmlFor="accountNumber" tooltip={isEmployer ? _trans('userForm.accountNumber.tooltip') : null} isRequired={isRequired}>
                {_trans('userForm.accountNumber.label')}
            </StLabel>
            <StField>
                <Control.text
                    id="accountNumber"
                    {...filteredProps}
                    aria-describedby="accountNumberHelp"
                    model={model}
                    onChange={(event) => isPfsAccountNumber(event.target.value)}
                    className="u-1/1"
                    // Lisäillään välilyöntejä, jotta tilinumero olisi helpompi lukea.
                    mapProps={{
                        // eslint-disable-next-line react/prop-types
                        value: (props) => beautifyAccountNumber(props.modelValue || ''),
                        // eslint-disable-next-line react/prop-types
                        once: (props) => isPfsAccountNumber(props.modelValue)
                    }}
                    // Kun tallentaan localformin storeen, niin poistetaan välilyönnit
                    parser={(value) => (value || '').replace(/\s/g,'')}
                    readOnly={isReadOnly}
                    validators={{
                        isValidIban: (value) => isPfsAccountNumber(value) ? true : validators.isIban(value),
                        isValidPfs: (value) => isPfsAccountNumber(value) ? validators.isIban(value) : true,
                        isRequired: (value) => isRequired ? validators.isRequired(value) : true,
                        minLength: (value) => validators.minLength(value, isPfsAccountNumber(value) && isCommune ? 18 : 15) || isEmployer,
                        maxLength: (value) => validators.maxLength(value, isPfsAccountNumber(value) && isCommune ? 22 : 24) || isEmployer,
                    }}
                    aria-labelledby={`${model}Label`}
                />
                <StHelp id="accountNumberHelp">
                    {_trans('userForm.accountNumber.help')}
                </StHelp>
                <StErrors
                    model={model}
                    messages={{
                        ...errorMessages,
                        isValidPfs: _trans('userForm.accountNumber.pfs_card.not_valid_pfs_number')
                    }}
                />
            </StField>
            <VisibilityContainer hidden={!isPfsAccount} modifierClass="u-margin-bottom">
                {isLoading
                    ? <MDSpinner size="small" />
                    : <StField>
                        <ul className="o-list-bare u-margin-none">
                            <li>
                                <label>
                                    <Control.checkbox
                                        model=".exposedAttributes.acceptPfsAccountNumber"
                                        validators={{
                                            isRequired: isPfsAccount ? validators.isRequiredCheckbox : null,
                                        }}
                                        disabled={!isPfsAccount || isReadOnly}
                                    />
                                    {_trans('userForm.accountNumber.pfs_card.accept_pfs_account_number')}
                                </label>
                                <StErrors model=".exposedAttributes.acceptPfsAccountNumber"/>
                            </li>
                            <li>
                                <label>
                                    <Control.checkbox
                                        model=".exposedAttributes.acceptIsPfsAccountOpen"
                                        validators={{
                                            isRequired: isPfsAccount ? validators.isRequiredCheckbox : null,
                                        }}
                                        disabled={!isPfsAccount || isReadOnly}
                                    />
                                    {_trans(
                                        'Vahvistan, että olen pyytänyt vastaanottokeskusta lisäämään tilinumeron %accountNumber% sallituksi tilinumeroksi työntekijän palkanmaksua varten ja palkanmaksuoikeus on myönnetty.',
                                        { accountNumber: accountNumber ?? 'FI41 8318 3710 0106 65' },
                                        'userDetails',
                                    )}
                                </label>
                                <StErrors model=".exposedAttributes.acceptIsPfsAccountOpen"/>
                            </li>
                        </ul>
                    </StField>
                }
            </VisibilityContainer>
        </div>
    );
}

AccountNumber.propTypes = {
    model: PropTypes.string,

    // Työnantajalle selitetään mihin ihmeeseen tilinumeroa tarvii. Ei luultavasti tarvi syöttää koskaan...
    isEmployer: PropTypes.bool,
    isCommune: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    relationType: PropTypes.number,
};

AccountNumber.defaultProps = {
    model: '.accountNumber',
    isEmployer: false,
    isCommune: false,
    isReadOnly: false,
    relationType: null,
};

export default AccountNumber;
