import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import classNames from 'classnames';
import { Field } from './Field';
import { AutoCompleteField } from './AutoCompleteField';
import { SwitchField } from './SwitchField';
import { TextField } from './TextField';
import { TextareaField } from './TextareaField';
import { EmailField } from './EmailField';
import { IntegerField } from './IntegerField';
import { DecimalField } from './DecimalField';
import { CheckboxField } from './CheckboxField';
import { RadioField } from './RadioField';
import { MaxTextAreaField } from './MaxTextAreaField';
import { StreetAddressAutoCompleteField } from './StreetAddressAutoCompleteField';
import { UrlField } from './UrlField';
import FormikErrors from 'shared/components/Formik/FormikErrors';
import { ChoiceField } from 'shared/components/Formik/ChoiceField';
import { DateInputField } from 'shared/components/Formik/DateInputField';
import { HelpText } from 'shared/components/HelpText';
import { DateRangeInputField } from 'shared/components/Formik/DateRangeInputField';
import { ChoiceFieldList } from 'shared/components/Formik/ChoiceFieldList';
import { RadioListField } from 'shared/components/Formik/RadioListField';
import MDIcon from 'shared/components/MDIcon';
import { SelectField } from 'shared/components/Formik/SelectField';

const ALLOWED_COMPONENTS = [
    AutoCompleteField,
    DateInputField,
    DateRangeInputField,
    DecimalField,
    Field,
    EmailField,
    IntegerField,
    MaxTextAreaField,
    StreetAddressAutoCompleteField,
    SwitchField,
    TextField,
    TextareaField,
    UrlField,
];

const GROUP_COMPONENTS = [
    CheckboxField,
    ChoiceField,
    ChoiceFieldList,
    SelectField,
    RadioField,
    RadioListField,
];

/**
 * Käärii labelin, tooltipin, helpTextin ym. hoitamisen yhteen komponenttiin.
 * Ennen kaikkea yksinkertaistaa lomakkeiden luontia automatisoimalla htmlFor:n ja id:n
 * hoitamisen labelin ja itse kentän välillä. Tämä auttaa ruudunlukijoita luomalla relaation
 * näiden kahden elementin välille.
 *
 * Hoitaa myös virheenkäsittelyn automaattisesti (kunhan validationSchema kunnossa).
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line no-unused-vars
export const FormField = (props) => {
    const { label, isRequired, tooltip, helpText, name, isContentFormField, canShowErrors, children, modifierClass, id } = props;
    const {
        // getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
        state
    } = usePopperTooltip({
        placement: 'top',
        trigger: ['hover', 'focus']
    });

    const filteredChildren = React.Children.toArray(children).filter(Boolean);
    const firstChild = filteredChildren[0];

    // Ei ekaa lasta niin tuskin muitakaan jälkeläisiä...
    if (! firstChild) return null;

    const lastChildren = filteredChildren.slice(1);

    const isAllowedChild = ALLOWED_COMPONENTS.includes(firstChild.type);
    /**
     * Onko sisältö ryhmä? Esim. lista radibuttoneita? Tällöin ei label osoitakaan yhteenkään näistä vaan
     * ryhmän itemeillä on oma label ja ryhmä kuvataan ryhmän ulkopuolisen "labelin" tekstillä.
     */
    const isGroup = props.isGroup || GROUP_COMPONENTS.includes(firstChild.type);

    // Välitä id vain jos sallittu tyyppi jottei osoiteta väärään elementtiin.
    const elementId = isAllowedChild ? name : undefined;
    const tooltipId = `${name}Tooltip`;
    const helpId = `${elementId}Help`;
    const placement = state?.placement ?? '';

    const fieldLabel = () => {
        // Ryhmän tai jonkin muun kuin form-elementin ollessa kyseessä kuvataan ryhmän sisältö tällä eikä htmlFor:ta tarvita.
        if (isGroup || ! isContentFormField) {
            return (
                <div
                    id={name}
                    className={classNames('label', {
                        'is-required': isRequired,
                        'u-align-bottom': tooltip,
                    })}
                >
                    {label}
                </div>
            );
        } else {
            return (
                <label
                    id={id ? `${id}Label` : null}
                    aria-required={isRequired}
                    htmlFor={name}
                    className={classNames({
                        'is-required': isRequired,
                        'u-align-bottom': tooltip,
                    })}
                >
                    {label}
                </label>
            );
        }
    };

    const getAttributes = () => {
        // Label ja sen for-attribuutti ei toimi jos lapsena on jokin muu kuin form-elementti.
        if (! isContentFormField) {
            return { 'aria-labelledby': name };
        }

        // Ryhmille nimi annetaan jokaiselle ryhmän elementille eikä ryhmän containerille.
        if (isGroup && !! helpText) {
            return { 'aria-describedby': helpId };
        }

        // 90% tapauksista menee tähän
        return {
            id: elementId,
            name,
            ...(!! helpText && { 'aria-describedby': helpId }),
        };
    };

    return (
        <Fragment>
            {label && (
                <span className="o-form__label-container u-align-middle" id={id}>
                    {fieldLabel()}
                    {/*TODO: Tästä tehdään nykyisen Tooltipin korvaaja ja korvataan vanhat virheelliset merkinnät vain titlellä*/}
                    {tooltip && (
                        <Fragment>
                            <button
                                ref={setTriggerRef}
                                aria-describedby={tooltipId}
                                className="u-margin-left-tiny"
                            >
                                <MDIcon icon="help_outline" />
                            </button>
                            <div
                                ref={setTooltipRef}
                                id={tooltipId}
                                aria-hidden={! visible}
                                {...getTooltipProps({
                                    className: classNames(`c-tooltip c-tooltip-placement-${placement}`, {
                                        'u-display-none': ! visible,
                                    })
                                })}
                            >
                                {/*<span {...getArrowProps({ className: 'c-tooltip-arrow' })} />*/}
                                <span className="c-tooltip-inner">
                                    {tooltip}
                                </span>
                            </div>
                        </Fragment>
                    )}
                </span>
            )}
            <div className={classNames('o-form__field', { 'o-form__field--labeless': ! label }, modifierClass)} id={! label ? elementId : undefined}>
                {React.cloneElement(firstChild, getAttributes())}
                {lastChildren.length >= 1 && lastChildren}
                { helpText && <HelpText text={helpText} id={id} /> }
                { (name && isContentFormField && canShowErrors) && <FormikErrors name={name} /> }
            </div>
        </Fragment>
    );
};

FormField.propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    helpText: PropTypes.string,
    name: PropTypes.string,
    /**
     * Onko lapsi tyypiltään lomakekenttä. Esim. jos sisältö on puhdasta tekstiä ei voida käyttää
     */
    isContentFormField: PropTypes.bool,
    /**
     * Näytetäänkö virheet out-of-the-box vai hoidetaanko niiden näyttminen itse FormikErrors:n avulla.
     * Tämä mahdollistaa virheiden tarkemman asettelun leiskassa => joissakin monimutkaisissa komponenteissa
     * virheviesti voi olla kaukana itse kentästä jos asettelua ei tehdä tarkemmin.
     */
    canShowErrors: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    /**
     * Onko sisältö ryhmä (inputfieldejä).
     */
    isGroup: PropTypes.bool,
    /**
     * CSS:n muokkausluokka.
     */
    modifierClass: PropTypes.string,
    /**
     * Yksilöivä id.
     */
    id: PropTypes.string,
};

FormField.defaultProps = {
    label: undefined,
    isRequired: false,
    tooltip: undefined,
    helpText: undefined,
    isContentFormField: true,
    canShowErrors: true,
    name: undefined,
    isGroup: false,
    modifierClass: undefined,
    id: undefined,
};
