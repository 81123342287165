export const tesTypes = {
    /**
     * Master-TES. Kaikki TES:t perivät tämän
     * */
    TES_MASTER: 0,

    /**
     * Työsopimuslaki. Perus lakisääteinen.
     */
    EMPLOYMENT_CONTRACTS_ACT: 1,

    /**
     * Rekannusala
     */
    CONSTRUCTION_INDUSTRY: 2,

    /**
     * Siivoukset yms.
     */
    REAL_ESTATE_SERVICES: 3,

    /**
     * Matkailu- ja ravintola-alan työehtosopimus
     */
    MARA: 4,

    /**
     * Työsopimuslaki (Lastenhoito)
     */
    CHILD_CARE: 5,

    /**
     * Kuntakohtainen Heta-liittoon kuulumattomien työehosopimus
     */
    COMMUNE_CONTRACT: 100,

    /**
     * Heta-liittoon kuuluvien TES
     */
    COMMUNE_HETA: 101,

    /**
     * KV-TES, jota jotkut kunnat käyttävät myös avustajien sopimuksissa
     *
     * 11/2016: HUOMAA: tästä on tarkoitus tehdä aina perivä kustom-TES.
     * Ei siis käytössä sellaisenaan millään kunnalla
     *
     */
    COMMUNE_KVTES: 102,

    /**
     * PAM
     */

    /**
     * Kaupan ala
     */
    COMMERCIAL_SECTOR: 230,

    /**
     * Kaupan ala myyjät
     */
    COMMERCIAL_SECTOR_VENDOR: 231,

    /**
     * Kaupan ala varastotyöntekijät
     */
    COMMERCIAL_SECTOR_WAREHOUSE: 232,
    /**
     * Kaupan ala konttorityöntekijät
     */
    COMMERCIAL_SECTOR_OFFICE: 233,

    /**
     * Golfkentän hoitajat
     */

    GOLF_COURSE_CARETAKER: 200,

    /**
     * Teollisuusliitto
     */

    /**
     * Mekaaninen metsäteollisuus
     */

    MECHANICAL_FOREST_INDUSTRY: 210,

    /**
     * Teknologiateollisuus
     */
    TECHNOLOGY_INDUSTRY: 211,

    /**
     * JHL
     */

    /**
     * Yksityisen sosiaalipalvelualan TES
     */

    PRIVATE_SOCIAL_SERVICES: 220,

    /**
     * PRO
     */

    /**
     * ICT Toimihenkilöt
     */

    ICT: 240,

    /**
     * Posti- ja logistiikka-alan unionin TES
     */
    PAU: 250,

    /**
     * Old COMMMON only used for existing old lite contracts. DO NOT REMOVE!
     * New Lite contracts also use EMPLOYMENT_CONTRACTS_ACT
     */
    COMMON: 900,

    /**
     * Alkuperäisestä TES:stä muokattu toteutus (näitä voi olla monta tietokannassa).
     * Nämä perivät (yleensä?) jonkun perustason TES:n (esim. Heta tai KVTES)
     */
    CUSTOM: 1000,

    /**
     * Perhehoidon "tes"
     */
    FAMILY_CARE: 2000,

    /**
     * Päivitystävä perhehoitajan sopimus
     */
    FAMILY_CARE_ON_CALL_COMPENSATION: 2010,

    /**
     * Omaishoidon "tes"
     */
    RELATIVE_CARE: 2100,

    /**
     * Palkkiosopimus.
     */
    COMPENSATION_EARNER: 2200,
};

const tesTypeMap = {
    [tesTypes.TES_MASTER]: _trans('tes_builder.types.master_tes'),
    [tesTypes.EMPLOYMENT_CONTRACTS_ACT]: _trans('tes_builder.types.employment_contracts_act'),
    [tesTypes.CONSTRUCTION_INDUSTRY]: _trans('tes_builder.types.construction_industry'),
    [tesTypes.REAL_ESTATE_SERVICES]: _trans('tes_builder.types.real_estate_services'),
    [tesTypes.MARA]: _trans('tes_builder.types.mara'),
    [tesTypes.CHILD_CARE]: _trans('tes_builder.types.employment_contracts_act'),
    [tesTypes.COMMUNE_CONTRACT]: _trans('tes_builder.types.commune_contract'),
    [tesTypes.COMMUNE_HETA]: _trans('tes_builder.types.commune_heta'),
    [tesTypes.COMMUNE_KVTES]: _trans('tes_builder.types.commune_kvtes'),
    [tesTypes.GOLF_COURSE_CARETAKER]: _trans('tes_builder.types.golf_course_caretaker'),
    [tesTypes.MECHANICAL_FOREST_INDUSTRY]: _trans('tes_builder.types.mechanical_forest_industry'),
    [tesTypes.TECHNOLOGY_INDUSTRY]: _trans('tes_builder.types.technology_industry'),
    [tesTypes.PRIVATE_SOCIAL_SERVICES]: _trans('tes_builder.types.private_social_services'),
    [tesTypes.COMMERCIAL_SECTOR]: _trans('tes_builder.types.commercial_sector'),
    [tesTypes.COMMERCIAL_SECTOR_VENDOR]: _trans('tes_builder.types.commercial_sector_vendor'),
    [tesTypes.COMMERCIAL_SECTOR_WAREHOUSE]: _trans('tes_builder.types.commercial_sector_warehouse'),
    [tesTypes.COMMERCIAL_SECTOR_OFFICE]: _trans('tes_builder.types.commercial_sector_office'),
    [tesTypes.ICT]: _trans('tes_builder.types.ict'),
    [tesTypes.PAU]: _trans('tes_builder.types.pau'),
    [tesTypes.COMMON]: _trans('tes_builder.types.common'),
    [tesTypes.CUSTOM]: _trans('tes_builder.types.custom'),
};

export function translateTesType(type) {
    return type in tesTypeMap ? tesTypeMap[type] : '';
}
