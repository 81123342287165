import _ from 'lodash';
import questionTypes from 'shared/containers/Inquisitor/constants/questionTypes';
import utils from 'shared/utils';
import { errorMessages } from 'shared/validators';

const UID_PREFIX = 'inquisitor-';
const QUESTION_SELECTOR = 'c-inquisitor';

/**
 * Validoi päällisin puolin kysymykset.
 * @param questions
 * @returns {boolean}
 */
function validateQuestions(questions) {
    if (_.size(questions) === 0) {
        console.error('No questions given or questions is empty!');
        return null;
    }

    const questionIds = _.map(questions, 'id');
    let hasValidQuestions = true;

    // Tarkistetaan kysymysten id:n uniikkius (yo!).
    _.map(_.countBy(questionIds), (occurences, id) => {
        if (occurences > 1) {
            hasValidQuestions = false;
            console.error(`Duplicate questions by id "${id}" (${occurences} pcs)`);
        }
    });

    questions.map((question, questionIndex) => {
        // Tarkistetaan löytyykö mahdollisten jumpTo-sijaintien id:t kysymyksistä.
        const jumpTo = _.get(question, 'jumpTo', null);
        const questionId = _.get(question, 'id', '');
        const questionType = _.get(question, 'type', null);

        // Jos löytyy intro-tyyppinen blokki on sen oltava ensimmäisenä listalla.
        if (questionType === questionTypes.INTRO && questionIndex > 0) {
            hasValidQuestions = false;
            console.error('"intro" block has to be first on the question list!');
        }

        // Jos löytyy kiitos-sivu -tyyppinen blokki on sen oltava viimeisenä listalla.
        /*if (questionType === questionTypes.THANK_YOU && questionIndex < questions.length - 1) {
            hasValidQuestions = false;
            console.error('"thank-you" block has to be last on the question list!');
        }*/

        // Jos löytyy select-tyyppinen kysymys on sillä oltava options-taulukko attribuuteissaan.
        if (questionType === questionTypes.SELECT) {
            const options = _.get(question, 'options', []);
            if (! options.length) {
                hasValidQuestions = false;
                console.error(`"${questionId}" has no options in attributes`);
            }
        }

        if (jumpTo !== null) {
            let jumpIds = null;

            // Otetaanko id:t objektista, stringistä vai päätetäänkö seuraava id itse vastausten perusteella.
            if (typeof jumpTo === 'object') {
                jumpIds = Object.keys(jumpTo);
            } else if (typeof jumpTo === 'string') {
                jumpIds = [jumpTo];
            }

            // Tutkitaan mitä id:tä ei löydy
            if (_.isArray(jumpIds)) {
                jumpIds.map((jumpId) => {
                    if (!_.includes(questionIds, jumpId)) {
                        console.error(`jumpTo id "${jumpId}" not found`);
                    }
                });
            }
        }

        // Tarkistetaan että kysymysten tyypit ovat tuettujen listalla.
        if (! _.includes(questionTypes, questionType)) {
            hasValidQuestions = false;
            console.error(`Question type "${questionType}" is not allowed in "${questionId}". Allowed types are: ${Object.values(questionTypes).join(', ')}`);
        }
    });

    return hasValidQuestions;
}

/**
 * Palauttaa dataa jota tarvitaan fokusoidun kysymyksen laskemiseen ja
 * siihen mihin kohtaa kysymyselementtiä scrollataan.
 * @returns {{windowHeight: number, documentHeight: number, scrollTop: number, percentScrolled: number}}
 */
function getScrollData() {
    // Ikkunan korkeus (näkyvä osuus)
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    // Scrollikohta
    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

    // Koko dokumentin (sisällön) korkeus
    const documentHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );

    // Scrollin pituus
    const trackLength = documentHeight - windowHeight;
    // Montako prosenttia scrollailtu näkymästä
    const percentScrolled = Math.floor(scrollTop/trackLength * 100);

    return {
        windowHeight,
        documentHeight,
        scrollTop,
        percentScrolled,
    };
}

/**
 * Palauttaa DOM:ssa olevan kysymyksen id:n.
 * @param id
 * @returns {string}
 */
const getUidById = (id) => `${UID_PREFIX}${id}`;

/**
 * Palauttaa kysymyksen uniikin id:n DOM:ssa.
 * @param question
 * @returns {string}
 */
const getUidByQuestion = (question) => getUidById(_.get(question, 'id', ''));

/**
 * Scrollataan kysymykseen.
 * Jos kysymys ei mahdu kokonaisuudessaan ruutuun scrollataan sen yläosaan jotta nähdään edes otsikko.
 * Käytännössä näin aina mobiilissa.
 * @param elementId
 * @param align
 */
const scrollToElementById = (elementId, align = 'middle') => {
    const element = document.getElementById(elementId);
    let isOffScreen = false;

    if (element) {
        const elementRect = element.getBoundingClientRect();
        const elementHeight = _.get(elementRect, 'height', 0);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        // Kysymyksen koko isompi kuin ikkunan koko = yli menee
        isOffScreen = elementHeight > windowHeight;
        align = isOffScreen ? 'top' : align;
    }

    return utils.smoothScrollToElement(`#${elementId}`, align, 0);
};

/**
 * Scrollaa näkymän kysymykseen.
 * @param question
 * @param align
 */
const scrollToQuestion = (question, align) => {
    const elementId = getUidByQuestion(question);
    scrollToElementById(elementId, align);
};

/**
 * Hakee kysymyksen attribuutin.
 */
const getAttribute = (question, attributeName, defaultValue = null) => {
    const attributes = _.get(question, 'attributes', {});
    return _.get(attributes, attributeName, defaultValue);
};

/**
 * Hakee kysymyksen näppäinoikotiet.
 */
const getQuestionShortcuts = (question) => {
    const questionType = _.get(question, 'type', null);

    switch (questionType) {
        case questionTypes.YES_NO: {
            // Tutkitaan löytyykö kysymykseltä kustomoitu label josta shortcut otetaan ensimmäisestä kirjaimesta.
            // TODO: Lokalisoinnin mukainen accesskey
            const yesAccessKey = _.get(question, 'attributes.yesLabel', 'K')[0];
            const noAccessKey = _.get(question, 'attributes.noLabel', 'E')[0];
            return {
                [yesAccessKey]: true,
                [noAccessKey]: false,
            };
        }

        case questionTypes.CHOICE:
        case questionTypes.PICTURE_CHOICE: {
            // Palautetaan objekti jossa key on aakkonen ja value on numero (A = 1, B = 2, jne...).
            const options = _.get(question, 'options', []);
            const accessKeys = _.map(options, (option, key) => String.fromCharCode(key + 65));
            const values = _.range(1, options.length + 1);
            // Yhdistetään aakkoset ja numerot.
            return _.zipObject(accessKeys, values);
        }

        case questionTypes.RATING: {
            const maxRating = getAttribute(question, 'maxRating', 5);
            // Palautetaan taul1ukko 1...maxRating
            return _.range(1, maxRating);
        }

        case questionTypes.SELECT:
            return {};

        case questionTypes.INTRO:
        case questionTypes.STATEMENT:
        case questionTypes.THANK_YOU:
            return { 'ENTER': true };


        // Itse tehty kysymyssisältö. Yritetään hakea annetut näppäinoikotiet, muutoin ei näppäinoikotietä.
        case questionTypes.CUSTOM:
            return _.get(question, 'accessKeys', {});

        // Oletuksena ei näppäinoikoteitä
        default:
            return {};
    }
};

/**
 * Validoi annettuja validaattoreita vasten ja palauttaa mahdolliset virheviestit.
 * @param value
 * @param validators
 * @returns {Array}
 */
const getErrorMessages = (value, validators) => {
    const errors = [];
    _.map(validators, (validator, errorMessageKey) => {
        if (! validator(value)) {
            errors.push(errorMessages[errorMessageKey]);
        }
    });
    return errors;
};

export {
    validateQuestions,
    getScrollData,
    getUidById,
    getUidByQuestion,
    scrollToElementById,
    scrollToQuestion,
    getAttribute,
    getQuestionShortcuts,
    getErrorMessages,
    UID_PREFIX,
    QUESTION_SELECTOR,
};
