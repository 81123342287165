import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProceedButton } from 'shared/containers/Inquisitor/components';
import { getAttribute } from 'shared/containers/Inquisitor/utils';
import {
    ActionBar,
    Button,
} from 'shared/components';

/**
 * Onboardingin yhteydessä esitettävä intro joka sisältää roolinvaihtonapin.
 * Roolia vaihtaessa resetoidaan edelliset kysymykset.
 */
export default class OnboardingIntro extends Component {
    static propTypes = {
        onAnswerQuestion: PropTypes.func.isRequired,
        question: PropTypes.object.isRequired, // Ei nyt jaksa vääntää shapea...
    };

    render() {
        const {
            question,
            onAnswerQuestion,
        } = this.props;

        return (
            <ActionBar>
                <ProceedButton
                    label={getAttribute(question, 'buttonLabel', _trans('button.continue'))}
                    onProceed={() => onAnswerQuestion(true)}
                    hasShortCut={false}
                />
                <Button
                    outline
                    size="large"
                    href={Routing.generate('suoratyo_home', { role: 999 })}
                    modifierClass="u-font-size-16"
                >
                    {_trans('onboarding.change_role.label')}
                </Button>
            </ActionBar>
        );
    }
}
