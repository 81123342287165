import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Renderöi mahdolliset virheviestit.
 */
export default class ErrorMessages extends Component {
    static propTypes = {
        errors: PropTypes.array,
    };

    static defaultProps = {
        errors: [],
    };

    render() {
        if (! this.props.errors.length) return null;

        return (
            <ul className="o-form__errors o-list-bare u-margin-bottom-none u-display-block">
                {this.props.errors.map((errorMessage, key) => (
                    <li key={key} className="o-form__error-item">
                        {errorMessage}
                    </li>
                ))}
            </ul>
        );
    }
}
