import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Wrapper, Button, Menu } from 'react-aria-menubutton';
import { withRouter, Route, Switch, } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import settings from './constants';
import SideBarItem from './SideBarItem';
import SideBarList from './SideBarList';
import { sideBarContentWidths } from 'shared/constants';
import {
    GoBackLink,
    MDIcon, PageNotFound, Placeholder,
} from 'shared/components';
import Badge from 'shared/components/Badge';

const SIDE_BAR_WIDTH = 224;

@withRouter
export default class SideBar extends Component {
    static defaultProps = {
        children: null,
        initialRouteName: '',
        goBackRoute: null,
        goBackHref: '',
        goBackText: _trans('link.back'),
        sideBarAdditionalDetails: null,
    };

    static propTypes = {
        /**
         * Paths-taulukko.
         */
        paths: PropTypes.array.isRequired,

        /**
         * Oletuksena valittu route. Jos tätä ei ole annettu valitaan automaattiseti ensimmäinen route.
         */
        initialRouteName: PropTypes.string,

        /**
         * Vaihtoehtoinen sisältö. Jos tätä ei ole annettu, renderöidään routet automaattisesti.
         */
        children: PropTypes.node,

        /**
         * Mahdollinen back linkki. Linkki sijaitsee contentin päällä ActionBar:ssa.
         */
        goBackRoute: PropTypes.string,

        // Tämä tässä sen ajan että TT:lle tehdään oma v3-sopparilista. Pitäisi siis pystyä tarkistamaan
        // joko onko TA:lla v3 päällä tai onko kaikilla soppareilla joku template => v3
        goBackHref: PropTypes.string,

        /**
         * Takaisinpaluulinkin teksti. Oletuksena "Takaisin".
         */
        goBackText: PropTypes.string,

        /**
         * Jos halutaan näyttää sidebarissa "takaisin" ja "valintojen" välisssä jotain tietoja.
         */
        sideBarAdditionalDetails: PropTypes.object,
    };

    /**
     * Hakee tämänhetkisen sijainnin nimen.
     * @returns {*}
     */
    getCurrentPathName = () => _.get(this.props, 'location.pathname', null);

    /**
     * Tehdään koko paths-objektista flätti key/value -objektipari.
     * @param paths
     */
    getFlattenPaths = (paths) => {
        let out = {};

        paths.map((path) => {
            if (_.has(path, 'path')) {
                out[path.path] = _.get(path, 'title', '');
            } else if (_.has(path, 'paths')) {
                out = Object.assign({},
                    this.getFlattenPaths(path.paths),
                    out,
                );
            }
        });

        return out;
    };

    /**
     * Mikäli paluulinkin route on annettu renderöidään itse linkkikin.
     * @returns {*}
     */
    renderGoBackRoute() {
        const { goBackRoute, goBackHref, goBackText } = this.props;

        if (goBackRoute || goBackHref !== '') {
            return (
                <div className="u-margin-top-small">
                    {goBackHref !== ''
                        ? (
                            <GoBackLink
                                text={goBackText}
                                href={goBackHref}
                                modifierClass="c-side-bar__go-back-link u-text-left u-display-block"
                            />
                        )
                        : (
                            <GoBackLink
                                text={goBackText}
                                to={goBackRoute}
                                modifierClass="c-side-bar__go-back-link u-text-left u-display-block"
                            />
                        )}
                </div>
            );
        }
    }

    /**
     * Iteroi route-objektin ja renderöi linkit ja mahdolliset ryhmän nimet.
     * @param paths
     * @param groupName
     * @returns {*}
     */
    renderPaths = (paths, groupName = 'root') => (
        <li role="presentation" key={`${groupName}`}>
            {_.map(paths, (path, idx) => {
                const hasGroupHeader = path[settings.GROUP_HEADER] ?? false;
                // Jos path on navigaatioryhmä käydään se läpi
                if ((path[settings.GROUP_NAME]
                    && path[settings.GROUP_PATHS])
                    || hasGroupHeader
                ) {
                    const groupName = path[settings.GROUP_NAME];
                    const groupIcon = _.get(path, settings.GROUP_ICON, false);
                    // Näkyvien routejen määrä
                    const visibleRouteCount = (path[settings.GROUP_PATHS] ?? []).filter((route) => route[settings.PATH_VISIBLE] ?? true).length;

                    // Jos grouppille annettu visible: false, niin eipä näytetä mittää tai jos ei (ryhmän alla) ole routeja.
                    if (! (path[settings.PATH_VISIBLE] ?? true) || visibleRouteCount === 0) {
                        return null;
                    }

                    // Joko vapaasisältöinen groupHeader tai groupIcon + groupName combo.
                    const groupHeader = hasGroupHeader
                        ? path[settings.GROUP_HEADER]
                        : (
                            <h3 className="c-side-bar-v2__title o-stack">
                                {groupIcon &&
                                    <MDIcon icon={groupIcon} size="small" modifierClass="u-margin-right-tiny"/>}
                                {groupName}
                            </h3>
                        );

                    return (
                        <div key={`${groupName}${idx}`}>
                            <div className="c-side-bar-v2__group-header">
                                {groupHeader}
                            </div>
                            <SideBarList>
                                <Placeholder isPending={_.get(path, settings.GROUP_LOADING_ROUTES, false)}>
                                    {this.renderPaths(path[settings.GROUP_PATHS], groupName)}
                                </Placeholder>
                            </SideBarList>
                        </div>
                    );
                }

                const routeName = path[settings.PATH_NAME];
                const isBadgeVisible = path[settings.PATH_IS_BADGE_VISIBLE] ?? false;
                const badgeCount = path[settings.PATH_BADGE_VALUE];
                const icon = _.get(path, settings.PATH_ICON, null);
                const hasRoutes = _.get(path, settings.PATH_HAS_ROUTES, false);
                const currentRouteName = this.getCurrentPathName();
                const isSelected = hasRoutes ? currentRouteName.indexOf(routeName) > -1 : (routeName === currentRouteName);
                const routeTitle = path[settings.PATH_TITLE];
                const isAdmin = _.get(path, 'isAdmin', false);

                // Tarkistellaan, onko sisältö piilotettu ja title on olemassa.
                if (_.get(path, settings.PATH_VISIBLE, true) && routeTitle) {
                    return (
                        <SideBarItem
                            modifierClass="u-text-truncate"
                            // Override: admin ikoni, jos admin route
                            icon={isAdmin ? 'android' : icon}
                            path={routeName}
                            key={`${groupName}${routeName}${idx}`}
                            isSelected={isSelected}
                            isAdmin={isAdmin}
                        >
                            <div className="o-stack o-stack--justify u-1/1">
                                <span>{routeTitle}</span>
                                {isBadgeVisible && <Badge value={badgeCount ?? 1} type="positive" isBall={false} />}
                            </div>
                        </SideBarItem>
                    );
                }
            })}
        </li>
    );

    /**
     * Renderöidään vain mobiilikoossa näkyvä alasvetovalikon avaava nappi.
     * @returns {*}
     */
    renderTrigger() {
        const paths = this.getFlattenPaths(this.props.paths);
        const currentPathTitle = _.get(paths, this.getCurrentPathName(), '-');

        return (
            <div className="c-side-bar-v2-trigger-container u-1/1 u-padding-small">
                <Wrapper
                    className={classNames({
                        'is-open': this.isOpen,
                    })}
                    onSelection={this.onChange}
                    onMenuToggle={({ isOpen }) => {
                        this.isOpen = isOpen;
                    }}
                    id="sidebar"
                >
                    <Button
                        className="c-button o-stack o-stack--justify u-text-no-wrap u-text-left u-1/1"
                    >
                        {currentPathTitle}
                        <i className="c-dropdown__arrow material-icons">keyboard_arrow_down</i>
                    </Button>
                    <Menu>
                        <SideBarList>
                            {this.props.sideBarAdditionalDetails}
                            {this.renderPaths(this.props.paths)}
                        </SideBarList>
                    </Menu>
                </Wrapper>
            </div>
        );
    }

    static renderPath(path) {
        const routePath = _.get(path, 'path', '');

        if (_.has(path, 'dynamicPath')) {
            return (
                <Route
                    key={routePath}
                    path={path.dynamicPath}
                    exact={_.get(path, 'exact', false)}
                    component={path[settings.PATH_COMPONENT]}
                />
            );
        }

        if (_.has(path, 'path')) {
            return (
                <Route
                    key={routePath}
                    path={path.path}
                    exact={_.get(path, 'exact', false)}
                    component={path[settings.PATH_COMPONENT]}
                />
            );
        }
    }

    /**
     * Itse sisältö valitun valikkoitemin mukaan. Käydään läpi rekursiivisesti jos löytyy alirotue.
     * Tukee siis esim. tilannetta jossa routella on aliroutena lomake.
     * TODO: GoBackLink näkyviin automaagisesti jos aliroute.
     * @returns {*}
     */
    renderContent(paths) {
        return paths.reduce((routes, path) => {
            if (_.has(path, settings.GROUP_PATHS)) {
                // Jos ryhmä, kutsutaan itseään rekursiivisesti ja lisätään routes-taulukkoon
                return [
                    ...routes,
                    // Jos routen alirouteja, niin lisäillään kuitenkin pääroute.
                    _.has(path, settings.PATH_COMPONENT) ? SideBar.renderPath(path) : null,
                    ...this.renderContent(_.get(path, settings.GROUP_PATHS, []))
                ];
            }
            //Jos löytyy visible-propertie, joka on false, niin ei näytetä mitään.
            if (! _.get(path, settings.PATH_VISIBLE, true)) {
                return routes;
            }

            // Jos dynaamisen contentin route on jo lisätty, niin eipä lisätä uudestaan
            if (routes.find((route) => _.get(route, 'props.path') === path.dynamicPath)) {
                return routes;
            }

            return [
                ...routes,
                SideBar.renderPath(path),
            ];
        }, []);
    }

    /**
     * Renderöidään sivupalkki (mobiilissa / pienissä selainkoi'issa alasvetovalikko)
     * @returns {*}
     */
    render() {
        const {
            paths,
            goBackRoute,
        } = this.props;

        const sideBarContainerWidth = SIDE_BAR_WIDTH + sideBarContentWidths['medium'] + 48;

        return (
            <div className="c-side-bar-v2-container">
                <MediaQuery maxWidth={sideBarContainerWidth - 1}>
                    <ul className="o-layout">
                        { goBackRoute && (
                            <li className={classNames('o-layout__item', {
                                'u-1/3': goBackRoute,
                            })}>
                                {this.renderGoBackRoute()}
                            </li>
                        )}
                        <li className={classNames('o-layout__item', {
                            'u-2/3': goBackRoute,
                        })}>
                            {this.renderTrigger()}
                        </li>
                    </ul>
                </MediaQuery>
                <MediaQuery minWidth={sideBarContainerWidth}>
                    <div className="c-side-bar-v2">
                        <SideBarList>
                            <li role="presentation">
                                {this.renderGoBackRoute()}
                            </li>
                            <li>
                                {this.props.sideBarAdditionalDetails}
                            </li>
                            {this.renderPaths(paths)}
                        </SideBarList>
                    </div>
                </MediaQuery>
                <div className={classNames('c-side-bar-v2-content')}>
                    <Switch>
                        {this.renderContent(paths)}
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </div>
            </div>
        );
    }
}
